import { EAuthSocialErrorCode, ESocialType } from 'entities/Auth/Auth.models';
import { IOrderHistoryModel } from 'entities/OrderHistory/OrderHistory.models';
import { ICartItemModel } from 'entities/Cart/Cart.models';
import { IMenuItemWithCategoryNameAndType } from 'entities/Menu/Menu.models';
import { IStoreModel } from 'entities/Store/Store.models';

export interface ICommonModalModel {
  isVisible: boolean;
}

export enum EModalType {
  OrderItem = 'OrderItem',
  MenuItem = 'MenuItem'
}

export enum EAuthModals {
  Type = 'type',
  Login = 'login',
  Signup = 'signup',
  Verify = 'verify'
}

export interface IAuthModalModel {
  isVisible: boolean;
  type?: EAuthModals;
}

export interface ISocialLinkModalModel {
  isVisible?: boolean;
  type?: ESocialLinkModals;
  errorCode?: EAuthSocialErrorCode;
  socialType?: ESocialType;
}

export enum ESocialLinkModals {
  LinkErrors = 'linkErrors',
  UnlinkConfirm = 'unlinkConfirm'
}

export interface IReorderConfirmModalModel {
  isVisible?: boolean;
  item?: IOrderHistoryModel;
}

export interface ICartItemModalModel {
  isVisible?: boolean;
  cartItem?: ICartItemModel;
  menuItem?: IMenuItemWithCategoryNameAndType;
}

export interface IChangeStoreConfirmModalModel {
  isVisible?: boolean;
  selectedStore?: IStoreModel;
}

export interface IAddToBagConfirmModalModel {
  isVisible?: boolean;
}
