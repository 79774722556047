import React from 'react';
import { useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { ERoutes, ERoutesCommon, ERoutesPrivate, ERoutesPublic } from 'common/models/routesModel';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { logout } from 'app/store/reducers/auth.reducer';

type AllProps = IComponentProps;

interface IComponentProps {
  mode?: 'horizontal' | 'vertical';
  mobile?: boolean;
  close?: () => void;
}

export const HeaderMenu: React.FC<AllProps> = (props) => {
  // ---> Leave as is. Just to update history and path on-the-go
  useAppSelector((state) => state.router);
  // <---
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { authModel } = useAppSelector((state) => state.auth);
  const { mobile, close } = props;
  const authorized = Boolean(authModel);
  const visibleLogin = Boolean(!authorized && mobile);
  const visibleLogout = Boolean(authorized && mobile);

  const path = history.location.pathname;
  const currentPath = path?.split(/(^\/[a-zA-Z-]*)/)[1];

  const showLogo = !mobile;
  const mobileClass = mobile ? 'header-menu_mobile' : '';
  const mode = mobile ? 'vertical' : 'horizontal';

  const onLogout = () => {
    if (close) {
      close();
    }

    dispatch(logout());
  };

  const redirect = (e: MenuInfo) => {
    if (close) {
      close();
    }

    history.push(e.key.toString());
  };

  const redirectToMain = () => {
    if (close) {
      close();
    }

    history.push(ERoutesCommon.Root);
  };

  return (
    <Menu selectedKeys={[currentPath]} mode={mode} className={`header-menu ${mobileClass}`}>
      {showLogo && (
        <Menu.Item key="logo" onClick={redirectToMain}>
          <span className="header__title">MB Go!</span>
        </Menu.Item>
      )}

      <Menu.Item key={ERoutesCommon.Root} onClick={redirect} className="header-menu__activilable-item">
        Menu
      </Menu.Item>

      {authorized && (
        <Menu.Item key={ERoutes.Order} onClick={redirect} className="header-menu__activilable-item">
          Current Order
        </Menu.Item>
      )}

      {authorized && (
        <Menu.Item key={ERoutes.AccountSettings} onClick={redirect} className="header-menu__activilable-item">
          Account settings
        </Menu.Item>
      )}

      {authorized && (
        <Menu.Item key={ERoutesPrivate.OrderHistory} onClick={redirect} className="header-menu__activilable-item">
          Order history
        </Menu.Item>
      )}

      {visibleLogin && (
        <Menu.Item key={ERoutesPublic.Login} onClick={redirect} className="header-menu__activilable-item mt-8">
          Log In
        </Menu.Item>
      )}

      {visibleLogout && (
        <Menu.Item key="logout" onClick={onLogout} className="header-menu__activilable-item mt-8">
          Log out
        </Menu.Item>
      )}

      <Menu.Item key={ERoutesCommon.Privacy} onClick={redirect} className="header-menu__link-item">
        Privacy policy
      </Menu.Item>

      <Menu.Item key={ERoutesCommon.ToS} onClick={redirect} className="header-menu__link-item">
        Terms of Service
      </Menu.Item>
    </Menu>
  );
};
