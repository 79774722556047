import React from 'react';
import { Button, Form, Input, message } from 'antd';
import { useFormMapper } from 'common/helpers/form.helper';
import { ADMIN_COMMUNICATION_LINK } from 'common/consts/common';
import { phonePattern } from 'common/consts/validation.consts';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { addCustomerPhone } from 'app/store/reducers/customer.reducer';

interface IComponentProps {
  onSuccess?: (phone: string) => void;
}
export const PhoneConfirm: React.FC<IComponentProps> = (props) => {
  const dispatch = useAppDispatch();
  const { customerPhone, customerPhoneParams, customerPhoneError, customerPhoneLoading } = useAppSelector(
    (state) => state.customer
  );
  const { fields } = useFormMapper(['phone'], customerPhone, customerPhoneParams, customerPhoneError);

  const submit = (values: any) => {
    const { onSuccess } = props;

    if (customerPhoneLoading) {
      return;
    }

    const successCallback = () => onSuccess && onSuccess(values.phone);
    const failCallback = () =>
      message.warning(
        <div>
          We can`t send the confirmation code. Please contact Customer Care{' '}
          <a target="_blank" rel="noopener noreferrer" href={ADMIN_COMMUNICATION_LINK} className="link fs-xxs">
            here.
          </a>
        </div>
      );

    dispatch(addCustomerPhone({ phone: values.phone }, successCallback, failCallback));
  };

  return (
    <Form onFinish={submit} requiredMark={false} layout="vertical" fields={fields}>
      <Form.Item
        name="phone"
        rules={[
          { required: true, message: 'Please input your mobile number (+63 9XX XXX XXXX)' },
          {
            pattern: phonePattern,
            message: 'Please input your mobile number (+63 9XX XXX XXXX)',
          },
        ]}
      >
        <Input name="phone" type="tel" placeholder="+63 9XX XXX XXXX" disabled={customerPhoneLoading} />
      </Form.Item>

      <Button size="large" block className="btn" type="primary" htmlType="submit" disabled={customerPhoneLoading}>
        Send code
      </Button>
    </Form>
  );
};
