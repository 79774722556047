import queryString from 'querystring';
import React, { useEffect } from 'react';
import { saveCreds } from '@axmit/axios-patch-jwt';
import { useHistory } from 'react-router-dom';
import { LoadingSpin } from 'common/components';
import { ERoutesCommon } from 'common/models/routesModel';
import { useAppDispatch } from 'app/store/store.hooks';
import { refreshToken, updateIsSocialNetworkRegistered } from 'app/store/reducers/auth.reducer';

export const SocialAuthPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    const userIdData = queryString.parse(window.location.search)[`?userId`];
    const access = queryString.parse(window.location.search)[`access`];
    const refreshData = queryString.parse(window.location.search)[`refresh`];
    const isRegisteredData = queryString.parse(window.location.search)[`isRegistered`];
    const refresh = Array.isArray(refreshData) ? refreshData[0] : refreshData;
    const issuedAt = new Date().getTime();
    const expiredAt = new Date().getTime();
    const userId = Array.isArray(userIdData) ? userIdData[0] : userIdData;
    const isRegistered = isRegisteredData && isRegisteredData === 'true';

    if (userId && access && refresh) {
      const token = {
        id: userId,
        userId,
        issuedAt,
        expiredAt,
        token: Array.isArray(refresh) ? refresh[0] : refresh,
      };

      saveCreds({
        refresh: token,
        access: token,
      }).then(() => {
        dispatch(updateIsSocialNetworkRegistered(!!isRegistered));
        dispatch(refreshToken(refresh));
      });
    } else {
      history.push(ERoutesCommon.Root);
    }
  }, []);

  return <LoadingSpin />;
};
