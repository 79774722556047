import React, { useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { setUiReorderConfirmModal } from 'app/store/reducers/ui.reducer';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { OrderHistoryList } from 'entities/OrderHistory/components/OrderHistoryList';
import { StoreSelector } from 'entities/Store/components/StoreSelector';
import { IStoreModel } from 'entities/Store/Store.models';
import { ReorderConfirmModal } from 'entities/OrderHistory/components/ReorderConfirmModal';
import { IOrderHistoryModel } from 'entities/OrderHistory/OrderHistory.models';

interface IFilter {
  storeItem?: Partial<IStoreModel>;
}

export const OrderHistoryPage: React.FC<RouteComponentProps> = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { uiReorderConfirmModal } = useAppSelector((state) => state.ui);
  const [filter, setFilter] = useState<IFilter>(queryToObject<IFilter>({ storeItem: undefined }));

  const openReorderConfirm = (item: IOrderHistoryModel) => {
    dispatch(setUiReorderConfirmModal({ isVisible: true, item }));
  };

  const onChangeSelector = (item?: IStoreModel) => {
    const storeItem = item ? { id: item?.id, storeName: item?.storeName } : undefined;

    setNewFilter({ storeItem });
  };

  const setNewFilter = (partialFilter: Partial<IFilter>) => {
    const newFilter = { ...filter, ...partialFilter };

    history.replace({ search: objectToQuery(newFilter) });

    setFilter(newFilter);
  };

  const isVisible = uiReorderConfirmModal?.isVisible;
  const itemForReorder = uiReorderConfirmModal?.item;
  const storeItem = filter.storeItem;
  const storeId = storeItem?.id;
  const emptyText = storeId ? (
    <>
      There are no orders in your order history that match the selected store.
      <br />
      Please try again.
    </>
  ) : (
    <>There are no orders in your order history yet.</>
  );

  return (
    <TwoColumnsLayout
      left={
        <MobileLayout header="Order history">
          <div className="basic__text_title mb-4">Filter orders by Store name or address</div>
          <div className="mb-9">
            <StoreSelector onChange={onChangeSelector} defaultOpt={storeItem} />
          </div>
          <OrderHistoryList showConfirm={openReorderConfirm} filter={{ store: storeId }} emptyText={emptyText} />
          <ReorderConfirmModal
            isVisible={!!isVisible}
            onClose={() => dispatch(setUiReorderConfirmModal({ isVisible: false }))}
            orderHistoryItem={itemForReorder}
          />
        </MobileLayout>
      }
    />
  );
};
