import { IError } from '@axmit/error-helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppDispatch } from 'app/store';
import { IPromoModel } from 'entities/Promo/Promo.models';

export interface IState {
  // promo model
  promoModel: IPromoModel | null;
  promoModelLoading: boolean;
  promoModelError: IError | null;
}

const initialState: IState = {
  // promo model
  promoModel: null,
  promoModelLoading: false,
  promoModelError: null,
};

export const promoSlice = createSlice({
  name: 'promo',
  initialState,
  reducers: {
    // promo model
    setPromoModelLoading(state, action: PayloadAction<boolean>) {
      state.promoModelLoading = action.payload;
    },
    setPromoModel(state, action: PayloadAction<IPromoModel | null>) {
      state.promoModel = action.payload;
    },
    setPromoModelError(state, action: PayloadAction<IError | null>) {
      state.promoModelError = action.payload;
    },
  },
});

export const { setPromoModelLoading, setPromoModel, setPromoModelError } = promoSlice.actions;
export default promoSlice.reducer;

export const getPromoModel = () => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setPromoModelLoading(true));

    try {
      const promoModel: IPromoModel = await axios.get('promos/customer');

      dispatch(setPromoModel(promoModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setPromoModelError(_error));
    } finally {
      dispatch(setPromoModelLoading(false));
    }
  }

  return thunk;
};
