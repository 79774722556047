import React, { useEffect } from 'react';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { useQuery } from 'common/hooks';
import { ERoutesCommon, ERoutesPublic } from 'common/models/routesModel';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { confirmCustomerEmail } from 'app/store/reducers/customer.reducer';
import { EAuthErrorMessage } from 'entities/Auth/Auth.models';

export const EmailConfirm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { customerConfirmationLoading, customerConfirmation } = useAppSelector((state) => state.customer);
  const isTokenValid = !customerConfirmationLoading && customerConfirmation;

  const history = useHistory();
  const token: string = useQuery().get('token') || '';

  useEffect(() => {
    if (!token) {
      message.error(EAuthErrorMessage.InvalidLink);
      history.push(ERoutesCommon.Root);

      return;
    }

    dispatch(confirmCustomerEmail(token));
  }, [token, history]);

  if (customerConfirmationLoading || !isTokenValid) {
    return <LoadingSpin />;
  }
  return (
    <TwoColumnsLayout
      left={
        <MobileLayout
          header="Email verification link has expired"
          subHeader={
            <>
              Try to{' '}
              <Link to={ERoutesPublic.Signup} className="link" title="Sign up">
                sign up
              </Link>{' '}
              again. If you have already created an account, you may{' '}
              <Link to={ERoutesPublic.Login} className="link" title="Login">
                log in
              </Link>{' '}
              to the platform.
            </>
          }
        />
      }
    />
  );
};
