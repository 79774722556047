import React from 'react';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { useAppDispatch } from 'app/store/store.hooks';
import { setUiAuthModal } from 'app/store/reducers/ui.reducer';
import { SignUp } from 'entities/Auth/components/SignUpForm';
import { EAuthModals } from 'entities/UI/UI.models';

export const SignupPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const openAuthModal = () => {
    dispatch(setUiAuthModal({ isVisible: true, type: EAuthModals.Verify }));
  };

  return (
    <TwoColumnsLayout
      left={
        <MobileLayout header="Registration" subHeader="It will take less than a minute">
          <SignUp onSuccess={openAuthModal} />
        </MobileLayout>
      }
    />
  );
};
