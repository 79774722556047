import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAddToBagConfirmModalModel,
  IAuthModalModel,
  ICartItemModalModel,
  IChangeStoreConfirmModalModel,
  ICommonModalModel,
  IReorderConfirmModalModel,
  ISocialLinkModalModel
} from 'entities/UI/UI.models';

export interface IState {
  uiCommonModal: ICommonModalModel | null;
  uiAuthModal: IAuthModalModel | null;
  uiSocialLinkModal: ISocialLinkModalModel | null;
  uiReorderConfirmModal: IReorderConfirmModalModel | null;
  uiCartItemModal: ICartItemModalModel | null;
  uiChangeStoreConfirmModal: IChangeStoreConfirmModalModel | null;
  uiAddToBagConfirmModal: IAddToBagConfirmModalModel | null;
}

const initialState: IState = {
  uiCommonModal: { isVisible: false },
  uiAuthModal: { isVisible: false },
  uiSocialLinkModal: { isVisible: false },
  uiReorderConfirmModal: { isVisible: false },
  uiCartItemModal: { isVisible: false },
  uiChangeStoreConfirmModal: { isVisible: false },
  uiAddToBagConfirmModal: { isVisible: false }
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setUiCommonModal(state, action: PayloadAction<ICommonModalModel>) {
      state.uiCommonModal = action.payload;
    },
    setUiAuthModal(state, action: PayloadAction<IAuthModalModel>) {
      state.uiAuthModal = action.payload;
    },
    setUiSocialLinkModal(state, action: PayloadAction<ISocialLinkModalModel>) {
      state.uiSocialLinkModal = action.payload;
    },
    setUiReorderConfirmModal(state, action: PayloadAction<IReorderConfirmModalModel>) {
      state.uiReorderConfirmModal = action.payload;
    },
    setUiCartItemModal(state, action: PayloadAction<ICartItemModalModel>) {
      state.uiCartItemModal = action.payload;
    },
    setUiChangeStoreConfirmModal(state, action: PayloadAction<IChangeStoreConfirmModalModel>) {
      state.uiChangeStoreConfirmModal = action.payload;
    },
    setUiAddToBagConfirmModal(state, action: PayloadAction<IAddToBagConfirmModalModel>) {
      state.uiAddToBagConfirmModal = action.payload;
    }
  }
});

export const {
  setUiCommonModal,
  setUiAuthModal,
  setUiSocialLinkModal,
  setUiReorderConfirmModal,
  setUiCartItemModal,
  setUiChangeStoreConfirmModal,
  setUiAddToBagConfirmModal
} = uiSlice.actions;
export default uiSlice.reducer;
