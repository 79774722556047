import React from 'react';
import { Link } from 'react-router-dom';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { ERoutesPublic } from 'common/models/routesModel';
import { LoginForm } from 'entities/Auth/components/LoginForm';

export const LoginPage: React.FC = () => {
  return (
    <TwoColumnsLayout
      left={
        <MobileLayout header="Log In">
          <LoginForm />

          <div className="mt-8 fs">
            Don&apos;t have an account yet?
            <Link to={ERoutesPublic.Signup} className="link ml-4">
              Sign up
            </Link>
          </div>
        </MobileLayout>
      }
    />
  );
};
