import { IError } from '@axmit/error-helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { getCustomerFullName } from 'common/helpers/Customer.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { GDLHelper } from 'common/helpers/GDL.helper';
import { AppDispatch, RootState, history } from 'app/store';
import { getCustomerModel } from 'app/store/reducers/customer.reducer';
import { getCartModel } from 'app/store/reducers/cart.reducer';
import { getMenuAddOns } from 'app/store/reducers/menu.reducer';
import { EAuthErrorCode, EAuthErrorMessage } from 'entities/Auth/Auth.models';
import { ECartErrorCode, ECartErrorMessage } from 'entities/Cart/Cart.models';
import { isUnavailableError } from 'entities/Orders/Orders.helpers';
import {
  EOrdersErrorCode,
  EOrdersErrorMessage,
  IAddOrderParams,
  IOrderModel,
  IRejectOrderParams,
} from 'entities/Orders/Orders.models';
import { orderTransport } from 'entities/Orders/Orders.transport';
import { EStoreErrorCode, EStoreErrorMessage } from 'entities/Store/Store.models';

export interface IState {
  // order model
  orderModel: IOrderModel | null;
  orderModelLoading: boolean;
  orderModelError: IError | null;
}

const initialState: IState = {
  // order model
  orderModel: null,
  orderModelLoading: false,
  orderModelError: null,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // order model
    setOrderModelLoading(state, action: PayloadAction<boolean>) {
      state.orderModelLoading = action.payload;
    },
    setOrderModel(state, action: PayloadAction<IOrderModel | null>) {
      state.orderModel = action.payload;
    },
    setOrderModelError(state, action: PayloadAction<IError | null>) {
      state.orderModelError = action.payload;
    },
  },
});

export const { setOrderModelLoading, setOrderModel, setOrderModelError } = orderSlice.actions;
export default orderSlice.reducer;

export const addOrder = (params: IAddOrderParams) => {
  async function thunk(dispatch: AppDispatch, getState: () => RootState) {
    dispatch(setOrderModelLoading(true));

    const store = getState();

    try {
      const orderModel = await orderTransport.postOrder(params);

      dispatch(setOrderModel(orderModel));

      const { customerData, id, total } = orderModel || {};
      const items = store.cart?.cartModel?.items;
      const customerFullName = getCustomerFullName(customerData);

      history.push(ERoutesPrivate.Order);

      if (items) {
        GDLHelper.pushPurchaseEvent(customerFullName, customerData?.email || '', id, total, items);
      }

      dispatch(getCartModel());
    } catch (error) {
      const _error = error as IError;

      dispatch(setOrderModelError(_error));

      if (_error?.data?.code === EOrdersErrorCode.ActiveOrderExists) {
        message.error(EOrdersErrorMessage.ActiveOrderExists);
      }
      if (_error?.data?.code === EAuthErrorCode.UserBlocked) {
        message.error(EAuthErrorMessage.UserBlocked);
      }
      if (_error?.data?.code === EStoreErrorCode.StoreIsClosed) {
        message.error(EStoreErrorMessage.StoreIsClosed);
        const userId = store.auth.authModel?.access?.userId;

        if (userId) {
          dispatch(getCustomerModel(userId));
        }
      }

      const hasUnavailableError = isUnavailableError(_error);

      if (hasUnavailableError) {
        dispatch(getCartModel());
        const storeId = store?.customer?.customerModel?.store?.id;

        if (storeId) {
          dispatch(getMenuAddOns(storeId));
        }
      }

      if (_error?.data?.code === ECartErrorCode.PromoNotFound) {
        message.error(ECartErrorMessage.PromoNotFound);
      }

      if (_error?.data?.code === EStoreErrorCode.NotFound) {
        message.error(EStoreErrorMessage.NotFound);
      }
      if (_error?.data?.code === EOrdersErrorCode.EmptyItems) {
        message.error(EOrdersErrorMessage.EmptyItems);
      }
    } finally {
      dispatch(setOrderModelLoading(false));
    }
  }

  return thunk;
};

export const rejectOrder = (params: IRejectOrderParams, failCb: () => void) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setOrderModelLoading(true));

    try {
      const orderModel = await orderTransport.rejectOrder(params);

      dispatch(setOrderModel(orderModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setOrderModelError(_error));

      if (_error.data.code === EOrdersErrorCode.AlreadyFinished) {
        message.error(EOrdersErrorMessage.AlreadyFinished);

        if (failCb) {
          failCb();
        }
      }
    } finally {
      dispatch(setOrderModelLoading(false));
    }
  }

  return thunk;
};

export const getCurrentOrder = () => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setOrderModelLoading(true));

    try {
      const orderModel = await orderTransport.getCurrentOrder();

      dispatch(setOrderModel(orderModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setOrderModelError(_error));
    } finally {
      dispatch(setOrderModelLoading(false));
    }
  }

  return thunk;
};
