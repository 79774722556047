import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { Drawer, Modal } from 'antd';
import { MEDIA_MOBILE, MODAL_MAX_WIDTH_DESKTOP } from 'common/config/base.config';
import { ReactComponent as SvgClose } from 'app/assets/images/close.svg';

export interface IModalContent {
  [key: string]: { content: JSX.Element; header?: string; subheader?: string };
}

interface IComponentProps {
  close: () => void;
  visible?: boolean;
  header?: string;
  subHeader?: string | React.ReactNode;
  afterClose?: () => void;
  closable?: boolean;
}

export const ModalContent: React.FC<IComponentProps> = (props) => {
  const { children, visible, close, header, subHeader, afterClose, closable } = props;

  const isMobile: boolean = useMediaPredicate(MEDIA_MOBILE);

  const content = (
    <>
      {header && <div className="modal-content__header">{header}</div>}
      {subHeader && <div className="modal-content__subheader">{subHeader}</div>}

      {children}
    </>
  );

  return isMobile ? (
    <Drawer
      placement="bottom"
      closable={!!closable}
      open={visible}
      onClose={close}
      closeIcon={<SvgClose />}
      key="bottom"
      className="modal-content modal-content_mobile"
      height="auto"
      afterOpenChange={(drawerVisible) => !drawerVisible && afterClose && afterClose()}
    >
      {content}
    </Drawer>
  ) : (
    <Modal
      closeIcon={<SvgClose />}
      closable={!!closable}
      destroyOnClose
      className="modal-content modal-content_desktop"
      open={visible}
      footer={null}
      afterClose={afterClose}
      width={MODAL_MAX_WIDTH_DESKTOP}
      onCancel={close}
    >
      {content}
    </Modal>
  );
};
