import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '@axmit/error-helper';
import { message } from 'antd';
import { getCustomerFullName } from 'common/helpers/Customer.helper';
import { GDLHelper } from 'common/helpers/GDL.helper';
import { EErrorStatus } from 'common/models/requestModels';
import { AppDispatch, RootState } from 'app/store';
import { getPromoModel } from 'app/store/reducers/promo.reducer';
import {
  ECartErrorCode,
  ECartErrorMessage,
  EItemErrorText,
  ICartAddItemParams,
  ICartAddPromoCodeParams,
  ICartModel,
  ICartUpdateItemParams,
} from 'entities/Cart/Cart.models';
import { cartTransport } from 'entities/Cart/Cart.transport';
import { EStoreErrorCode, EStoreErrorMessage } from 'entities/Store/Store.models';
import { EMenuErrorCode, EMenuErrorMessage } from 'entities/Menu/Menu.models';
import { EOrdersErrorCode } from 'entities/Orders/Orders.models';

export interface IState {
  cartModelLoading: boolean;
  cartModel: ICartModel | null;
  cartModelError: IError | null;
  addItemError: IError | null;
}

const initialState: IState = {
  cartModelLoading: false,
  cartModel: null,
  cartModelError: null,
  addItemError: null,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartModelLoading(state, action: PayloadAction<boolean>) {
      state.cartModelLoading = action.payload;
    },
    setCartModel(state, action: PayloadAction<ICartModel | null>) {
      state.cartModel = action.payload;
    },
    setCartModelError(state, action: PayloadAction<IError | null>) {
      state.cartModelError = action.payload;
    },
    setAddItemError(state, action: PayloadAction<IError | null>) {
      state.addItemError = action.payload;
    },
  },
});

export const { setCartModelLoading, setCartModel, setCartModelError, setAddItemError } = cartSlice.actions;
export default cartSlice.reducer;

export const getCartModel = () => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCartModelLoading(true));

    try {
      const cartModel = await cartTransport.getCartModel();

      dispatch(setCartModel(cartModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setCartModelError(_error));
    } finally {
      dispatch(setCartModelLoading(false));
    }
  }

  return thunk;
};

export const addItem = (params: ICartAddItemParams, successCb?: () => void) => {
  async function thunk(dispatch: AppDispatch, getState: () => RootState) {
    dispatch(setCartModelLoading(true));

    try {
      const cartModel = await cartTransport.addItem(params);

      dispatch(setCartModel(cartModel));

      const { items, total } = cartModel;
      const { menuItemId } = params;
      const customerData = getState().customer.customerModel;
      const { email } = customerData || {};
      const customerFullName = getCustomerFullName(customerData);

      if (menuItemId && items && total) {
        const cartItem = items.find((item) => item.menuItemId === menuItemId);
        cartItem && GDLHelper.pushAddToBagEvent(customerFullName, email || '', total, cartItem);
      }

      if (successCb) {
        successCb();
      }
    } catch (error) {
      const _error = error as IError;

      dispatch(setAddItemError(_error));

      const errorCode = _error?.data?.code;

      if (errorCode === EStoreErrorCode.NotFound) {
        message.error(EStoreErrorMessage.NotFound);
      }

      if (errorCode === ECartErrorCode.CartQRItemNotFound) {
        message.error(EItemErrorText.ItemTUText);
      }

      if (errorCode === EMenuErrorCode.ItemNotFound) {
        message.error(EMenuErrorMessage.ItemNotFound);
      }
    } finally {
      dispatch(setCartModelLoading(false));
    }
  }

  return thunk;
};

export const updateItem = (params: ICartUpdateItemParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCartModelLoading(true));

    try {
      const cartModel = await cartTransport.updateItem(params);

      dispatch(setCartModel(cartModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setAddItemError(_error));

      const errorCode = _error?.data?.code;

      if (errorCode === EStoreErrorCode.NotFound) {
        message.error(EStoreErrorMessage.NotFound);
      }
      if (errorCode === ECartErrorCode.CartItemNotFound) {
        message.error(ECartErrorMessage.CartItemNotFound);
      }
      if (errorCode === EMenuErrorCode.ItemNotFound) {
        message.error(EMenuErrorMessage.ItemNotFound);
      }
    } finally {
      dispatch(setCartModelLoading(false));
    }
  }

  return thunk;
};

export const deleteItem = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCartModelLoading(true));

    try {
      const cartModel = await cartTransport.deleteItem(id);

      dispatch(setCartModel(cartModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setAddItemError(_error));

      if (_error?.data?.code === ECartErrorCode.PromoTemporarilyUnavailable) {
        message.error(ECartErrorMessage.PromoTemporarilyUnavailable);

        return;
      }

      if (
        _error?.data?.code === ECartErrorCode.PromoNotFound ||
        (_error?.data?.statusCode === EErrorStatus.Validation && _error?.data?.code !== EOrdersErrorCode.UnavailablePromo)
      ) {
        message.error(ECartErrorMessage.PromoNotFound);
      }
    } finally {
      dispatch(setCartModelLoading(false));
    }
  }

  return thunk;
};

export const addPromoCode = (params: ICartAddPromoCodeParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCartModelLoading(true));

    try {
      const cartModel = await cartTransport.addPromoCode(params);

      dispatch(setCartModel(cartModel));
      dispatch(getPromoModel());
    } catch (error) {
      const _error = error as IError;

      dispatch(setCartModelError(_error));

      if (_error?.data?.code === ECartErrorCode.PromoTemporarilyUnavailable) {
        message.error(ECartErrorMessage.PromoTemporarilyUnavailable);

        return;
      }

      if (
        _error?.data?.code === ECartErrorCode.PromoNotFound ||
        (_error?.data?.statusCode === EErrorStatus.Validation && _error?.data?.code !== EOrdersErrorCode.UnavailablePromo)
      ) {
        message.error(ECartErrorMessage.PromoNotFound);
      }
    } finally {
      dispatch(setCartModelLoading(false));
    }
  }

  return thunk;
};

export const deletePromoCode = () => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCartModelLoading(true));

    try {
      const cartModel = await cartTransport.deletePromoCode();

      dispatch(setCartModel(cartModel));
      dispatch(getPromoModel());
    } catch (error) {
      const _error = error as IError;

      dispatch(setCartModelError(_error));
    } finally {
      dispatch(setCartModelLoading(false));
    }
  }

  return thunk;
};
