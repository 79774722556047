import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { ModalContent } from 'common/components';
import { EAuthSocialErrorCode, socialErrorMessages } from 'entities/Auth/Auth.models';

interface IComponentProps {
  errorCode?: EAuthSocialErrorCode;
  isVisible: boolean;
  onClose?: () => void;
}

export const SocialLinkErrorsModal: React.FC<IComponentProps> = (props) => {
  const { errorCode, onClose, isVisible } = props;
  const header = errorCode && socialErrorMessages[errorCode] ? socialErrorMessages[errorCode] : 'Unknown error';
  const history = useHistory();

  const handleClose = () => {
    onClose && onClose();
    history.replace({ search: '' });
  };

  return (
    <ModalContent visible={isVisible} close={handleClose} header={header}>
      <Button size="large" block className="btn" type="primary" onClick={handleClose}>
        Got it!
      </Button>
    </ModalContent>
  );
};
