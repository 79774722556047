import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { useCallback, useEffect } from 'react';
import { ERoutesCommon } from 'common/models/routesModel';
import { ERatingsErrorMessage } from 'entities/Ratings/Ratings.models';
import { isOrderRateTimeExpired } from 'entities/OrderHistory/OrderHistory.helpers';
import { ECustomerStatus, ICustomerModel } from 'entities/Customer/Customer.models';
import { EAuthErrorMessage } from 'entities/Auth/Auth.models';
import { IOrderHistoryModel } from 'entities/OrderHistory/OrderHistory.models';

const useRedirectIfError = (orderHistory: IOrderHistoryModel | null, customer: ICustomerModel | null) => {
  const history = useHistory();
  const redirect = useCallback(
    (msg: string) => {
      history.replace(ERoutesCommon.Main);
      message.error(msg);
    },
    [history]
  );

  useEffect(() => {
    if (isOrderRateTimeExpired(orderHistory)) {
      redirect(ERatingsErrorMessage.RatingTimeExpired);
      return;
    }

    if (orderHistory?.isRatingExist) {
      redirect(ERatingsErrorMessage.RatingAlreadyExists);
    }
  }, [orderHistory, history, redirect]);

  useEffect(() => {
    if (customer?.status === ECustomerStatus.Blocked) {
      redirect(EAuthErrorMessage.UserBlocked);
    }
  }, [customer, history, redirect]);
};

export const RatingsHooks = { useRedirectIfError };
