import { IError } from '@axmit/error-helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppDispatch } from 'app/store';
import { IQRModelData } from 'entities/QR/QR.models';

export interface IState {
  // qr model
  qrModel: IQRModelData | null;
  qrModelLoading: boolean;
  qrModelError: IError | null;
}

const initialState: IState = {
  // qr model
  qrModel: null,
  qrModelLoading: false,
  qrModelError: null,
};

export const qrSlice = createSlice({
  name: 'qr',
  initialState,
  reducers: {
    // qr model
    setQRModelLoading(state, action: PayloadAction<boolean>) {
      state.qrModelLoading = action.payload;
    },
    setQRModel(state, action: PayloadAction<IQRModelData | null>) {
      state.qrModel = action.payload;
    },
    setQRModelError(state, action: PayloadAction<IError | null>) {
      state.qrModelError = action.payload;
    },
  },
});

export const { setQRModelLoading, setQRModel, setQRModelError } = qrSlice.actions;
export default qrSlice.reducer;

export const getQrModel = (name: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setQRModelLoading(true));

    try {
      const qrModel: IQRModelData = await axios.get(`qr-codes/${name}/item`);

      dispatch(setQRModel(qrModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setQRModelError(_error));
    } finally {
      dispatch(setQRModelLoading(false));
    }
  }

  return thunk;
};
