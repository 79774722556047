import React, { useCallback, useEffect, useMemo } from 'react';
import { Spin, List } from 'antd';
import { getStoreShortId } from 'common/helpers/Store.helper';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { setCustomerStore } from 'app/store/reducers/customer.reducer';
import { getDefaultMenu, getMenuAddOns, getMenuCollection } from 'app/store/reducers/menu.reducer';
import { getStoreModelById } from 'app/store/reducers/store.reducer';
import { setUiCartItemModal, setUiChangeStoreConfirmModal, setUiCommonModal } from 'app/store/reducers/ui.reducer';
import { EStoreErrorCode, EStoresLSStatus, IStoreModel } from 'entities/Store/Store.models';
import { MenuDetailsModal } from 'entities/UI/components/MenuDetailsModal';
import { MenuCategoriesListItem } from 'entities/Menu/components/MenuCategoriesListItem';
import { StorePickerModal } from 'entities/Store/components/StorePickerModal';
import { IMenuItemWithCategoryNameAndType } from 'entities/Menu/Menu.models';
import { ChangeStoreConfirmModal } from 'entities/Store/components/ChangeStoreConfirmModal';

export const MenuPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { authModel, authModelLoading } = useAppSelector((state) => state.auth);
  const { menuCollection, menuCollectionLoading } = useAppSelector((state) => state.menu);
  const { cartModel } = useAppSelector((state) => state.cart);
  const { customerModel, customerModelLoading } = useAppSelector((state) => state.customer);
  const { storeModel, storeModelLoading, storeModelError, storeLsStatus, storeLsStatusLoading } = useAppSelector(
    (state) => state.store
  );
  const { categoriesToAsset: menu } = menuCollection || {};

  const isDefaultMenu = useMemo(() => {
    return !customerModel?.store?.id && !storeModel?.id;
  }, [customerModel, storeModel]);

  useEffect(() => {
    const dataLoading = authModelLoading || customerModelLoading || storeModelLoading || storeLsStatusLoading;

    if (dataLoading) {
      return;
    }

    const customerStoreId = customerModel?.store?.id;
    const storeIdFromLS = storeModel?.id;
    const authorized = Boolean(authModel);
    const incorrectStoreCodeError = storeModelError?.data?.code === EStoreErrorCode.NotFound;
    const isStoreInfoUpdated = storeLsStatus === EStoresLSStatus.Updated;

    const isNonAuthUserWithoutStore = !authorized && !customerModel?.userId && !storeModel;
    const isNonAuthUserWithStore = storeIdFromLS && !authorized && !customerModel?.userId;
    const isAuthUserWithoutStore = authorized && customerModel?.userId && !customerStoreId;
    const isAuthUserWithStore = customerStoreId && authorized;

    const storeShortId = getStoreShortId();

    const needResetAuthUserStoreFromLS = (isAuthUserWithoutStore || isAuthUserWithStore) && storeIdFromLS;
    const needResetAuthUserStoreFromCode = (isAuthUserWithoutStore || isAuthUserWithStore) && storeShortId;

    if (incorrectStoreCodeError || needResetAuthUserStoreFromLS || needResetAuthUserStoreFromCode) {
      return;
    }

    if (isNonAuthUserWithoutStore || isAuthUserWithoutStore) {
      dispatch(getDefaultMenu());
    }

    if (isAuthUserWithStore) {
      dispatch(getMenuCollection(customerStoreId));
      dispatch(getMenuAddOns(customerStoreId));
    }

    if (isNonAuthUserWithStore && isStoreInfoUpdated) {
      dispatch(getMenuCollection(storeIdFromLS));
      dispatch(getMenuAddOns(storeIdFromLS));
    }
  }, [
    authModel,
    authModelLoading,
    customerModel,
    customerModelLoading,
    storeModel,
    storeModelLoading,
    storeLsStatus,
    storeLsStatusLoading,
  ]);

  const handleMenuItemClick = useCallback(
    (item: IMenuItemWithCategoryNameAndType) => {
      if (isDefaultMenu) {
        dispatch(setUiCommonModal({ isVisible: true }));
      } else {
        dispatch(setUiCartItemModal({ isVisible: true, menuItem: item }));
      }
    },
    [isDefaultMenu]
  );

  const handleChangeStoreConfirm = (store: IStoreModel) => {
    changeStore(store);
  };

  const changeStore = (newStore: IStoreModel) => {
    const { id: newStoreId } = newStore;
    const { store: customerStore, userId } = customerModel || {};
    const finalStore = customerStore || storeModel;
    const isSameStore = finalStore && finalStore.id === newStoreId;

    if (userId && !isSameStore) {
      dispatch(setCustomerStore({ id: userId, store: newStoreId }));
    }
    if (!userId && !isSameStore) {
      dispatch(getStoreModelById(newStoreId));
    }
  };

  const handleSelectStore = (selectedStore: IStoreModel) => {
    const isCartEmpty = !cartModel || cartModel?.items?.length === 0;

    if (!isCartEmpty) {
      dispatch(setUiChangeStoreConfirmModal({ isVisible: true, selectedStore }));

      return;
    }

    changeStore(selectedStore);
  };

  return (
    <>
      <Spin spinning={menuCollectionLoading} className="mt-2">
        <List
          dataSource={menu}
          renderItem={(category) => (
            <MenuCategoriesListItem category={category} isDefaultMenu={isDefaultMenu} onMenuItemClick={handleMenuItemClick} />
          )}
        />
      </Spin>
      <MenuDetailsModal />
      <StorePickerModal onSelect={handleSelectStore} />
      <ChangeStoreConfirmModal onContinue={handleChangeStoreConfirm} />
    </>
  );
};
