import { IError } from '@axmit/error-helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LS_KEY_STORE } from 'common/consts/LocalStorage.const';
import { ERoutesCommon } from 'common/models/routesModel';
import { AppDispatch, RootState, history } from 'app/store';
import { setCustomerStore } from 'app/store/reducers/customer.reducer';
import { EStoresLSStatus, IStoreCollection, IStoreCollectionParams, IStoreModel } from 'entities/Store/Store.models';
import { storeTransport } from 'entities/Store/Store.transport';

export interface IState {
  // store model
  storeModel: IStoreModel | null;
  storeModelLoading: boolean;
  storeModelError: IError | null;
  // store collection
  storeCollection: IStoreCollection | null;
  storeCollectionLoading: boolean;
  storeCollectionError: IError | null;
  // store lsStatus
  storeLsStatus: string | null;
  storeLsStatusLoading: boolean;
  storeLsStatusError: IError | null;
}

const initialState: IState = {
  // store model
  storeModel: null,
  storeModelLoading: false,
  storeModelError: null,
  // store collection
  storeCollection: null,
  storeCollectionLoading: false,
  storeCollectionError: null,
  // store lsStatus
  storeLsStatus: null,
  storeLsStatusLoading: false,
  storeLsStatusError: null,
};

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    // store model
    setStoreModelLoading(state, action: PayloadAction<boolean>) {
      state.storeModelLoading = action.payload;
    },
    setStoreModel(state, action: PayloadAction<IStoreModel | null>) {
      state.storeModel = action.payload;
    },
    setStoreModelError(state, action: PayloadAction<IError | null>) {
      state.storeModelError = action.payload;
    },
    // store collection
    setStoreCollectionLoading(state, action: PayloadAction<boolean>) {
      state.storeCollectionLoading = action.payload;
    },
    setStoreCollection(state, action: PayloadAction<IStoreCollection | null>) {
      state.storeCollection = action.payload;
    },
    setStoreCollectionError(state, action: PayloadAction<IError | null>) {
      state.storeCollectionError = action.payload;
    },
    // store lsStatus
    setStoreLsStatusLoading(state, action: PayloadAction<boolean>) {
      state.storeLsStatusLoading = action.payload;
    },
    setStoreLsStatus(state, action: PayloadAction<string | null>) {
      state.storeLsStatus = action.payload;
    },
    setStoreLsStatusError(state, action: PayloadAction<IError | null>) {
      state.storeLsStatusError = action.payload;
    },
  },
});

export const {
  setStoreModelLoading,
  setStoreModel,
  setStoreModelError,
  setStoreCollectionLoading,
  setStoreCollection,
  setStoreCollectionError,
  setStoreLsStatusLoading,
  setStoreLsStatus,
  setStoreLsStatusError,
} = storeSlice.actions;
export default storeSlice.reducer;

export const initStoreModel = () => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setStoreLsStatus(EStoresLSStatus.NonUpdated));

    const storeFromLS = localStorage.getItem(LS_KEY_STORE);

    if (storeFromLS) {
      const parcedStoreData = JSON.parse(storeFromLS);

      dispatch(setStoreModel(parcedStoreData));
      dispatch(getStoreModelById(parcedStoreData.id));
    } else {
      dispatch(setStoreModel(null));
    }
  }

  return thunk;
};

export const clearStoreModel = () => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setStoreModel(null));
    localStorage.removeItem(LS_KEY_STORE);
  }

  return thunk;
};

export const getStoreModelById = (storeId: string) => {
  async function thunk(dispatch: AppDispatch, getState: () => RootState) {
    dispatch(setStoreModelLoading(true));

    try {
      const storeModel = await storeTransport.getStoreModelById(storeId);

      dispatch(setStoreModel(storeModel));

      localStorage.setItem(LS_KEY_STORE, JSON.stringify(storeModel));
      const lsStatus = getState().store.storeLsStatus;

      if (lsStatus && lsStatus === EStoresLSStatus.NonUpdated) {
        dispatch(setStoreLsStatus(EStoresLSStatus.Updated));
      }
    } catch (error) {
      const _error = error as IError;

      dispatch(setStoreModelError(_error));
    } finally {
      dispatch(setStoreModelLoading(false));
    }
  }

  return thunk;
};

export const getStoreModelByShortId = (storeShortId: string) => {
  async function thunk(dispatch: AppDispatch, getState: () => RootState) {
    dispatch(setStoreModelLoading(true));

    try {
      const storeModel = await storeTransport.getStoreModelByShortId(storeShortId);

      dispatch(setStoreModel(storeModel));

      const userId = getState().auth.authModel?.access?.userId;

      if (userId) {
        dispatch(setCustomerStore({ id: userId, store: storeModel.id }));
      }

      if (!userId) {
        localStorage.setItem(LS_KEY_STORE, JSON.stringify(storeModel));
        dispatch(setStoreLsStatus(EStoresLSStatus.Updated));
      }
    } catch (error) {
      const _error = error as IError;

      dispatch(setStoreModelError(_error));
      dispatch(initStoreModel());

      history.push(ERoutesCommon.Root);
    } finally {
      dispatch(setStoreModelLoading(false));
    }
  }

  return thunk;
};

export const getStoreCollection = (params?: IStoreCollectionParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setStoreCollectionLoading(true));

    try {
      const storeCollection = await storeTransport.getStoreCollection(params);

      dispatch(setStoreCollection(storeCollection));
    } catch (error) {
      const _error = error as IError;

      dispatch(setStoreCollectionError(_error));
    } finally {
      dispatch(setStoreCollectionLoading(false));
    }
  }

  return thunk;
};
