import React, { useState } from 'react';
import {} from 'react-router';
import { Button, Row, Drawer } from 'antd';
import { Link } from 'react-router-dom';
import { ERoutesCommon } from 'common/models/routesModel';
import { HeaderMenu } from 'common/components/Header/HeaderMenu';
import menuBurger from 'app/assets/images/menuBurger.svg';
import menuClose from 'app/assets/images/menuClose.svg';
import { ShopBag } from 'entities/Orders/components/ShopBag';

export const HeaderMenuWrapperMob: React.FC = () => {
  const [isVisibleMobMenu, setIsVisibleMobMenu] = useState<boolean>(false);

  const showDrawer = () => {
    setIsVisibleMobMenu(true);
  };

  const closeDrawer = () => {
    setIsVisibleMobMenu(false);
  };

  return (
    <>
      <Row gutter={10} align="middle">
        <Button className="btn-opacity" onClick={showDrawer}>
          <img src={menuBurger} alt="open menu" />
        </Button>

        <Link to={ERoutesCommon.Root}>
          <span className="header__title header__title_with-p">MB Go!</span>
        </Link>
      </Row>

      <Drawer
        placement="top"
        closable={false}
        onClose={closeDrawer}
        open={isVisibleMobMenu}
        key="top"
        className="header-menu__drawer"
        height="auto"
      >
        <Row gutter={10} align="middle">
          <Button className="btn-opacity btn-opacity_sm" onClick={closeDrawer}>
            <img src={menuClose} alt="close menu" />
          </Button>

          <Link to={ERoutesCommon.Root} className="flex__item_1 mt-1">
            <span className="header__title header__title_with-p">MB Go!</span>
          </Link>

          <ShopBag />
        </Row>

        <HeaderMenu mobile close={closeDrawer} />
      </Drawer>
    </>
  );
};
