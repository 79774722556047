import React from 'react';
import { Button } from 'antd';
import { ModalContent } from 'common/components';
import { useAppDispatch } from 'app/store/store.hooks';
import { disconnectFacebook, disconnectGoogle } from 'app/store/reducers/customer.reducer';
import { ESocialType } from 'entities/Auth/Auth.models';

interface IComponentProps {
  isVisible: boolean;
  socialType?: ESocialType;
  onClose: () => void;
}

const socialTypeLabels: Record<ESocialType, string> = {
  [ESocialType.Facebook]: 'Facebook',
  [ESocialType.Google]: 'Google'
};

export const SocialUnlinkConfirmModal: React.FC<IComponentProps> = props => {
  const dispatch = useAppDispatch();
  const { isVisible, socialType, onClose } = props;
  const header = socialType
    ? `Are you sure you want to remove your ${socialTypeLabels[socialType]} account from your MB Go account? `
    : '';
  const subHeader = socialType
    ? `You may need to remove MB Go from your allowed apps from your ${socialTypeLabels[socialType]} account for this to be completed.`
    : '';

  const disconnectSocial = () => {
    socialType === ESocialType.Facebook && dispatch(disconnectFacebook());
    socialType === ESocialType.Google && dispatch(disconnectGoogle());
    onClose();
  };

  return (
    <ModalContent visible={isVisible} close={onClose} header={header} subHeader={subHeader}>
      <Button size="large" block className="btn mb-7" type="primary" onClick={disconnectSocial}>
        Remove
      </Button>
      <Button block className="btn" onClick={onClose}>
        Cancel
      </Button>
    </ModalContent>
  );
};
