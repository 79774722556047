import React from 'react';
import { Button } from 'antd';
import { ModalContent } from 'common/components';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { setUiChangeStoreConfirmModal } from 'app/store/reducers/ui.reducer';
import { IStoreModel } from 'entities/Store/Store.models';

interface IComponentProps {
  onContinue(store: IStoreModel): void;
}

export const ChangeStoreConfirmModal: React.FC<IComponentProps> = props => {
  const dispatch = useAppDispatch();
  const { onContinue } = props;
  const { uiChangeStoreConfirmModal } = useAppSelector(state => state.ui);
  const { isVisible, selectedStore } = uiChangeStoreConfirmModal || {};
  const header = 'One or more items in your order may not be available in this store, or may be available at a different price.';
  const subHeader = 'Unavailable items will be removed from your Bag and prices will be updated.';

  const closeUiAddToBagConfirmModal = () => {
    dispatch(setUiChangeStoreConfirmModal({ isVisible: false }));
  };

  const submit = () => {
    selectedStore && onContinue(selectedStore);
    closeUiAddToBagConfirmModal();
  };

  return (
    <ModalContent header={header} subHeader={subHeader} close={closeUiAddToBagConfirmModal} visible={!!isVisible}>
      <Button size="large" block className="btn mb-7" type="primary" onClick={submit}>
        Continue
      </Button>
      <Button size="large" block className="btn" onClick={closeUiAddToBagConfirmModal}>
        Cancel
      </Button>
    </ModalContent>
  );
};
