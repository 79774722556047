import React from 'react';
import { Button } from 'antd';
import { ModalContent } from 'common/components';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { setUiAddToBagConfirmModal } from 'app/store/reducers/ui.reducer';

interface IComponentProps {
  onConfirm(): void;
}

const header = 'You are about to update your pick-up Store location.';
const subHeader =
  'You currently have a different store selected. If you continue, your current store will be updated and any items in your bag will be replaced with the scanned item.';

export const AddToBagConfirmModal: React.FC<IComponentProps> = ({ onConfirm }) => {
  const dispatch = useAppDispatch();
  const { uiAddToBagConfirmModal } = useAppSelector(state => state.ui);
  const { isVisible } = uiAddToBagConfirmModal || {};

  const closeUiAddToBagConfirmModal = () => {
    dispatch(setUiAddToBagConfirmModal({ isVisible: false }));
  };

  const submit = () => {
    onConfirm();
    closeUiAddToBagConfirmModal();
  };

  return (
    <ModalContent header={header} subHeader={subHeader} close={closeUiAddToBagConfirmModal} visible={!!isVisible}>
      <Button size="large" block className="btn mb-7" type="primary" onClick={submit}>
        Continue
      </Button>
      <Button size="large" block className="btn" onClick={closeUiAddToBagConfirmModal}>
        Cancel
      </Button>
    </ModalContent>
  );
};
