import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import moment from 'moment';
import { useFormMapper } from 'common/helpers/form.helper';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { LoadingSpin, ModalContent } from 'common/components';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { DatePicker } from 'common/components/DatePicker';
import {
  emailValidationRules,
  firstNameValidationRules,
  lastNameValidationRules,
  phonePattern,
} from 'common/consts/validation.consts';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { updateCustomerModel } from 'app/store/reducers/customer.reducer';
import { setUiCommonModal, setUiSocialLinkModal } from 'app/store/reducers/ui.reducer';
import { ICustomerUpdateParams } from 'entities/Customer/Customer.models';
import { UpdatePasswordForm } from 'entities/User/components/UpdatePasswordForm';
import { SocialLinkBar } from 'entities/Auth/components/SocialLinkBar';
import { ESocialLinkModals } from 'entities/UI/UI.models';
import { SocialLinkModal } from 'entities/User/components/SocialLinkModal';
import { AuthHooks } from 'entities/Auth/Auth.hooks';

export const SettingsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { authModel } = useAppSelector((state) => state.auth);
  const { customerModel, customerModelParams, customerModelLoading, customerModelError } = useAppSelector(
    (state) => state.customer
  );
  const { uiCommonModal } = useAppSelector((state) => state.ui);
  const [birthdate, setBirthdate] = useState<moment.Moment | undefined>();
  const userId = authModel?.access?.userId;
  const isVisible = uiCommonModal?.isVisible;
  const isPasswordSet = !!customerModel?.isPasswordSet;
  const phone = customerModel?.phone;
  const title = isPasswordSet ? 'Change password' : 'Set password';
  const { fields } = useFormMapper(
    ['firstName', 'lastName', 'email', 'phone'],
    customerModel,
    customerModelParams,
    customerModelError
  );

  useEffect(() => {
    if (customerModel?.birthDate) {
      setBirthdate(moment(customerModel.birthDate));
    }
  }, [customerModel]);

  useEffect(() => {
    const socialConnectError = AuthHooks.useSocialConnectError();

    if (socialConnectError) {
      dispatch(setUiSocialLinkModal({ isVisible: true, type: ESocialLinkModals.LinkErrors, errorCode: socialConnectError }));
    }
  }, []);

  if (!userId) {
    return <LoadingSpin />;
  }

  const submit = (values: any) => {
    const firstName = values?.firstName || '';
    const lastName = values?.lastName || '';
    const phone = values?.phone || null;

    const updateParams: ICustomerUpdateParams = {
      id: userId,
      userId,
      birthDate: birthdate?.toISOString(),
      firstName,
      lastName,
      phone,
    };

    dispatch(updateCustomerModel(updateParams));
  };

  return (
    <TwoColumnsLayout
      left={
        <MobileLayout header="Account Settings">
          <Form fields={fields} onFinish={submit} requiredMark={false} layout="vertical">
            <Form.Item
              rules={[firstNameValidationRules.required, firstNameValidationRules.updateMin, firstNameValidationRules.max]}
              name="firstName"
              label="First name"
            >
              <Input name="firstName" type="text" placeholder="First name" disabled={customerModelLoading} />
            </Form.Item>

            <Form.Item
              rules={[lastNameValidationRules.required, lastNameValidationRules.updateMin, lastNameValidationRules.max]}
              name="lastName"
              label="Last name"
            >
              <Input name="lastName" type="text" placeholder="Last name" disabled={customerModelLoading} />
            </Form.Item>

            <Form.Item validateTrigger="onBlur" rules={emailValidationRules} name="email" label="Email">
              <Input name="email" type="email" placeholder="Email" disabled />
            </Form.Item>

            {phone && (
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  {
                    pattern: phonePattern,
                    message: 'Please input your mobile number (+63 9XX XXX XXXX)',
                  },
                ]}
              >
                <Input name="phone" type="tel" placeholder="+63 9XX XXX XXXX" disabled={customerModelLoading} />
              </Form.Item>
            )}

            <Form.Item label="Birthdate">
              <DatePicker
                disabled={customerModelLoading || !!customerModel?.birthDate}
                value={birthdate}
                onChange={(value: moment.Moment | undefined) => value && setBirthdate(value)}
              />
            </Form.Item>

            <Form.Item>
              <Button size="large" className="btn" block htmlType="submit" type="primary" disabled={customerModelLoading}>
                Save
              </Button>
            </Form.Item>
          </Form>

          <Button
            size="large"
            className="btn"
            onClick={() => dispatch(setUiCommonModal({ isVisible: true }))}
            block
            title={title}
          >
            {title}
          </Button>

          <SocialLinkBar className="mt-9" />

          <ModalContent visible={isVisible} close={() => dispatch(setUiCommonModal({ isVisible: false }))} header={title}>
            <UpdatePasswordForm userId={userId} isPasswordSet={isPasswordSet} />
          </ModalContent>

          <SocialLinkModal />
        </MobileLayout>
      }
    />
  );
};
