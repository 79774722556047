import React from 'react';
import { Button, Form, Input } from 'antd';
import { useFormMapper } from 'common/helpers/form.helper';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import {
  emailValidationRules,
  firstNameValidationRules,
  lastNameValidationRules,
  passValidationRules,
} from 'common/consts/validation.consts';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { completeCustomerRegistration } from 'app/store/reducers/customer.reducer';
import { ICustomerCompleteRegistrationParams } from 'entities/Customer/Customer.models';

export const CompleteRegistrationPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { customerModel, customerModelParams, customerModelLoading, customerModelError } = useAppSelector(
    (state) => state.customer
  );
  const { fields } = useFormMapper(
    ['firstName', 'lastName', 'email', 'password'],
    customerModel,
    customerModelParams,
    customerModelError
  );
  const disableEmailInput = !!customerModel?.email || customerModelLoading;

  const completeRegistration = (values: any) => {
    const { firstName, lastName, password, email } = values;
    const { userId: id } = customerModel || {};

    if (id) {
      const params: ICustomerCompleteRegistrationParams = {
        id,
        firstName,
        lastName,
        email,
      };

      if (password) {
        params.password = password;
      }

      dispatch(completeCustomerRegistration(params));
    }
  };

  return (
    <TwoColumnsLayout
      left={
        <MobileLayout header="Complete your registration" subHeader="It will take less than a minute">
          <Form onFinish={completeRegistration} layout="vertical" fields={fields}>
            <Form.Item
              rules={[firstNameValidationRules.required, firstNameValidationRules.signUpMin, firstNameValidationRules.max]}
              validateTrigger="onBlur"
              name="firstName"
              label="First name"
            >
              <Input name="firstName" placeholder="First name" disabled={customerModelLoading} />
            </Form.Item>

            <Form.Item
              rules={[lastNameValidationRules.required, lastNameValidationRules.signUpMin, lastNameValidationRules.max]}
              validateTrigger="onBlur"
              name="lastName"
              label="Last name"
            >
              <Input name="lastName" placeholder="Last name" disabled={customerModelLoading} />
            </Form.Item>

            <Form.Item validateTrigger="onBlur" rules={emailValidationRules} name="email" label="Email">
              <Input name="email" type="email" disabled={disableEmailInput} />
            </Form.Item>

            <Form.Item rules={[...passValidationRules]} validateFirst name="password" label="Password">
              <Input.Password type="password" disabled={customerModelLoading} />
            </Form.Item>

            <Button size="large" block className="btn" type="primary" htmlType="submit" disabled={customerModelLoading}>
              Continue
            </Button>
          </Form>
        </MobileLayout>
      }
    />
  );
};
