import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { getQueryParam } from 'common/helpers/url.helper';
import { QR_QUERY_PARAM_NAME } from 'common/consts/QR.const';
import { ERoutesCommon } from 'common/models/routesModel';
import { LoadingSpin, ModalContent } from 'common/components';
import { LS_KEY_STORE } from 'common/consts/LocalStorage.const';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { addItem } from 'app/store/reducers/cart.reducer';
import { setCustomerStore } from 'app/store/reducers/customer.reducer';
import { getQrModel } from 'app/store/reducers/qr.reducer';
import { setUiAddToBagConfirmModal, setUiAuthModal, setUiCommonModal } from 'app/store/reducers/ui.reducer';
import { StoreCard } from 'entities/QR/components/StoreCard';
import { ECartErrorCode, EItemErrorText } from 'entities/Cart/Cart.models';
import { IStoreModel, EStoreState } from 'entities/Store/Store.models';
import { StorePickerModal } from 'entities/Store/components/StorePickerModal';
import { ScannedItemCard } from 'entities/QR/components/ScannedItemCard';
import { EAuthModals } from 'entities/UI/UI.models';
import { EQRError } from 'entities/QR/QR.models';
import { AddToBagConfirmModal } from 'entities/QR/components/AddToBagConfirmModal';
import { IMenuItem } from 'entities/Menu/Menu.models';

export const ScanPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addItemError, cartModelLoading } = useAppSelector((state) => state.cart);
  const { qrModel, qrModelLoading, qrModelError } = useAppSelector((state) => state.qr);
  const { customerModel } = useAppSelector((state) => state.customer);
  const qrName = getQueryParam(QR_QUERY_PARAM_NAME) || '';
  const isAuthorized = !!customerModel?.userId;
  const storeOptions = useMemo(() => qrModel?.data.map(({ store }) => store) || [], [qrModel]);

  const [quantity, setQuantity] = useState<number>(1);
  const [selectedStore, setSelectedStore] = useState<IStoreModel>();
  const [menuItemFromQrCode, setMenuItemFromQrCode] = useState<IMenuItem>();
  const [selectedMenuItem, setSelectedMenuItem] = useState<IMenuItem>();
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);

  const qrError = qrModelError?.data.error;
  const isStoreSettedByQR = qrModel?.isStoreSetted;
  const isItemUnavailable = qrError === EQRError.MenuItemUnavailableException;
  const isStoreOffline = useMemo(() => selectedStore?.state !== EStoreState.Online, [selectedStore]);
  const isStoreUnavailable = qrError === EQRError.StoreTemporarilyUnavailableException;
  const lsStoreStr = localStorage.getItem(LS_KEY_STORE);
  const customerStore = customerModel?.store || (lsStoreStr ? JSON.parse(lsStoreStr) : lsStoreStr);
  const isStoreDifferent = useMemo(() => customerStore?.id !== selectedStore?.id, [selectedStore, customerStore]);
  const customerStoreInOptions = storeOptions.find(({ id }) => id === customerStore?.id);
  const menuItem = menuItemFromQrCode || selectedMenuItem;

  useEffect(() => {
    dispatch(getQrModel(qrName));
  }, [qrName]);

  useEffect(() => {
    const itemFromStore = qrModel?.data && qrModel.data[0].menuItemAsset;
    setMenuItemFromQrCode(() => itemFromStore);
  }, [qrModel]);

  useEffect(() => {
    setIsShowErrorModal(!!addItemError);
  }, [addItemError]);

  const modalErrorMessage = useMemo(() => {
    if (addItemError?.data?.code === ECartErrorCode.CartQRItemNotFound) {
      return `${EItemErrorText.ItemTUText} ${isStoreSettedByQR ? '' : EItemErrorText.ChangeStoreText}`;
    }
    if (addItemError?.data?.code === ECartErrorCode.StoreQRItemNotFound) {
      return `${EItemErrorText.StoreTUText} ${isStoreSettedByQR ? '' : EItemErrorText.ChangeStoreText}`;
    }
    return '';
  }, [addItemError]);

  useEffect(() => {
    if (isStoreSettedByQR) {
      handleSelectStore(storeOptions[0]);
    } else {
      if (customerStoreInOptions) {
        handleSelectStore(customerStoreInOptions);
      } else {
        customerStore && handleSelectStore(customerStore);
      }
    }
  }, [isStoreSettedByQR, storeOptions, customerStore, customerStoreInOptions]);

  const history = useHistory();

  const goToMain = () => {
    history.push(ERoutesCommon.Root);
  };

  const handleSelectStore = useCallback(
    (store: IStoreModel) => {
      setSelectedStore(store);
      const itemFromStore =
        qrModel?.data && qrModel.data.find(({ store: storeFromData }) => storeFromData.id === store.id)?.menuItemAsset;
      setSelectedMenuItem(() => itemFromStore);
    },
    [qrModel]
  );

  const handleStoreClick = useCallback(() => {
    dispatch(setUiCommonModal({ isVisible: true }));
  }, []);

  const handleAddToBagClick = useCallback(() => {
    if (!isAuthorized) {
      dispatch(setUiAuthModal({ isVisible: true, type: EAuthModals.Type }));
    } else if (isStoreDifferent) {
      dispatch(setUiAddToBagConfirmModal({ isVisible: true }));
    } else {
      confirmAddToBag();
    }
  }, [isAuthorized, isStoreDifferent, selectedMenuItem, quantity]);

  // TODO Try to refactor this method
  const confirmAddToBag = useCallback(() => {
    if (selectedMenuItem && selectedStore) {
      const { userId } = customerModel || {};

      if (userId) {
        if (isStoreDifferent) {
          dispatch(
            setCustomerStore({ id: userId, store: selectedStore.id }, () =>
              dispatch(addItem({ ...selectedMenuItem, quantity, addOns: [] }, goToMain))
            )
          );
        } else {
          dispatch(addItem({ ...selectedMenuItem, quantity, addOns: [] }, goToMain));
        }
      }
    }
  }, [isStoreDifferent, selectedMenuItem, selectedStore, quantity]);

  const isUserHasChoices = useMemo(() => storeOptions.length > 1, [storeOptions]);

  // TODO Try to move to separate helper
  const itemErrorText = useMemo(() => {
    let errorText = `We're sorry! `;
    let hasError = false;

    switch (true) {
      case qrModel && !customerStoreInOptions && customerStore && selectedStore?.id === customerStore.id:
        if (customerStore.status === 'active') {
          errorText += `${EItemErrorText.ItemTUInStoreText} ${EItemErrorText.ChangeStoreText} `;
          hasError = true;
        } else {
          errorText = `${EItemErrorText.StoreProblem} ${EItemErrorText.ChangeStoreText} `;
          hasError = true;
        }
        break;
      case isItemUnavailable && isUserHasChoices:
        errorText += `${EItemErrorText.ItemTUInStoreText} ${EItemErrorText.ChangeStoreText} `;
        hasError = true;
        break;
      case isItemUnavailable && !isUserHasChoices:
        errorText += `${EItemErrorText.ItemTUText} `;
        hasError = true;
        break;
      case isStoreUnavailable && isUserHasChoices:
        errorText += `${EItemErrorText.StoreTUText} ${EItemErrorText.ChangeStoreText} `;
        hasError = true;
        break;
      case isStoreUnavailable && !isUserHasChoices:
        errorText += `${EItemErrorText.StoreTUText} `;
        hasError = true;
        break;
      case !qrModel:
        errorText += `${EItemErrorText.QrNotFound} `;
        hasError = true;
        break;
      case !selectedStore:
        errorText = `${EItemErrorText.StoreProblem} ${EItemErrorText.ChangeStoreText} `;
        hasError = true;
        break;
    }

    return { hasError, errorText };
  }, [qrModel, isStoreUnavailable, isItemUnavailable, isUserHasChoices, selectedStore, storeOptions, customerModel]);

  const handleCloseModal = useCallback(() => {
    setIsShowErrorModal(false);
    history.go(0);
  }, []);

  if (qrModelLoading) {
    return <LoadingSpin />;
  }

  return (
    <TwoColumnsLayout
      left={
        <MobileLayout header="Go add this to your bag!">
          {qrModel && (
            <StoreCard store={selectedStore} disabled={isStoreSettedByQR || cartModelLoading} onClick={handleStoreClick} />
          )}
          {menuItem && (
            <ScannedItemCard item={menuItem} selectedStore={selectedStore} quantity={quantity} onQuantityChange={setQuantity} />
          )}
          {itemErrorText.hasError && <div className="mt-9 mb-6">{itemErrorText.errorText}</div>}
          <Button
            block
            className="btn mb-7"
            type="primary"
            disabled={
              isStoreOffline ||
              isItemUnavailable ||
              isStoreUnavailable ||
              cartModelLoading ||
              (!customerStoreInOptions && customerStore && selectedStore?.id === customerStore.id)
            }
            onClick={handleAddToBagClick}
          >
            Add to bag
          </Button>
          <Button block className="btn" onClick={goToMain}>
            Cancel
          </Button>
          <StorePickerModal options={storeOptions} onSelect={handleSelectStore} />
          <AddToBagConfirmModal onConfirm={confirmAddToBag} />
          <ModalContent
            visible={isShowErrorModal && !!modalErrorMessage}
            close={handleCloseModal}
            header="We're sorry!"
            subHeader={modalErrorMessage}
          >
            <Button size="large" block className="btn" type="primary" onClick={handleCloseModal}>
              Update
            </Button>
          </ModalContent>
        </MobileLayout>
      }
    />
  );
};
