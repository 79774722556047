import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { setUiSocialLinkModal } from 'app/store/reducers/ui.reducer';
import { ESocialLinkModals } from 'entities/UI/UI.models';
import { SocialLinkErrorsModal } from 'entities/User/components/SocialLinkErrorsModal';
import { SocialUnlinkConfirmModal } from 'entities/User/components/SocialUnlinkConfirmModal';

export const SocialLinkModal: React.FC = () => {
  const dispacth = useAppDispatch();
  const { uiSocialLinkModal } = useAppSelector(state => state.ui);
  const isVisible = uiSocialLinkModal?.isVisible;
  const modalType = uiSocialLinkModal?.type;
  const errorCode = uiSocialLinkModal?.errorCode;
  const socialType = uiSocialLinkModal?.socialType;
  const isLinkErrorsModal = modalType === ESocialLinkModals.LinkErrors;
  const isUnlinkConfirmModal = modalType === ESocialLinkModals.UnlinkConfirm;
  const isLinkErrorsModalVisible = isVisible && isLinkErrorsModal;
  const isUnlinkConfirmModalVisible = isVisible && isUnlinkConfirmModal;

  const closeUiSocialLinkModal = () => {
    dispacth(setUiSocialLinkModal({ isVisible: false }));
  };

  return (
    <>
      <SocialLinkErrorsModal isVisible={!!isLinkErrorsModalVisible} errorCode={errorCode} onClose={closeUiSocialLinkModal} />
      <SocialUnlinkConfirmModal
        isVisible={!!isUnlinkConfirmModalVisible}
        socialType={socialType}
        onClose={closeUiSocialLinkModal}
      />
    </>
  );
};
