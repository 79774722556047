import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureAxiosJWTInterseptors } from '@axmit/axios-patch-jwt';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { ConfigProvider, message } from 'antd';
import { EErrorStatus, errorMapper } from '@axmit/error-helper';
import { envConfig } from 'common/config/env.config';
import { ThirdPartyServicesHelper } from 'common/helpers/ThirdPartyServicesHelper.helpers';
import { storageHelper } from 'common/helpers/storage.helper';
import { history, store } from 'app/store';
import 'app/assets/sass/index.scss';
import { App } from 'app/App';
import { ECustomerPhoneErrorCode, ECustomerPhoneErrorMessage } from 'entities/Customer/Customer.models';
import { EBaseErrorMessage } from 'entities/Auth/Auth.models';

// Check access to localStorage
const storage = storageHelper.checkAndCreateLocalStorage();

// Axios initialization
export const axiosConfig = {
  storage,
  axios,
  refreshTokenEndpoint: '/auth',
};

// Sentry initialization
Sentry.init({
  dsn: envConfig.SENTRY_DSN || envConfig.REACT_APP_SENTRY_DSN,
  environment: envConfig.NODE_ENV,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
  tracesSampleRate: 1.0,
});

// @ts-ignore
window.checkSentry = (text?: string) => {
  const msg = `Sentry successfully connected. Config: ${JSON.stringify(envConfig)} ${new Date()}. Test string: ${text}`;
  console.log('Go to slack channel and check an error with message: ');
  console.log(msg);
  Sentry.captureException(msg);
};

ThirdPartyServicesHelper.initGoogleTag(envConfig.GOOGLE_TAG_ID || envConfig.REACT_APP_GOOGLE_TAG_ID, envConfig.NODE_ENV);
ThirdPartyServicesHelper.initFacebookPixel(envConfig.FB_PIXEL_ID || envConfig.REACT_APP_FB_PIXEL_ID, envConfig.NODE_ENV);
ThirdPartyServicesHelper.initGoogleAnalytics(
  envConfig.GOOGLE_ANALYTICS_ID || envConfig.REACT_APP_GOOGLE_ANALYTICS_ID,
  envConfig.NODE_ENV
);

configureAxiosJWTInterseptors(axiosConfig);

axios.defaults.baseURL = `/api`;
axios.interceptors.request.use((config) => ({
  ...config,
  headers: {
    'Source-Service': 'customer',
    'x-client-version': envConfig.RELEASE_VERSION || envConfig.REACT_APP_RELEASE_VERSION || '',
    ...config.headers,
  },
}));
axios.interceptors.response.use(
  (response) => response.data,
  (error) =>
    errorMapper(
      error,
      { showError: message.error, defaultError: EBaseErrorMessage.Default },
      {
        withPopupMessage: [
          EErrorStatus.BadRequest,
          EErrorStatus.Conflict,
          EErrorStatus.PayloadTooLarge,
          EErrorStatus.FailedDependency,
          EErrorStatus.TooManyRequests,
          EErrorStatus.RetryWith,
          EErrorStatus.InternalServerError,
        ],
      },
      (msg: [string, string] | string) => {
        if (Array.isArray(msg)) {
          const [code, text] = msg;

          if (code === ECustomerPhoneErrorCode.NotUnique) {
            return ECustomerPhoneErrorMessage.NotUnique;
          }
          return text;
        }

        return msg;
      }
    )
);

// Render Setup
const MOUNT_NODE = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <ConfigProvider
        theme={{
          token: {
            // Set default font for antd components
            // Link - https://ant.design/docs/react/customize-theme#customize-design-token
            fontFamily: "'Uniform Rounded', 'sans-serif'",
          },
        }}
      >
        <App />
      </ConfigProvider>
    </Router>
  </Provider>,
  MOUNT_NODE
);
