import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { message, Form, Input, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useFormMapper } from 'common/helpers/form.helper';
import { useQuery } from 'common/hooks';
import { ERoutesCommon } from 'common/models/routesModel';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { requiredValidationRule, passValidationRules } from 'common/consts/validation.consts';
import { LoadingSpin } from 'common/components';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { changePassword, checkToken } from 'app/store/reducers/auth.reducer';
import { EAuthErrorMessage } from 'entities/Auth/Auth.models';

export const PasswordRestorePage: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    authTokenCheckLoading,
    authPasswordRestore,
    authPasswordRestoreParams,
    authPasswordRestoreError,
    authTokenCheck,
    authPasswordRestoreLoading,
  } = useAppSelector((state) => state.auth);
  const token: string = useQuery().get('token') || '';
  const isTokenValid = !authTokenCheckLoading && authTokenCheck.success;
  const { fields } = useFormMapper(['password'], authPasswordRestore, authPasswordRestoreParams, authPasswordRestoreError);

  useEffect(() => {
    dispatch(checkToken(token));
  }, [token]);

  if (authTokenCheckLoading) {
    return <LoadingSpin />;
  }

  const submit = (values: any) => {
    if (!token) {
      message.error(EAuthErrorMessage.InvalidLink);
      history.push(ERoutesCommon.Root);

      return;
    }

    const password = values?.password || '';

    dispatch(changePassword(password));
  };

  const content = isTokenValid ? (
    <MobileLayout header="Create new password">
      <Form onFinish={submit} requiredMark={false} layout="vertical" fields={fields}>
        <Form.Item rules={[requiredValidationRule, ...passValidationRules]} name="password" label="New password" validateFirst>
          <Input.Password type="password" placeholder="Password" disabled={authPasswordRestoreLoading} />
        </Form.Item>

        <Button size="large" block className="btn" htmlType="submit" type="primary" disabled={authPasswordRestoreLoading}>
          Submit
        </Button>
      </Form>
    </MobileLayout>
  ) : (
    <MobileLayout
      header="The request to reset your password has expired."
      subHeader={
        <>
          Please{' '}
          <Link to={ERoutesCommon.PasswordForgot} className="link" title="Request to change your password">
            request to change your password
          </Link>{' '}
          again.
        </>
      }
    />
  );

  return <TwoColumnsLayout left={content} />;
};
