import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Row } from 'antd';
import { FieldData } from 'rc-field-form/es/interface';
import { requiredValidationRule } from 'common/consts/validation.consts';
import redExclamationMark from 'app/assets/images/redExclamationMark.svg';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { addPromoCode, deletePromoCode } from 'app/store/reducers/cart.reducer';

interface IComponentProps {
  className?: string;
}

interface IFormValues {
  code?: string;
}

export const PromoCodeForm: React.FC<IComponentProps> = props => {
  const dispatch = useAppDispatch();
  const { cartModel, cartModelLoading } = useAppSelector(state => state.cart);
  const [fields, setFields] = useState<FieldData>({ name: 'code', value: undefined });
  const { className } = props;
  const { promo, savedPromo } = cartModel || {};
  const { code } = promo || savedPromo || {};
  const criteriaMessage = savedPromo?.message;
  const isPromoApplied = !!code;
  const buttonText = isPromoApplied ? 'Remove' : 'Apply';

  useEffect(() => {
    if (!cartModelLoading) {
      setFields({ name: 'code', value: code });
    }
  }, [cartModel]);

  const handleFinish = ({ code }: IFormValues) => {
    if (!isPromoApplied) {
      if (code) {
        dispatch(addPromoCode({ code }));
      }
    } else {
      dispatch(deletePromoCode());
    }
  };

  return (
    <Form className={className || ''} onFinish={handleFinish} fields={[fields]}>
      <Row justify="space-between" wrap={false}>
        <Form.Item name="code" className="mr-7 width-full pt-2" rules={[requiredValidationRule]}>
          <Input placeholder="Add promo code" disabled={isPromoApplied} />
        </Form.Item>
        <Form.Item className="mb-0">
          <Button className="btn" htmlType="submit">
            {buttonText}
          </Button>
        </Form.Item>
      </Row>
      {criteriaMessage && (
        <div>
          <img width={20} src={redExclamationMark} alt="warning" />
          <span className="pl-4 promo-criterai__message">{criteriaMessage}</span>
        </div>
      )}
    </Form>
  );
};
