import { IError } from '@axmit/error-helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppDispatch } from 'app/store';
import { IPeakHours } from 'entities/PeakHours/PeakHours.models';

export interface IState {
  // peakHours model
  peakHoursModel: IPeakHours | null;
  peakHoursModelLoading: boolean;
  peakHoursModelError: IError | null;
}

const initialState: IState = {
  // peakHours model
  peakHoursModel: null,
  peakHoursModelLoading: false,
  peakHoursModelError: null,
};

export const peakHoursSlice = createSlice({
  name: 'peakHours',
  initialState,
  reducers: {
    // order model
    setPeakHoursModelLoading(state, action: PayloadAction<boolean>) {
      state.peakHoursModelLoading = action.payload;
    },
    setPeakHoursModel(state, action: PayloadAction<IPeakHours | null>) {
      state.peakHoursModel = action.payload;
    },
    setPeakHoursModelError(state, action: PayloadAction<IError | null>) {
      state.peakHoursModelError = action.payload;
    },
  },
});

export const { setPeakHoursModelLoading, setPeakHoursModel, setPeakHoursModelError } = peakHoursSlice.actions;
export default peakHoursSlice.reducer;

export const getPeakHours = () => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setPeakHoursModelLoading(true));

    try {
      const peakHoursModel: IPeakHours = await axios.get('stores/peak-hours');

      dispatch(setPeakHoursModel(peakHoursModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setPeakHoursModelError(_error));
    } finally {
      dispatch(setPeakHoursModelLoading(false));
    }
  }

  return thunk;
};
