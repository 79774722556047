import React, { useMemo } from 'react';
import { Button, Row, Typography } from 'antd';
import moment from 'moment';
import { ModalContent } from 'common/components';
import { CountSelector } from 'common/components/CountSelector';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { addItem } from 'app/store/reducers/cart.reducer';
import { setUiAuthModal, setUiCartItemModal } from 'app/store/reducers/ui.reducer';
import { MenuItemImage } from 'entities/Menu/components/MenuItemImage';
import { EStoreState } from 'entities/Store/Store.models';
import { formatTime } from 'entities/PeakHours/helpers/date.helper';
import { useCartItemForm } from 'entities/Cart/Cart.hooks';
import { getCartItemTotalSum } from 'entities/Cart/Cart.helpers';
import { mapMenuItemToCartAddItemParams } from 'entities/Cart/Cart.mappers';
import { ECategoryType } from 'entities/Menu/Menu.models';
import { AddonsCategoriesList } from 'entities/AddOns/components/AddonsCategoriesList';

export const MenuDetailsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { menuAddOns } = useAppSelector(state => state.menu);
  const { customerModel } = useAppSelector(state => state.customer);
  const { storeModel } = useAppSelector(state => state.store);
  const { uiCartItemModal } = useAppSelector(state => state.ui);
  const { isVisible, menuItem } = uiCartItemModal || {};

  const store = customerModel?.store || storeModel || undefined;
  const isStoreOffline = store && store.state === EStoreState.Offline;
  const hasOpenClose = store?.closeTime && store?.openTime;
  const storeOpenTime = formatTime(moment(store?.openTime || '00:00', 'HH:mm'));

  const { categoriesToAsset: addOnsCategories } = menuAddOns || {};
  const hasBEAddOns = !!addOnsCategories?.length;

  const { name, description, image, type } = menuItem || {};
  const isVisibleAddons = type === ECategoryType.IsAddOnsEnable && hasBEAddOns && !isStoreOffline;

  const initialValues = useMemo(() => (menuItem ? mapMenuItemToCartAddItemParams(menuItem) : undefined), [menuItem]);
  const { formValues, setFormValues, initValues } = useCartItemForm(initialValues);

  const { quantity, addOns, price } = formValues;

  const orderItemSum = getCartItemTotalSum(formValues);

  const closeUiCartItemModal = () => {
    dispatch(setUiCartItemModal({ isVisible: false }));
  };

  const submit = () => {
    if (!customerModel) {
      closeUiCartItemModal();
      dispatch(setUiAuthModal({ isVisible: true }));

      return;
    }

    dispatch(addItem(formValues));
    closeUiCartItemModal();
  };

  return (
    <ModalContent
      closable={true}
      afterClose={() => {
        setFormValues(initValues());
      }}
      visible={isVisible}
      close={closeUiCartItemModal}
    >
      <div className="menu-details">
        <div className="menu-details__content">
          <Row justify="space-between" className="d-flex_no-wrap">
            <div>
              {name && <div className="menu-details__header">{name}</div>}
              {description && (
                <Typography.Paragraph className="menu-details__description word-break">{description}</Typography.Paragraph>
              )}

              {!isStoreOffline && (
                <CountSelector
                  label={
                    <Row className="fs" align="middle">
                      P&nbsp;<span className="fs-xs font-weight-7">{price}</span>
                    </Row>
                  }
                  value={quantity}
                  onChange={(newCount: number) => {
                    setFormValues(prevState => ({ ...prevState, quantity: newCount }));
                  }}
                  min={1}
                />
              )}
            </div>

            {image && (
              <div className="menu-details__image-wrapper">
                <MenuItemImage src={image} className="menu-details__image" alt={name} />
              </div>
            )}
          </Row>

          {isVisibleAddons && (
            <AddonsCategoriesList
              allAddOnsCategories={addOnsCategories}
              itemAddOns={addOns}
              update={items => {
                setFormValues(prevState => ({ ...prevState, addOns: items }));
              }}
            />
          )}
        </div>
      </div>

      {isStoreOffline ? (
        <div className="t-align-c fs-xss">
          <span className="font-weight-7">Store is offline</span>
          {hasOpenClose && ` — Opens at ${storeOpenTime}`}
        </div>
      ) : (
        <Button size="large" block className="btn" type="primary" disabled={isStoreOffline} onClick={submit}>
          Add to bag
          {<span className="pl-2 font-weight-default"> + P{orderItemSum || 0}</span>}
        </Button>
      )}
    </ModalContent>
  );
};
