import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Spin } from 'antd';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { addPromoCode, deletePromoCode } from 'app/store/reducers/cart.reducer';
import { getPromoModel } from 'app/store/reducers/promo.reducer';
import { PromoCard } from 'entities/Promo/components/PromoCard';

export const PersonalPromoPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { cartModelLoading } = useAppSelector((state) => state.cart);
  const { promoModel, promoModelLoading } = useAppSelector((state) => state.promo);
  const activePromo = promoModel?.activePromo;
  const availablePromos = promoModel?.availablePromos;
  const history = useHistory();

  useEffect(() => {
    dispatch(getPromoModel());
  }, []);

  const applyPromo = (code?: string) => {
    code && dispatch(addPromoCode({ code }));
  };

  const removePromo = () => {
    dispatch(deletePromoCode());
  };

  return (
    <Spin spinning={promoModelLoading}>
      <TwoColumnsLayout
        left={
          <MobileLayout header="MB Go! Exclusive deals" back={() => history.goBack()}>
            <div className="mb-7">
              Select from the current list of MB Go! exclusive deals to enjoy freebies and discounts with your order. Only with MB
              Go!
            </div>
            {activePromo && <PromoCard promoItem={activePromo} isActive onClick={removePromo} loading={cartModelLoading} />}
            {!!availablePromos?.length &&
              availablePromos.map((item, index) => (
                <PromoCard key={index} promoItem={item} onClick={applyPromo} loading={cartModelLoading} />
              ))}
            {!activePromo && !availablePromos?.length && <Row justify="center">You have no deals available yet.</Row>}
          </MobileLayout>
        }
      />
    </Spin>
  );
};
