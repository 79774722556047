import { IBasePathParams } from 'common/models/requestModels';

export interface ICustomerModel extends Components.Schemas.CustomerDto {}
export interface ICustomerAddParams extends Components.Schemas.CustomerCreateDto {
  onSuccess?: Function;
}
export interface ICustomerUpdateParams extends Components.Schemas.CustomerUpdateDto {
  id: string;
}
export interface ICustomerSetStoreParams extends Components.Schemas.SetStoreDto {
  id: string;
}
export interface ICustomerSetPasswordPath extends Paths.CustomerControllerSetPassword.PathParameters {}
export interface ICustomerSetPasswordParams extends Components.Schemas.SetPasswordDto, ICustomerSetPasswordPath {}
export interface ICustomerUpdatePasswordPath extends Paths.CustomerControllerUpdatePassword.PathParameters {}
export interface ICustomerUpdatePasswordParams extends Components.Schemas.UpdatePasswordDto, ICustomerUpdatePasswordPath {}

export interface IConfirmationModel extends Components.Schemas.TokenPairPayloadDto {}
export interface IConfirmationParams extends Components.Schemas.EmailConfirmDto {}

export interface ICustomerCompleteRegistrationParams
  extends Components.Schemas.CustomerCompleteRegistrationDto,
    IBasePathParams {}

export enum ECustomerErrorCode {
  AlreadyRegistered = 'error.customerAlreadyConfirmedException',
  CodeSendingError = 'error.httpException',
}

export enum ECustomerErrorMessage {
  AlreadyRegistered = 'This Email Address is already in use',
}

export enum ECustomerStatus {
  Active = 'active',
  Blocked = 'blocked',
  Pending = 'pending',
}

export interface IConfirmPhoneParams extends Components.Schemas.TemporaryPhoneCreateDto {}
export interface IConfirmPhoneCodeParams extends Components.Schemas.TemporaryPhoneActivateDto {}

export enum ECustomerPhoneErrorCode {
  NotUnique = 'validation.phoneNotUnique',
}

export enum ECustomerPhoneErrorMessage {
  InvalidCode = 'Invalid code',
  NotUnique = 'This mobile number is already in use. Please use another number.',
}

export enum ECustomerPhoneSuccessMessage {
  Activated = 'Successfully activated',
}
