import React from 'react';
import { Typography, Button } from 'antd';
import { formatCurrency } from 'common/helpers/format.helper';
import plusIcon from 'app/assets/images/plus.svg';
import { IMenuItemWithCategoryNameAndType } from 'entities/Menu/Menu.models';
import { MenuItemImage } from 'entities/Menu/components/MenuItemImage';

interface IComponentProps {
  item: IMenuItemWithCategoryNameAndType;
  isDefaultMenu: boolean;
  onClick(item: IMenuItemWithCategoryNameAndType): void;
}

type AllProps = IComponentProps;

export const MenuItemCard: React.FC<AllProps> = (props) => {
  const { item, isDefaultMenu, onClick } = props;
  const { name, isEnable, price, image, description } = item;

  const isTemporarilyNotAvailable = !isDefaultMenu && !isEnable;
  const isClickable = !isTemporarilyNotAvailable;
  const isVisibleDetails = !isDefaultMenu && isEnable;

  const handleClick = () => {
    if (!isClickable) {
      return;
    }

    onClick(item);
  };

  return (
    <div
      className={`menu-card menu-card_height_fixed d-flex d-flex_column d-flex_justify-between ${
        isClickable ? 'cursor-pointer' : ''
      }`}
      onClick={handleClick}
    >
      <div className="menu-card__content">
        <div>
          {name && (
            <Typography.Paragraph ellipsis={{ rows: 3 }} className="menu-card__header mb-0">
              {name}
            </Typography.Paragraph>
          )}
          {description && (
            <Typography.Paragraph ellipsis={{ rows: 3 }} className="menu-card__description mb-0">
              {description}
            </Typography.Paragraph>
          )}
        </div>

        {image && (
          <div className="menu-card__image-wrapper">
            <MenuItemImage src={image} className="menu-card__image" alt={name} />
          </div>
        )}
      </div>

      <div className="menu-card__footer">
        {isClickable && (
          <Button className="btn-opacity btn-opacity_gray">
            <img src={plusIcon} alt="open menu" className="height-full" />
          </Button>
        )}

        {isVisibleDetails && <div className="mt-2 ml-5 font-weight-bold fs">{formatCurrency(price)}</div>}
      </div>

      {isTemporarilyNotAvailable && <div className="menu-card__footer fs">Temporarily unavailable</div>}
    </div>
  );
};
