import axios from 'axios';
import {
  IConfirmationModel,
  IConfirmationParams,
  ICustomerAddParams,
  ICustomerCompleteRegistrationParams,
  ICustomerModel,
  ICustomerSetPasswordParams,
  ICustomerSetStoreParams,
  ICustomerUpdateParams,
  ICustomerUpdatePasswordParams,
} from 'entities/Customer/Customer.models';
import { IConfirmPhoneCodeParams, IConfirmPhoneParams } from 'entities/Customer/Customer.models';

const basePath = 'customers';
const passwordPath = 'password';
const setPasswordPath = 'set-password';
const setStorePath = 'set-store';
const completeRegistrationPath = 'complete-registration';
const disconnectFacebookPath = 'auth/disconnect-facebook';
const disconnectGooglePath = 'auth/disconnect-google';

// class CustomerTransport extends BaseHttpTransport<AxiosInstance, ICustomerModel> {
//   public setPassword = ({ id, password }: ICustomerSetPasswordParams) =>
//     axios.put(`${this.path}/${id}/${setPasswordPath}`, { password });
//   public updatePassword = ({ id, password, oldPassword }: ICustomerUpdatePasswordParams) =>
//     axios.put(`${this.path}/${id}/${passwordPath}`, { password, oldPassword });
//   public setStore = ({ params: { id, store } }: IParamWithCallback<ICustomerSetStoreParams>) =>
//     axios.put(`${this.path}/${id}/${setStorePath}`, { store });
//   public completeRegistration = ({ id, ...rest }: ICustomerCompleteRegistrationParams) =>
//     axios.post(`${this.path}/${id}/${completeRegistrationPath}`, rest);
//   public disconnectFacebook = () => axios.put(`${disconnectFacebookPath}`);
//   public disconnectGoogle = () => axios.put(`${disconnectGooglePath}`);
// }

// export const customerTransport = new CustomerTransport('/customers', axios);

// export const confirmationTransport = new BaseHttpTransport('customers/email-confirm', axios);

export const customerTransport = {
  getCustomerModel: (userId: string): Promise<ICustomerModel> => axios.get(`${basePath}/${userId}`),
  addCustomerModel: (params: ICustomerAddParams): Promise<ICustomerModel> => axios.post(basePath, params),
  updateCustomerModel: ({ id, ...restData }: ICustomerUpdateParams): Promise<ICustomerModel> =>
    axios.put(`${basePath}/${id}`, restData),
  setCustomerPassword: ({ id, password }: ICustomerSetPasswordParams): Promise<ICustomerModel> =>
    axios.put(`${basePath}/${id}/${setPasswordPath}`, { password }),
  updateCustomerPassword: ({ id, password, oldPassword }: ICustomerUpdatePasswordParams): Promise<ICustomerModel> =>
    axios.put(`${basePath}/${id}/${passwordPath}`, { password, oldPassword }),
  setCustomerStore: ({ id, store }: ICustomerSetStoreParams): Promise<ICustomerModel> =>
    axios.put(`${basePath}/${id}/${setStorePath}`, { store }),
  completeCustomerRegistration: ({ id, ...rest }: ICustomerCompleteRegistrationParams): Promise<ICustomerModel> =>
    axios.post(`${basePath}/${id}/${completeRegistrationPath}`, rest),
  disconnectFacebook: (): Promise<ICustomerModel> => axios.put(`${disconnectFacebookPath}`),
  disconnectGoogle: (): Promise<ICustomerModel> => axios.put(`${disconnectGooglePath}`),
  confirmCustomerEmail: (params: IConfirmationParams): Promise<IConfirmationModel> =>
    axios.post(`${basePath}/email-confirm`, params),
  addCustomerPhone: (params: IConfirmPhoneParams) => axios.post(`${basePath}-phones`, params),
  activateCustomerPhone: (params: IConfirmPhoneCodeParams) => axios.put(`${basePath}-phones/activate`, params),
};
