import { IError } from '@axmit/error-helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/store';
import { IMenuCollection } from 'entities/Menu/Menu.models';
import { menuTransport } from 'entities/Menu/Menu.transport';

export interface IState {
  // menu
  menuCollection: IMenuCollection | null;
  menuCollectionLoading: boolean;
  menuCollectionError: IError | null;
  // addons
  menuAddOns: IMenuCollection | null;
  menuAddOnsLoading: boolean;
  menuAddOnsError: IError | null;
}

const initialState: IState = {
  // menu
  menuCollection: null,
  menuCollectionLoading: false,
  menuCollectionError: null,
  // addons
  menuAddOns: null,
  menuAddOnsLoading: false,
  menuAddOnsError: null,
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    // menu
    setMenuCollectionLoading(state, action: PayloadAction<boolean>) {
      state.menuCollectionLoading = action.payload;
    },
    setMenuCollection(state, action: PayloadAction<IMenuCollection | null>) {
      state.menuCollection = action.payload;
    },
    setMenuCollectionError(state, action: PayloadAction<IError | null>) {
      state.menuCollectionError = action.payload;
    },
    // addons
    setMenuAddOnsLoading(state, action: PayloadAction<boolean>) {
      state.menuAddOnsLoading = action.payload;
    },
    setMenuAddOns(state, action: PayloadAction<IMenuCollection | null>) {
      state.menuAddOns = action.payload;
    },
    setMenuAddOnsError(state, action: PayloadAction<IError | null>) {
      state.menuAddOnsError = action.payload;
    },
  },
});

export const {
  setMenuCollectionLoading,
  setMenuCollection,
  setMenuCollectionError,
  setMenuAddOnsLoading,
  setMenuAddOns,
  setMenuAddOnsError,
} = menuSlice.actions;
export default menuSlice.reducer;

export const getMenuCollection = (storeId: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setMenuCollectionLoading(true));

    try {
      const menuCollection = await menuTransport.getMenuCollection({ id: storeId, isAddOns: false });

      dispatch(setMenuCollection(menuCollection));
    } catch (error) {
      const _error = error as IError;

      dispatch(setMenuCollectionError(_error));
    } finally {
      dispatch(setMenuCollectionLoading(false));
    }
  }

  return thunk;
};

export const getDefaultMenu = () => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setMenuCollectionLoading(true));

    try {
      const menuCollection = await menuTransport.getDefaultMenu({ isAddOns: false });

      dispatch(setMenuCollection(menuCollection));
    } catch (error) {
      const _error = error as IError;

      dispatch(setMenuCollectionError(_error));
    } finally {
      dispatch(setMenuCollectionLoading(false));
    }
  }

  return thunk;
};

export const getMenuAddOns = (storeId: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setMenuAddOnsLoading(true));

    try {
      const menuAddOns = await menuTransport.getMenuCollection({ id: storeId, isAddOns: true });

      dispatch(setMenuAddOns(menuAddOns));
    } catch (error) {
      const _error = error as IError;

      dispatch(setMenuAddOnsError(_error));
    } finally {
      dispatch(setMenuAddOnsLoading(false));
    }
  }

  return thunk;
};
