import React from 'react';
import { Typography } from 'antd';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';

const { Title, Paragraph, Link } = Typography;

export const TosPage: React.FC = () => {
  return (
    <MobileLayout header="Term of service">
      <Paragraph className="fs">
        These Terms and Conditions shall apply to all orders of Minute Burger products (“Products”) of a customer through this{' '}
        <strong>MB Go!</strong> Website (“Site”). By placing an order for Products through this Site, you understand, agree with,
        and accept: (i) these Terms and Conditions and any corresponding changes thereto which we may implement from time to time;
        and (ii) our Privacy Policy in connection with how we process your personal information collected from this Site.
      </Paragraph>
      <Paragraph className="fs">
        For purposes of these Terms and Conditions, “Minute Burger”, “our” or “we” shall refer to Leslie Corporation and its
        subsidiaries, while “customer” or “you” shall refer to the customer who intends to purchase the Products from this Site.
      </Paragraph>
      <Paragraph className="fs">
        All materials/content found in our Online Services may not be copied, distributed, republished, uploaded, posted, or
        transmitted in any way, without our prior written consent EXCEPT you may download one copy materials and content on one
        computer for your personal, non-commercial, home use only. You may not delete or change any copyright, trademark, or other
        proprietary notices which may be incorporated in these Terms and Minute Burger Privacy Policy. Modification or use of the
        said materials and content as described in the Terms and Minute Burger Privacy Policy for any other purpose violates
        Minute Burger intellectual property rights.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Account Creation
      </Title>
      <Paragraph className="fs">
        If you decide to place an order through this Site, you will be asked to register by creating an account which requires
        your personal information such as name, complete delivery address, telephone or mobile number, email address and a
        password. Creating an account allows you to automatically fill-up all the necessary delivery details in your next order.
        Inputting and confirming a password on the “Create an Account” or “Guest Information” page of the website, along with
        other information, allows you to make an account. You must keep this password confidential and must not disclose it to
        anyone. If you believe someone else knows your password, please change your password immediately.
      </Paragraph>
      <Paragraph className="fs">
        We reserve the right to suspend your registration if you violate any of these Terms and Conditions.
      </Paragraph>
      <Paragraph className="fs">
        When you choose to create an account by linking it with an external third party service or application, such as Facebook,
        this feature will not modify nor allow the access of the content of your Facebook account. However, if you use your
        Facebook account to create an account in this Site, the following shall apply:
      </Paragraph>
      <Paragraph className="fs">
        <ul>
          <li>Your Facebook account name shall be your Account name in the website;</li>
          <li>The e-mail address of your Facebook account shall be used as the e-mail address for your Account.</li>
        </ul>
      </Paragraph>
      <Paragraph className="fs">
        If another person used your account to order Products on your behalf, with or without your knowledge, such order shall be
        subject to these Terms and Conditions, and you agree that the order shall be considered your own and will be charged to
        you.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Finding Stores Nearest Your Location
      </Title>
      <Paragraph className="fs">
        If you decide to use our Nearest Me feature, you will be asked by your browser to allow MB Go to use your current location
        to find stores. This information will only be used to find stores nearest your current location and will not be stored. If
        you would like to find stores nearest you on another time or date, you must select “Nearest Me” again. If your active
        location is currently moving such as in a moving vehicle or if you are walking, the Nearest Me feature may not be as
        accurate.
      </Paragraph>
      <Paragraph className="fs">
        The location feature is an approximate guide and uses your device’s GPS capabilities and connection to provide an
        approximate location. You must still refer to the address and map (provided at the checkout page) to confirm that the
        store you selected is indeed nearest your current location.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Submission of Order
      </Title>
      <Paragraph className="fs">
        Upon submission of your online order, you are authorizing us to process your online order and preparation of the Products
        you have specified on your order form. However, we reserve the right to refuse the processing of your order without any
        notification should we find any incorrect or incomplete details, or any previous spurious transactions with the given
        personal information.
      </Paragraph>
      <Paragraph className="fs">
        Also, non-response to SMS and calls from the store may result in late or non-delivery of the Products and should not be
        taken against us. Additional verification call or confirmation call may be required to proceed with your order.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Regular Order
      </Title>
      <Paragraph className="fs">
        By submitting your order through this Site, you understand and agree that the processing of your order is subject to the
        following conditions:
      </Paragraph>
      <Paragraph className="fs">
        <ol>
          <li>
            There is a twenty (20) to thirty (30) minute preparation time for single receipt transactions amounting to{' '}
            <strong>P600 and below</strong>. The exact time of pickup shall be indicated at the order confirmation page which the
            customer will agree to once the order is placed. However, this preparation guarantee does not apply if there are more
            than 10 orders per transaction of the same product combinations. If we are unable to have your products prepared for
            pickup by the time that the order is due, you will be allowed to cancel your order due to waiting too long. You
            understand and accept, however, that any delay due to heavy traffic, inclement weather, unavoidable technical
            difficulties in the stores, or similar or conditions or circumstances, are beyond our control and should not at any
            time be taken against us. Pickup services may also not be available in cases of extreme weather, natural disasters,
            and unavoidable technical difficulties in the store/s in your area.
          </li>
          <li>
            For Orders amounting to <strong>P600.00</strong> and up you will receive a call from our store to verify your identity
            and your order details. Due to the volume of the Products ordered, we do not guarantee that orders can be prepared
            within the standard preparation time and the store will coordinate with you on the serving time.
          </li>
          <li>
            Orders above <strong>P1500</strong>, are considered bulk orders and will not be accepted by the Site. Instead,the Site
            will prompt you to contact the store directly to place your order.
          </li>
          <li>Excessive requests of items such as packaging, paper napkins, and the like are subject to store discretion.</li>
          <li>
            Any other special request outside the list of the Minute Burger product menu is strictly prohibited. Additional
            special request such as medicine, newspaper, cigarettes, grocery items and the likes will not be entertained.
          </li>
        </ol>
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Advance Order
      </Title>
      <Paragraph className="fs">
        If you wish to order in advance through this website, you may place your order within the calendar day.
      </Paragraph>

      <Title level={2} className="fs-xxs">
        Advance Order
      </Title>
      <Paragraph className="fs">
        The acceptance of orders for the Products made through the Site shall be at the entire discretion of Minute Burger. We
        reserve the right to decline to accept the order or deliver the Products without giving any reason.
      </Paragraph>
      <Paragraph className="fs">
        Our acceptance of an order occurs when the order status has been updated to show that the store is now preparing your
        order (“Order Confirmation”). The Order Confirmation is a record of our agreement to deliver the Products you ordered and
        your obligation to pay the price of the Products in full. The Order Summary serves as your Order Confirmation and will
        contain the following information:
      </Paragraph>
      <Paragraph className="fs">
        <ol>
          <li>Your pertinent order number;</li>
          <li>The date and time of your order;</li>
          <li>The total amount payable;</li>
          <li>The address of the store and name of the store where the order will be picked up;</li>
          <li>The Minute Burger store that has received and will process your order; and</li>
          <li>The contact number of the Minute Burger store that has received your order.</li>
        </ol>
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Changes of Order
      </Title>
      <Paragraph className="fs">
        Changes in your order can be made before a store accepts your order by cancelling it and re-ordering.
      </Paragraph>
      <Paragraph className="fs">
        After the store has accepted your order, you may agree on change with the store by calling the Minute Burger store that
        received the order or by contacting the store through the contact details indicated in the order summary page.
      </Paragraph>
      <Paragraph className="fs">
        If you wish to change your order prior to pickup time, please call (through the contact number provided in the Order
        Confirmation) the Minute Burger store within the timeframe stated above, and provide the details of your order included in
        the Order Confirmation.
      </Paragraph>
      <Paragraph className="fs">
        Changes in your order that are confirmed by the pertinent Minute Burger store will reset the thirty (30) minute
        preparation time, and will likewise be subject to these Terms and Conditions. You understand that the total amount of your
        order will be changed should there be any changes in your order, and that you agree to pay the new total amount upon
        delivery. Thus:
      </Paragraph>
      <Paragraph className="fs">
        <ul>
          <li>
            If you paid for your order online via credit card, any additional price difference as a result of changing your order
            must be paid in cash, payable upon delivery.
          </li>
          <li>
            If, as a result of any change/s you make to your order, the total amount payable is less than the amount paid online
            via credit card, any amount paid in excess of the new total amount payable shall be refunded to you by your own bank.
            You understand and agree that in such a case, we will not process the refund on your behalf, and that you are
            personally responsible for processing the refund with your own issuing bank.,
          </li>
          <li>
            If you choose to pay by credit card upon delivery (card present), the total amount shall be chargeable and payable
            upon delivery.
          </li>
        </ul>
      </Paragraph>
      <Paragraph className="fs">
        If any of the changes to order include promotions which are only available for limited periods, any changes to your order
        may mean that the promotions are no longer available by the time the changes are made.
      </Paragraph>
      <Paragraph className="fs">
        We reserve the right to refuse any modification in case the order has already begun preparation and cooking prior to
        receipt of your request.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Cancellation of Orders
      </Title>
      <Paragraph className="fs">
        Orders may be cancelled before a store accepts your order. After, cancellations are to be done by calling or messaging the
        Minute Burger store that received your order. Cancellation immediately after the time that the Order Confirmation was
        received will no longer be valid. You understand and agree that cancellation beyond this time will require you to pay the
        full amount of the order placed by you.
      </Paragraph>
      <Paragraph className="fs">
        We reserve the right to refuse any cancellation in case the order has already been prepared prior to receipt of your
        request.
      </Paragraph>
      <Paragraph className="fs">
        We reserve the right to cancel an order of a customer in case the store has tried to call you over local phone or sent you
        messages via local SMS and the store is unable to reach you after 3 tries within 5 minutes.
      </Paragraph>
      <Paragraph className="fs">
        If payment is made via credit card and the cancellation of order was successful, it will be your responsibility to contact
        the issuing bank to cancel the payment made.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Prices and Payment
      </Title>
      <Paragraph className="fs">
        Unless otherwise stated in writing, the Product prices quoted on this Site are inclusive of value-added taxes, any other
        applicable taxes and delivery charge. The Product prices on this Site are subject to change without prior notice.
      </Paragraph>
      <Paragraph className="fs">
        To check the price/s of the Product you want to order, please click the Checkout button to see the breakdown and total
        amount due.
      </Paragraph>
      <Paragraph className="fs">You can pay by any of the following methods, when available:</Paragraph>
      <Paragraph className="fs">
        <ul>
          <li>Cash upon delivery</li>
          <li>
            Cashless mode of payment on-site (credit card and/or debit card), subject to the condition that onsite credit card
            payment may not be available 24 hours.
          </li>
          <li>
            Cashless payment on-site will involve a redirection to a third-party payment gateway. You acknowledge that Minute
            Burger will not be responsible for any information provided in the payment gateway.
          </li>
          <li>Cashless mode of payment upon pickup. Cashless payment upon pickup is only available in select stores.</li>
        </ul>
      </Paragraph>
      <Paragraph className="fs">
        If your order is placed using a credit card, such card should be valid on the date of pickup of your order.
      </Paragraph>
      <Paragraph className="fs">
        We reserve our right to refuse to prepare any order if for whatever reason we believe that payment will be refused at any
        stage.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Refund
      </Title>
      <Paragraph className="fs">
        In any case, refunds for cash payments and cashless payments made at the store at the time of pickup should be discussed
        and processed with the store that received, accepted, and served your order.
      </Paragraph>
      <Paragraph className="fs">
        Refunds for orders paid online via credit card or upon delivery via credit card must be processed by you through your
        issuing bank.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Order Ahead Conditions
      </Title>
      <Paragraph className="fs">
        You agree to accept delivery of the Products at the agreed time and place of pickup, subject to the specific delivery
        conditions below:
      </Paragraph>
      <Title level={3} className="fs">
        Pickup Time
      </Title>
      <Paragraph className="fs">
        The arrival time in our pickup service guarantee will be based on the readiness of the product upon customer’s arrival at
        the front counter of the store. We shall exert reasonable efforts to meet the guaranteed preparation time, where
        applicable. You understand and accept, however, that any delay due to calamity, inclement weather or similar or analogous
        conditions or circumstance, are beyond our control and should not at any time be taken against us.
      </Paragraph>
      <Title level={3} className="fs">
        Product Availability
      </Title>
      <Paragraph className="fs">
        You understand and agree that Product/s are subject to availability at the time of ordering. If the Products you wish to
        order are not available, it will be reflected in the website that it is unavailable. If at the time of submitting your
        order the products have become unavailable, the store receiving your order may offer you a reasonable substitute Product
        or equivalent quality and price or we may advise you through phone call (local Philippines) or SMS for alternative
        Product/s. You understand that should you choose to order other Product/s, this may affect the purchase price.
      </Paragraph>
      <Paragraph className="fs">
        You also understand that if the store is unable to reach you by Phone Call or SMS to confirm your order at least 3 times
        within 5 minutes, the store reserves the right to assume the order will no longer push through and will cancel your order.
      </Paragraph>
      <Title level={3} className="fs">
        ID Validation
      </Title>
      <Paragraph className="fs">
        Upon pickup of your order, you will be requested to present a valid ID to our store crew for validation as well as your
        unique order number.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Promotions (Special Offers)
      </Title>
      <Paragraph className="fs">
        From time to time, products may be subject to special offers. Any special offer shall be subject to these Terms and
        Conditions. In case of inconsistency between the special offer and these Terms and Conditions, the latter shall prevail.
        We reserve the right to change the conditions and/or availability of special offers or withdraw them at our discretion.
        However, we will honor any order received before the withdrawal or changes on the special offers. All special offers are
        valid only within the promotional period, are subject to change and are not valid in conjunction with other offers, unless
        otherwise stated.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Limitation of Liability
      </Title>
      <Paragraph className="fs">
        We shall not be liable for any loss, expense, cost or damage arising out arising directly or indirectly out of or in
        connection with the delay beyond the estimated time or date of pickup, availability of product/s at any given time, force
        majeure, and any circumstances beyond our control, and over which we could not have avoided even with the exercise of
        reasonable care, or any indirect or unforeseeable loss suffered or incurred by customers or third parties. In any event,
        our liability for any given transaction shall not exceed the total price charged for the relevant Products.
      </Paragraph>
      <Paragraph className="fs">
        The Products ordered should be consumed within one (1) hour from their actual receipt. We will not be liable for any event
        that may arise as a result/consequence of consuming the Products beyond the specified time.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Privacy Policy
      </Title>
      <Paragraph className="fs">
        Our Privacy Policy in connection with how we process your personal information collected from this Site, and any changes
        thereto, which we may implement from time to time, may be found in the Privacy Policy. You agree that with your acceptance
        of these Terms and Conditions, you have read, understood and accepted our Privacy Policy.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Updating these Terms and Conditions
      </Title>
      <Paragraph className="fs">
        Whenever necessary, we may, from time to time, update and/or revise these Terms and Conditions. We shall inform you of any
        update or revision by placing the date of the latest version on top of these Terms and Conditions.
      </Paragraph>
      <Title level={2} className="fs-xxs">
        Contact Us
      </Title>
      <Paragraph className="fs">
        If you have any questions regarding this Terms and Conditions, please contact us through:
      </Paragraph>
      <Paragraph className="fs">
        <Link href="https://mbcustomerservice.zendesk.com/hc/en-us/requests/new">MB Go! Customer Care</Link>{' '}
      </Paragraph>
    </MobileLayout>
  );
};
