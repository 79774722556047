import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clearCreds, getCreds, saveCreds } from '@axmit/axios-patch-jwt';
import { IError } from '@axmit/error-helper';
import { message } from 'antd';
import { ERoutesCommon, ERoutesPublic } from 'common/models/routesModel';
import { EErrorStatus } from 'common/models/requestModels';
import { AppDispatch, history } from 'app/store';
import { getCartModel, setCartModel } from 'app/store/reducers/cart.reducer';
import {
  confirmCustomerEmail,
  getCustomerModel,
  setCustomerModel,
  setCustomerStoreFromLS,
} from 'app/store/reducers/customer.reducer';
import { clearStoreModel } from 'app/store/reducers/store.reducer';
import { setUiAuthModal } from 'app/store/reducers/ui.reducer';
import { authTransport } from 'entities/Auth/Auth.transport';
import {
  EAuthErrorCode,
  EAuthErrorMessage,
  EAuthSuccessMessage,
  IAuthModel,
  IAuthParams,
  IPasswordForgotParams,
  IPasswordRestoreParams,
} from 'entities/Auth/Auth.models';

export interface IState {
  // auth model
  authModelLoading: boolean;
  authModel: IAuthModel | null;
  authModelParams: IAuthParams | null;
  authModelError: IError | null;
  // auth password restore
  authPasswordRestoreLoading: boolean;
  authPasswordRestore: any;
  authPasswordRestoreParams: IPasswordForgotParams | IPasswordRestoreParams | null;
  authPasswordRestoreError: IError | null;
  // auth token check
  authTokenCheckLoading: boolean;
  authTokenCheck: any;
  authTokenCheckError: IError | null;
  // social registration
  isSocialNetworkRegistered: boolean;
}

const initialState: IState = {
  // auth model
  authModelLoading: true,
  authModel: null,
  authModelParams: null,
  authModelError: null,
  // auth password restore
  authPasswordRestoreLoading: false,
  authPasswordRestore: null,
  authPasswordRestoreParams: null,
  authPasswordRestoreError: null,
  // auth token check
  authTokenCheckLoading: false,
  authTokenCheck: null,
  authTokenCheckError: null,
  // social registration
  isSocialNetworkRegistered: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // auth model
    setAuthModelLoading(state, action: PayloadAction<boolean>) {
      state.authModelLoading = action.payload;
    },
    setAuthModel(state, action: PayloadAction<IAuthModel | null>) {
      state.authModel = action.payload;
    },
    setAuthModelParams(state, action: PayloadAction<IAuthParams | null>) {
      state.authModelParams = action.payload;
    },
    setAuthModelError(state, action: PayloadAction<IError | null>) {
      state.authModelError = action.payload;
    },
    // auth password restore
    setAuthPasswordRestoreLoading(state, action: PayloadAction<boolean>) {
      state.authPasswordRestoreLoading = action.payload;
    },
    setAuthPasswordRestore(state, action: PayloadAction<any>) {
      state.authPasswordRestore = action.payload;
    },
    setAuthPasswordRestoreParams(state, action: PayloadAction<IPasswordForgotParams | IPasswordRestoreParams | null>) {
      state.authPasswordRestoreParams = action.payload;
    },
    setAuthPasswordRestoreError(state, action: PayloadAction<IError | null>) {
      state.authPasswordRestoreError = action.payload;
    },
    // auth token check
    setAuthTokenCheckLoading(state, action: PayloadAction<boolean>) {
      state.authTokenCheckLoading = action.payload;
    },
    setAuthTokenCheck(state, action: PayloadAction<any>) {
      state.authTokenCheck = action.payload;
    },
    setAuthTokenCheckError(state, action: PayloadAction<IError | null>) {
      state.authTokenCheckError = action.payload;
    },
    // social registration
    setIsSocialNetworkRegistered(state, action: PayloadAction<boolean>) {
      state.isSocialNetworkRegistered = action.payload;
    },
  },
});

export const {
  setAuthModel,
  setAuthModelParams,
  setAuthModelLoading,
  setAuthModelError,
  setAuthTokenCheckLoading,
  setAuthTokenCheckError,
  setAuthPasswordRestoreLoading,
  setAuthPasswordRestoreParams,
  setAuthPasswordRestoreError,
  setIsSocialNetworkRegistered,
} = authSlice.actions;
export default authSlice.reducer;

const onSuccessAuth = (userId: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(getCustomerModel(userId));
    dispatch(getCartModel());
    dispatch(setCustomerStoreFromLS(userId));
  }

  return thunk;
};

export const clearAuth = () => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAuthModel(null));
    dispatch(clearStoreModel());
    dispatch(setCustomerModel(null));
    dispatch(setCartModel(null));
    clearCreds();
  }

  return thunk;
};

export const initAuthModel = () => {
  async function thunk(dispatch: AppDispatch) {
    try {
      const authResponse = await getCreds();
      const userId = authResponse?.access?.userId;

      if (userId) {
        dispatch(setAuthModel(authResponse));
        dispatch(onSuccessAuth(userId));
      }
    } catch (error) {
      history.push(ERoutesCommon.Root);
    } finally {
      dispatch(setAuthModelLoading(false));
    }
  }

  return thunk;
};

export const login = (params: IAuthParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAuthModelParams(params));
    dispatch(setAuthModelLoading(true));

    try {
      const authResponse = await authTransport.login(params);

      await saveCreds(authResponse);
      dispatch(setAuthModel(authResponse));
      dispatch(onSuccessAuth(authResponse.access.userId));
      dispatch(setUiAuthModal({ isVisible: false }));
      dispatch(setAuthModelParams(null));

      history.push(ERoutesCommon.Root);
    } catch (error) {
      const _error = error as IError;

      dispatch(setAuthModelError(_error));

      if (_error?.data?.code === EAuthErrorCode.InvalidCredentials) {
        message.error(EAuthErrorMessage.InvalidCredentials);
      }
      if (_error?.data?.code === EAuthErrorCode.UserBlocked) {
        message.error(EAuthErrorMessage.UserBlocked);
      }
      if (_error?.data?.code === EAuthErrorCode.UserNotConfirmed) {
        message.error(EAuthErrorMessage.UserNotConfirmed);
      }
    } finally {
      dispatch(setAuthModelLoading(false));
    }
  }

  return thunk;
};

export const logout = () => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAuthModelLoading(true));

    try {
      await authTransport.logout();

      dispatch(clearAuth());

      history.push(ERoutesCommon.Root);
    } catch (error) {
      const _error = error as IError;

      dispatch(setAuthModelError(_error));

      history.push(ERoutesCommon.Root);
    } finally {
      dispatch(setAuthModelLoading(false));
    }
  }

  return thunk;
};

export const refreshToken = (refreshToken: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAuthModelLoading(true));

    try {
      const authResponse = await authTransport.refresh({ refreshToken });

      await saveCreds(authResponse);
      dispatch(setAuthModel(authResponse));
      dispatch(onSuccessAuth(authResponse.access.userId));

      history.push(ERoutesCommon.Root);
    } catch (error) {
      const _error = error as IError;

      dispatch(setAuthModelError(_error));

      history.push(ERoutesCommon.Root);
    } finally {
      dispatch(setAuthModelLoading(false));
    }
  }

  return thunk;
};

export const restorePassword = (params: IPasswordForgotParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAuthPasswordRestoreLoading(true));
    dispatch(setAuthPasswordRestoreParams(params));

    try {
      await authTransport.passwordRestore(params);

      const popupMessage = params.email
        ? `${EAuthSuccessMessage.CheckEmailAfterRequestWithEmail}${params.email}`
        : EAuthSuccessMessage.CheckEmailAfterRequest;

      message.success(popupMessage);
      dispatch(setAuthPasswordRestoreParams(null));
    } catch (error) {
      const _error = error as IError;

      dispatch(setAuthPasswordRestoreError(_error));
    } finally {
      dispatch(setAuthPasswordRestoreLoading(false));
    }
  }

  return thunk;
};

export const changePassword = (params: IPasswordRestoreParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAuthPasswordRestoreLoading(true));
    dispatch(setAuthPasswordRestoreParams(params));

    try {
      await authTransport.passwordRestoreNew(params);

      message.success(EAuthSuccessMessage.PasswordChanged);
      dispatch(setAuthPasswordRestoreParams(null));
      history.push(ERoutesPublic.Login);
    } catch (error) {
      const _error = error as IError;

      dispatch(setAuthPasswordRestoreError(_error));

      const linkInvalid = _error.status === EErrorStatus.Validation && _error?.validation?.token;

      if (_error.status === EErrorStatus.NotFound || linkInvalid) {
        message.error(EAuthErrorMessage.LinkExpired);
      }
    } finally {
      dispatch(setAuthPasswordRestoreLoading(false));
    }
  }

  return thunk;
};

export const updateIsSocialNetworkRegistered = (isRegistered: boolean) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setIsSocialNetworkRegistered(isRegistered));
  }

  return thunk;
};

export const checkToken = (token: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setAuthTokenCheckLoading(true));

    try {
      await authTransport.checkPasswordRestoreToken({ token });

      dispatch(confirmCustomerEmail(token));
    } catch (error) {
      const _error = error as IError;

      dispatch(setAuthTokenCheckError(_error));
    } finally {
      dispatch(setAuthTokenCheckLoading(false));
    }
  }

  return thunk;
};
