import axios from 'axios';
import { objectToQuery } from 'common/helpers/filters.helper';
import {
  IOrderHistoryAddReorderParams,
  IOrderHistoryCollection,
  IOrderHistoryCollectionFilter,
  IOrderHistoryModel,
  IOrderHistoryReorderModel,
} from 'entities/OrderHistory/OrderHistory.models';

const basePath = 'order-histories';

// class OrderHistoryTransport extends BaseHttpTransport<AxiosInstance, IOrderHistoryModel> {
//   public reorder = ({ id, storeId }: IOrderHistoryAddReorderParams) => axios.post(`${this.path}/${id}/reorder/${storeId}`);
// }

// export const orderHistoryTransport = new OrderHistoryTransport(basePath, axios);

export const orderHistoryTransport = {
  getOrderHistoryModel: (id: string): Promise<IOrderHistoryModel> => axios.get(`${basePath}/${id}`),
  getOrderHistoryCollection: (params: IOrderHistoryCollectionFilter): Promise<IOrderHistoryCollection> =>
    axios.get(`${basePath}/${objectToQuery(params)}`),
  reorder: ({ id, storeId }: IOrderHistoryAddReorderParams): Promise<IOrderHistoryReorderModel> =>
    axios.post(`${basePath}/${id}/reorder/${storeId}`),
};
