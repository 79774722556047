import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { IError } from '@axmit/error-helper';
import { ERoutes } from 'common/models/routesModel';
import { AppDispatch, history } from 'app/store';
import { EUserSuccessMessage, IUpdatePasswordParams, IUserModel, IUserUpdateModel } from 'entities/User/User.models';
import { EAuthSuccessMessage, IAuthParams } from 'entities/Auth/Auth.models';
import { userTransport } from 'entities/User/User.transport';

export interface IState {
  userModelLoading: boolean;
  userModel: IUserModel | null;
  userModelError: IError | null;
}

const initialState: IState = {
  userModelLoading: false,
  userModel: null,
  userModelError: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserModelLoading(state, action: PayloadAction<boolean>) {
      state.userModelLoading = action.payload;
    },
    setUserModel(state, action: PayloadAction<IUserModel | null>) {
      state.userModel = action.payload;
    },
    setUserModelError(state, action: PayloadAction<IError | null>) {
      state.userModelError = action.payload;
    },
  },
});

export const { setUserModelLoading, setUserModel, setUserModelError } = userSlice.actions;
export default userSlice.reducer;

export const getUserModel = (userId: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setUserModelLoading(true));

    try {
      const userModel = await userTransport.getUserModel(userId);

      dispatch(setUserModel(userModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setUserModelError(_error));
    } finally {
      dispatch(setUserModelLoading(false));
    }
  }

  return thunk;
};

export const updateUserModel = (userId: string, userData: IUserUpdateModel) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setUserModelLoading(true));

    try {
      const userModel = await userTransport.updateUserModel(userId, userData);

      dispatch(setUserModel(userModel));

      message.success(EAuthSuccessMessage.SuccessfullyEdited);
      history.push(`${ERoutes.Users}/${userModel.id}`);
    } catch (error) {
      const _error = error as IError;

      dispatch(setUserModelError(_error));
    } finally {
      dispatch(setUserModelLoading(false));
    }
  }

  return thunk;
};

export const updatePassword = (userData: IUpdatePasswordParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setUserModelLoading(true));

    try {
      await userTransport.changeUserPassword(userData);

      message.success(EUserSuccessMessage.PasswordChanged);

      if (userData.onSuccess) {
        userData.onSuccess();
      }
    } catch (error) {
      const _error = error as IError;

      dispatch(setUserModelError(_error));
    } finally {
      dispatch(setUserModelLoading(false));
    }
  }

  return thunk;
};

export const addUserRegistration = (params: IAuthParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setUserModelLoading(true));

    try {
      await userTransport.addUserRegistration(params);

      message.success(EAuthSuccessMessage.CheckEmailForConfirmedLink);
    } catch (error) {
      const _error = error as IError;

      dispatch(setUserModelError(_error));
    } finally {
      dispatch(setUserModelLoading(false));
    }
  }

  return thunk;
};
