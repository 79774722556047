import React, { useMemo } from 'react';
import { Row, Button, Typography } from 'antd';
import { ModalContent } from 'common/components';
import { CountSelector } from 'common/components/CountSelector';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { deleteItem, updateItem } from 'app/store/reducers/cart.reducer';
import { setUiCartItemModal } from 'app/store/reducers/ui.reducer';
import { MenuItemImage } from 'entities/Menu/components/MenuItemImage';
import { useCartItemForm } from 'entities/Cart/Cart.hooks';
import { isCartAddItemParamsEquals, getCartItemTotalSum } from 'entities/Cart/Cart.helpers';
import { mapCartItemToCartAddItemParams } from 'entities/Cart/Cart.mappers';
import { ECategoryType } from 'entities/Menu/Menu.models';
import { AddonsCategoriesList } from 'entities/AddOns/components/AddonsCategoriesList';

export const OrderDetailsModal: React.FC = () => {
  const dispacth = useAppDispatch();
  const { menuAddOns } = useAppSelector(state => state.menu);
  const { uiCartItemModal } = useAppSelector(state => state.ui);
  const { isVisible, cartItem } = uiCartItemModal || {};

  const { id, name, price, description, imageId: image, categoryType: type, isEnable, discountPrice } = cartItem || {};

  const initialCartItem = useMemo(() => (cartItem ? mapCartItemToCartAddItemParams(cartItem) : undefined), [cartItem]);
  const { formValues, setFormValues } = useCartItemForm(initialCartItem);
  const { formValues: initialValues } = useCartItemForm(initialCartItem);
  const { quantity, addOns, maxLimit, isPromo } = formValues;

  const { categoriesToAsset: addOnsCategories } = menuAddOns || {};
  const hasBEAddOns = !!addOnsCategories?.length;

  const isVisibleAddons = type === ECategoryType.IsAddOnsEnable && hasBEAddOns;
  const needRemove = !isEnable && quantity > 0;
  const minCount = needRemove ? quantity : 1;
  const maxCount = needRemove ? quantity : isPromo && maxLimit ? maxLimit : undefined;

  const itemTotalPrice = getCartItemTotalSum(formValues, discountPrice);
  const currentItemTotalPrice = getCartItemTotalSum(initialValues, discountPrice);
  const updatedPriceDiff = itemTotalPrice - currentItemTotalPrice;
  const isPriceChanged = updatedPriceDiff !== 0;
  const isCurrentItemChanged = !isCartAddItemParamsEquals(initialValues, formValues);

  const closeUiCartItemModal = () => {
    dispacth(setUiCartItemModal({ isVisible: false }));
  };

  const remove = () => {
    if (id) {
      dispacth(deleteItem(id));
    }

    closeUiCartItemModal();
  };

  const update = () => {
    if (id) {
      dispacth(updateItem({ id, ...formValues }));
    }

    closeUiCartItemModal();
  };

  return (
    <ModalContent
      closable={true}
      visible={isVisible}
      afterClose={() => {
        setFormValues(formValues);
      }}
      close={closeUiCartItemModal}
    >
      <div className="menu-details">
        <div className="menu-details__content">
          <Row justify="space-between" className="d-flex_no-wrap">
            <div>
              {name && (
                <div className={`menu-details__header ${!isEnable ? 'color-gray' : ''}`}>
                  {name}
                  {!isEnable && <span className="font-weight-4"> (Unavailable)</span>}
                </div>
              )}
              {description && (
                <Typography.Paragraph className="menu-details__description word-break">{description}</Typography.Paragraph>
              )}
            </div>

            {image && (
              <div className="menu-details__image-wrapper">
                <MenuItemImage src={image} alt={name} className="menu-details__image" />
              </div>
            )}
          </Row>

          <CountSelector
            value={quantity}
            label={
              <Row className="fs-xs font-weight-7" align="middle">
                {isPromo && discountPrice ? (
                  <>
                    <span className="t-decoration-line-through">P {price}</span>
                    <span className="ml-4 color-orange-main">P {discountPrice}</span>
                  </>
                ) : (
                  <span>P {price}</span>
                )}
              </Row>
            }
            onChange={(newCount: number) => {
              setFormValues(prevState => ({ ...prevState, quantity: newCount }));
            }}
            max={maxCount}
            min={minCount}
          />

          {isVisibleAddons && (
            <AddonsCategoriesList
              itemAddOns={addOns}
              allAddOnsCategories={addOnsCategories}
              needRemoveItem={needRemove}
              update={items => {
                setFormValues(prevState => ({ ...prevState, addOns: items }));
              }}
            />
          )}
        </div>
      </div>

      <Button size="large" block className="btn mb-7" type="primary" disabled={!isCurrentItemChanged} onClick={update}>
        Update your bag
        {isPriceChanged && (
          <span className="font-weight-4 ml-2">
            ({updatedPriceDiff > 0 ? '+' : '-'}P<b>{Math.abs(updatedPriceDiff)}</b>)
          </span>
        )}
      </Button>

      <Button size="large" block className="btn" onClick={remove}>
        Remove all
        <span className="font-weight-4 ml-2">
          (-P<b>{currentItemTotalPrice}</b>)
        </span>
      </Button>
    </ModalContent>
  );
};
