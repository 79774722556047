import { IError } from '@axmit/error-helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import { ERoutesCommon } from 'common/models/routesModel';
import { AppDispatch, RootState, history } from 'app/store';
import { setCustomerModel } from 'app/store/reducers/customer.reducer';
import { ERatingsErrorCode, ERatingsErrorMessage, IAddRatingParams, IRatingModel } from 'entities/Ratings/Ratings.models';
import { EAuthErrorCode, EAuthErrorMessage } from 'entities/Auth/Auth.models';

export interface IState {
  // rating model
  ratingModel: IRatingModel | null;
  ratingModelLoading: boolean;
  ratingModelError: IError | null;
}

const initialState: IState = {
  // rating model
  ratingModel: null,
  ratingModelLoading: false,
  ratingModelError: null,
};

export const ratingSlice = createSlice({
  name: 'rating',
  initialState,
  reducers: {
    // rating model
    setRatingModelLoading(state, action: PayloadAction<boolean>) {
      state.ratingModelLoading = action.payload;
    },
    setRatingModel(state, action: PayloadAction<IRatingModel | null>) {
      state.ratingModel = action.payload;
    },
    setRatingModelError(state, action: PayloadAction<IError | null>) {
      state.ratingModelError = action.payload;
    },
  },
});

export const { setRatingModelLoading, setRatingModel, setRatingModelError } = ratingSlice.actions;
export default ratingSlice.reducer;

export const addRatingsModel = (params: IAddRatingParams) => {
  async function thunk(dispatch: AppDispatch, getState: () => RootState) {
    dispatch(setRatingModelLoading(true));

    try {
      const ratingModel: IRatingModel = await axios.post('ratings', params);

      dispatch(setRatingModel(ratingModel));

      const reduxStore = getState();
      const { store: storeFromRating } = ratingModel || {};
      const { customerModel } = reduxStore.customer;

      if (customerModel && customerModel.store && storeFromRating && customerModel.store.id === storeFromRating.id) {
        dispatch(setCustomerModel({ ...customerModel, store: storeFromRating }));
      }

      history.push(ERoutesCommon.Main);
      message.success('Thank you for your feedback! Your rating helps us improve the experience on MB Go for our customers.');
    } catch (error) {
      const _error = error as IError;

      dispatch(setRatingModelError(_error));

      if (_error?.data?.code === ERatingsErrorCode.RatingTimeExpired) {
        message.error(ERatingsErrorMessage.RatingTimeExpired);
      }
      if (_error?.data?.code === ERatingsErrorCode.RatingAlreadyExists) {
        message.error(ERatingsErrorMessage.RatingAlreadyExists);
      }
      if (_error?.data?.code === EAuthErrorCode.UserBlocked) {
        message.error(EAuthErrorMessage.UserBlocked);
      }
    } finally {
      dispatch(setRatingModelLoading(false));
    }
  }

  return thunk;
};
