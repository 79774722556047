import React from 'react';
import { Row, Col, Button } from 'antd';
import { useMediaPredicate } from 'react-media-hook';
import { useHistory } from 'react-router-dom';
import { HeaderMenu } from 'common/components/Header/HeaderMenu';
import { HeaderMenuWrapperMob } from 'common/components/Header/HeaderMenuWrapperMob';
import { MEDIA_MOBILE } from 'common/config/base.config';
import { ERoutesPrivate, ERoutesPublic } from 'common/models/routesModel';
import { BlockingMessage } from 'common/components/Header/BlockingMessage';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { logout } from 'app/store/reducers/auth.reducer';
import { ShopBag } from 'entities/Orders/components/ShopBag';
import { ECustomerStatus } from 'entities/Customer/Customer.models';

export const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const { authModel } = useAppSelector((state) => state.auth);
  const { customerModel } = useAppSelector((state) => state.customer);
  const isMobile: boolean = useMediaPredicate(MEDIA_MOBILE);
  const isCustomerBlocked = customerModel?.status && customerModel.status === ECustomerStatus.Blocked;
  const banExpiredAt = customerModel?.banExpiredAt || undefined;
  const history = useHistory();
  const visibleLogout = authModel && !isMobile;
  const visibleLogin = !authModel && !isMobile;
  const showBlockingMessage = history.location.pathname !== ERoutesPrivate.Bag && isCustomerBlocked;
  const menuLayout = { lg: 20, md: 19, sm: 17, xs: 16 };

  const onLogout = () => {
    dispatch(logout());
  };

  return (
    <header>
      <div className="ant-menu header">
        <Row className="basic__center-desktop-wrapper flex-noWrap height-full" justify="space-between" align="middle">
          <Col {...menuLayout}>{isMobile ? <HeaderMenuWrapperMob /> : <HeaderMenu />}</Col>

          <Col>
            <Row justify="end" align="middle">
              <Col>
                <ShopBag />
              </Col>
              <Col>
                {visibleLogout && (
                  <Button onClick={onLogout} className="header__desktop-logout-btn pr-0">
                    Log out
                  </Button>
                )}
                {visibleLogin && (
                  <Button onClick={() => history.push(ERoutesPublic.Login)} className="header__desktop-logout-btn pr-0">
                    Log In
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {showBlockingMessage && (
        <div className="basic__center-desktop-wrapper">
          <BlockingMessage banExpiredAt={banExpiredAt} />
        </div>
      )}
    </header>
  );
};
