import axios from 'axios';
import { IAddOrderParams, IOrderModel, IRejectOrderParams } from 'entities/Orders/Orders.models';

const basePath = 'orders';

// class OrderTransport extends BaseHttpTransport<AxiosInstance, IMenuItem> {
//   public getCurrent = () => {
//     return this.axios.get(`${this.path}/current`);
//   };
//   public reject = ({ id, ...rest }: IRejectOrderParams) => {
//     return this.axios.put(`${this.path}/${id}/reject-customer`, rest);
//   };
// }

// export const orderTransport = new OrderTransport(basePath, axios);

export const orderTransport = {
  postOrder: (params: IAddOrderParams): Promise<IOrderModel> => axios.post(`${basePath}`, params),
  getCurrentOrder: (): Promise<IOrderModel> => axios.get(`${basePath}/current`),
  rejectOrder: ({ id, ...rest }: IRejectOrderParams): Promise<IOrderModel> =>
    axios.put(`${basePath}/${id}/reject-customer`, rest),
};
