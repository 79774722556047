import axios from 'axios';
import {
  IAuthModel,
  IAuthParams,
  IPasswordRestoreParams,
  ICheckTokenData,
  IAuthRefreshParams,
  IPasswordForgotParams,
} from 'entities/Auth/Auth.models';

const basePath = 'auth';
const passwordRestore = 'restore-password';

export const authTransport = {
  refresh: (params: IAuthRefreshParams): Promise<IAuthModel> => axios.put(`${basePath}`, params),
  login: (params: IAuthParams): Promise<IAuthModel> => axios.post(`${basePath}`, params),
  logout: (): Promise<any> => axios.delete(`${basePath}`),
  passwordRestore: (params: IPasswordForgotParams): Promise<any> => axios.post(`${passwordRestore}`, params),
  passwordRestoreNew: (params: IPasswordRestoreParams): Promise<any> => axios.put(`${passwordRestore}`, params),
  checkPasswordRestoreToken: (params: ICheckTokenData): Promise<any> => axios.post(`${passwordRestore}/check-token`, params),
};
