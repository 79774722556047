import React, { ReactElement } from 'react';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { useAppSelector } from 'app/store/store.hooks';

interface IComponentProps {
  privateRoutes: ReactElement;
  publicRoutes: ReactElement;
  nonCompletedUserRoutes: ReactElement;
}

export const PermissionLayout: React.FC<IComponentProps> = (props) => {
  const { authModel, authModelLoading, authModelParams } = useAppSelector((state) => state.auth);
  const { customerModel, customerModelLoading } = useAppSelector((state) => state.customer);
  const { privateRoutes, publicRoutes, nonCompletedUserRoutes } = props;
  const authorized = Boolean(authModel);
  const isUserCompleted = customerModel?.isCompleted;
  const checkForAccountComplete = isUserCompleted ? privateRoutes : nonCompletedUserRoutes;

  if ((authModelLoading && !authModelParams) || customerModelLoading) {
    return <LoadingSpin />;
  }

  if (!authorized) {
    return publicRoutes;
  }

  if (customerModel) {
    return checkForAccountComplete;
  } else {
    return <LoadingSpin />;
  }
};
