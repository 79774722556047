import React from 'react';
import { Button } from 'antd';
import { ModalContent } from 'common/components';
import { useAppDispatch } from 'app/store/store.hooks';
import { reorder } from 'app/store/reducers/orderHistory.reducer';
import { IOrderHistoryModel } from 'entities/OrderHistory/OrderHistory.models';

interface IComponentProps {
  isVisible: boolean;
  onClose(): void;
  orderHistoryItem?: IOrderHistoryModel;
}

export const ReorderConfirmModal: React.FC<IComponentProps> = props => {
  const dispatch = useAppDispatch();
  const { isVisible, onClose, orderHistoryItem } = props;

  const handleContinue = () => {
    const { id, store } = orderHistoryItem || {};
    const { id: storeId, storeName } = store || {};

    if (id && storeId) {
      dispatch(reorder({ storeId, id, storeName }));
    }

    onClose();
  };

  return (
    <ModalContent
      visible={isVisible}
      close={onClose}
      header="You already have items in your Bag."
      subHeader="If you reorder, the currently selected store will be changed and the items in your Bag will be replaced with the items of this order."
    >
      <Button size="large" block className="btn mb-7" type="primary" onClick={handleContinue}>
        Continue
      </Button>
      <Button size="large" block className="btn" onClick={onClose}>
        Cancel
      </Button>
    </ModalContent>
  );
};
