import React from 'react';
import { Button, Form, Input, Row } from 'antd';
import { Link } from 'react-router-dom';
import { ERoutesCommon } from 'common/models/routesModel';
import { emailValidationRules } from 'common/consts/validation.consts';
import { useFormMapper } from 'common/helpers/form.helper';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { login } from 'app/store/reducers/auth.reducer';
import { SocialBar } from 'entities/Auth/components/SocialBar';
import { IAuthParams } from 'entities/Auth/Auth.models';

interface IComponentProps {
  close?: () => void;
}

export const LoginForm: React.FC<IComponentProps> = (props) => {
  const dispatch = useAppDispatch();
  const { close } = props;
  const { authModelLoading, authModel, authModelParams, authModelError } = useAppSelector((state) => state.auth);
  const { fields } = useFormMapper(['email', 'password'], authModel, authModelParams, authModelError);

  const closeModal = () => {
    if (close) {
      close();
    }
  };

  const onSubmit = (values: IAuthParams) => {
    if (authModelLoading) {
      return;
    }

    dispatch(login(values));
  };

  return (
    <Form onFinish={onSubmit} requiredMark={false} layout="vertical" fields={fields}>
      <Form.Item validateTrigger="onBlur" rules={emailValidationRules} name="email" label="Email">
        <Input name="email" type="email" placeholder="Email" disabled={authModelLoading} />
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: 'Password is required',
          },
        ]}
        name="password"
        label="Password"
        className="mb-4"
      >
        <Input.Password type="password" placeholder="Password" disabled={authModelLoading} />
      </Form.Item>

      <Row justify="end" align="middle" className="mb-8">
        <Link to={ERoutesCommon.PasswordForgot} className="link" title="Forgot password?" onClick={closeModal}>
          Forgot password?
        </Link>
      </Row>

      <Button size="large" block className="btn" htmlType="submit" type="primary" disabled={authModelLoading}>
        Log in
      </Button>
      <div className="social-bar__separator font-weight-bold fs">Or</div>
      <SocialBar disabled={authModelLoading} />
    </Form>
  );
};
