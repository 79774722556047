import React from 'react';
import { Button, Form, Input, message } from 'antd';
import { useFormMapper } from 'common/helpers/form.helper';
import { useResetTimer } from 'common/hooks/ResetTimer.hooks';
import { secondsToStringTime } from 'common/helpers/date.helper';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { activateCustomerPhone, addCustomerPhone } from 'app/store/reducers/customer.reducer';
import { EAuthSuccessMessage } from 'entities/Auth/Auth.models';

interface IComponentProps {
  phone: string;
  onSuccess?: Function;
}

export const CodeConfirm: React.FC<IComponentProps> = props => {
  const dispatch = useAppDispatch();
  const { customerPhoneLoading, customerPhoneCodeLoading, customerPhoneCodeError } = useAppSelector(state => state.customer);
  const loading = customerPhoneLoading || customerPhoneCodeLoading;
  const { setTimer, isTimerActive, seconds } = useResetTimer();

  const { fields } = useFormMapper(['token'], null, customerPhoneCodeError);

  const submit = (values: any) => {
    const { onSuccess } = props;
    const token = values?.token || '';

    dispatch(activateCustomerPhone({ token }, () => onSuccess && onSuccess()));
  };

  const resend = () => {
    const { phone } = props;

    if (loading) {
      return;
    }

    setTimer();

    dispatch(addCustomerPhone({ phone }, () => message.success(EAuthSuccessMessage.SMSResend)));
  };

  return (
    <Form onFinish={submit} requiredMark={false} layout="vertical" fields={fields}>
      <Form.Item
        className="mb-2"
        rules={[
          {
            required: true,
            message: 'Code is required'
          }
        ]}
        name="token"
      >
        <Input name="token" disabled={loading} />
      </Form.Item>

      <div className="fs t-align-c">
        Didn&apos;t receive the code?{' '}
        <Button className="btn_link" type="link" onClick={resend} disabled={loading || isTimerActive}>
          Resend
        </Button>
      </div>

      {isTimerActive && (
        <div className="fs font-weight-bold color-orange-main t-align-c mt-2">
          Please check your SMS inbox for your code ({secondsToStringTime(seconds)})
        </div>
      )}

      <Button size="large" block className="btn mt-7" type="primary" htmlType="submit" disabled={loading}>
        Confirm and order
      </Button>
    </Form>
  );
};
