// Custom object for DatePicker
// reason: antd v5 with MomentJS incompatibility - https://ant.design/docs/react/migration-v5#technology-adjustment
// solution - https://ant.design/docs/react/use-custom-date-library#custom-component

import React from "react";
import { DatePicker as AntDatePicker } from "antd";
import type { Moment } from "moment";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import iconCalendar from "app/assets/images/calendar.svg";

const CustomDatePicker =
  AntDatePicker.generatePicker<Moment>(momentGenerateConfig);

export const DatePicker = ({ format = "MM / DD / YYYY", ...props }) => {
  return (
    <CustomDatePicker
      suffixIcon={<img src={iconCalendar} alt="calendar" />}
      format={format}
      {...props}
    />
  );
};
