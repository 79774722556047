import React, { memo, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Empty, List, Spin } from 'antd';
import { LoadingSpin } from 'common/components/LoadingSpin';
import { IBaseFilterModel } from 'common/models/requestModels';
import { store as reduxStore } from 'app/store';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { getOrderHistoryCollection, reorder, setOrderHistoryCollection } from 'app/store/reducers/orderHistory.reducer';
import { IOrderHistoryCollectionFilter, IOrderHistoryModel } from 'entities/OrderHistory/OrderHistory.models';
import { OrderHistoryItem } from 'entities/OrderHistory/components/OrderHistoryItem';
import { ECustomerStatus } from 'entities/Customer/Customer.models';

interface IComponentProps {
  filter: IOrderHistoryCollectionFilter;
  showConfirm: (item: IOrderHistoryModel) => void;
  emptyText: string | React.ReactElement;
}

const PAGE_SIZE_COUNT = 10;

export const OrderHistoryList: React.FC<IComponentProps> = memo(
  ({ filter, emptyText, showConfirm }) => {
    const dispatch = useAppDispatch();
    const { orderHistoryCollection, orderHistoryCollectionLoading, orderHistoryReorderModelLoading } = useAppSelector(
      (state) => state.orderHistory
    );
    const [orders, setOrders] = useState<IOrderHistoryModel[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const ordersCount = orderHistoryCollection?.meta?.count || 0;
    const hasMoreOrders = !orderHistoryCollectionLoading && orders.length < ordersCount;

    const clearCollection = () => {
      dispatch(setOrderHistoryCollection(null));
    };

    const loadCollection = (params: IBaseFilterModel) => {
      const { store } = filter || {};
      const newFilter: IOrderHistoryCollectionFilter = {
        ...params,
      };

      if (store) {
        newFilter.store = store;
      }

      dispatch(getOrderHistoryCollection(newFilter, () => setPageIndex((prevIndex) => prevIndex + 1)));
    };

    const loadData = () => {
      if (orderHistoryCollectionLoading) {
        return;
      }

      const ordersCount = orderHistoryCollection?.meta?.count || 0;
      const hasMore = !ordersCount || ordersCount > PAGE_SIZE_COUNT * pageIndex;

      if (hasMore) {
        const params: IBaseFilterModel = {
          limit: PAGE_SIZE_COUNT,
          offset: PAGE_SIZE_COUNT * pageIndex,
        };

        loadCollection(params);
      }
    };

    const renderListItem = (item: IOrderHistoryModel) => {
      const reduxState = reduxStore.getState();
      const { cartModel } = reduxState.cart;
      const { customerModel } = reduxState.customer;
      const isBagEmpty = !cartModel || cartModel?.items?.length === 0;
      const isUserBanned = customerModel?.status === ECustomerStatus.Blocked;

      return (
        <OrderHistoryItem
          order={item}
          addReorder={(params) => dispatch(reorder(params))}
          isBagEmpty={isBagEmpty}
          isUserBanned={isUserBanned}
          showConfirm={showConfirm}
        />
      );
    };

    useEffect(() => {
      return () => clearCollection();
    }, []);

    useEffect(() => {
      if (orderHistoryCollection) {
        setOrders(orderHistoryCollection.data);
      }
    }, [orderHistoryCollection]);

    useEffect(() => {
      setPageIndex(0);
      clearCollection();
      loadCollection({ limit: PAGE_SIZE_COUNT, offset: 0 });
    }, [filter]);

    return (
      <Spin spinning={orderHistoryReorderModelLoading}>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadData}
          hasMore={hasMoreOrders}
          useWindow={true}
          getScrollParent={() => document.getElementById('"basicLayout"')}
        >
          {(orderHistoryCollection || !orderHistoryCollectionLoading) && (
            <List
              itemLayout="vertical"
              dataSource={orders}
              renderItem={renderListItem}
              locale={{
                emptyText: !orderHistoryCollectionLoading ? (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyText} />
                ) : null,
              }}
            />
          )}
          {(orderHistoryCollectionLoading || orderHistoryReorderModelLoading) && <LoadingSpin />}
        </InfiniteScroll>
      </Spin>
    );
  },
  (oldProps, newProps) => JSON.stringify(oldProps.filter) === JSON.stringify(newProps.filter)
);
