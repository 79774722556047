import axios from 'axios';
import { objectToQuery } from 'common/helpers/filters.helper';
import { IStoreModel, IStoreCollection, IStoreCollectionParams } from 'entities/Store/Store.models';

const basePath = 'stores';
const listPath = 'customer-list';
const storeShortIdPath = 'by-short-id';

// class StoresModelTransport extends BaseHttpTransport<AxiosInstance, IStoreModel> {
//   public getByShortId = (code: string) => axios.get(`${this.path}/${getByShortIdPath}/${code}`);
// }

// export const storesModelTransport = new StoresModelTransport('/stores', axios);
// export const storesListTransport = new BaseHttpTransport<AxiosStatic, IStoreModel, IStoreCollection>(
//   '/stores/customer-list',
//   axios
// );

export const storeTransport = {
  getStoreModelById: (storeId: string): Promise<IStoreModel> => axios.get(`${basePath}/${storeId}`),
  getStoreModelByShortId: (storeShortId: string): Promise<IStoreModel> =>
    axios.get(`${basePath}/${storeShortIdPath}/${storeShortId}`),
  getStoreCollection: (params?: IStoreCollectionParams): Promise<IStoreCollection> =>
    axios.get(`${basePath}/${listPath}/${objectToQuery(params)}`)
};
