import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { IModalContent, ModalContent } from 'common/components';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { setCustomerModel } from 'app/store/reducers/customer.reducer';
import { setUiAuthModal } from 'app/store/reducers/ui.reducer';
import { EAuthModals } from 'entities/UI/UI.models';
import { LoginForm, SignUp } from 'entities/Auth/components';

export const AuthModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { uiAuthModal } = useAppSelector(state => state.ui);
  const [contentType, toggleModalType] = useState<EAuthModals>();
  const isVisible = uiAuthModal?.isVisible;

  useEffect(() => {
    if (isVisible) {
      toggleModalType(uiAuthModal?.type || EAuthModals.Type);
    }
  }, [isVisible, toggleModalType, uiAuthModal]);

  const components: IModalContent = {
    [EAuthModals.Type]: {
      content: (
        <>
          <Button
            block
            className="btn mb-9"
            size="large"
            type="primary"
            onClick={() => {
              toggleModalType(EAuthModals.Signup);
            }}
          >
            Register
          </Button>

          <Button
            size="large"
            block
            className="btn"
            onClick={() => {
              toggleModalType(EAuthModals.Login);
            }}
          >
            Log in
          </Button>
        </>
      ),
      header: 'Please register or log in'
    },
    [EAuthModals.Login]: {
      content: <LoginForm />,
      header: 'Log in'
    },
    [EAuthModals.Signup]: {
      content: (
        <SignUp
          onSuccess={() => {
            toggleModalType(EAuthModals.Verify);
          }}
        />
      ),
      header: 'Registration',
      subheader: 'It will take less than a minute'
    },
    [EAuthModals.Verify]: {
      content: (
        <Button
          size="large"
          block
          className="btn"
          type="primary"
          onClick={() => {
            dispatch(setUiAuthModal({ isVisible: false }));
            dispatch(setCustomerModel(null));
          }}
        >
          Got it!
        </Button>
      ),
      header: 'Please verify your account',
      subheader: 'Confirmation link has been sent to your email'
    }
  };
  const modalContent = contentType && components[contentType];

  return (
    <ModalContent visible={isVisible} close={() => {}} header={modalContent?.header} subHeader={modalContent?.subheader}>
      {modalContent?.content}
    </ModalContent>
  );
};
