import React from 'react';
import { Spin } from 'antd';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { useAppSelector } from 'app/store/store.hooks';
import { OrderPageBeforeSend } from 'entities/Orders/components/OrderPageBeforeSend';
import { BagPageEmpty } from 'entities/Orders/components/BagPageEmpty';
import { OrderDetailsModal } from 'entities/UI/components/OrderDetailsModal';

export const BagPage: React.FC = () => {
  const { menuAddOnsLoading } = useAppSelector(state => state.menu);
  const { cartModel, cartModelLoading } = useAppSelector(state => state.cart);
  const { orderModelLoading } = useAppSelector(state => state.order);
  const { items } = cartModel || {};
  const loading = cartModelLoading || orderModelLoading || menuAddOnsLoading;

  const content =
    cartModel && items?.length ? <OrderPageBeforeSend cart={cartModel} isBag /> : <TwoColumnsLayout left={<BagPageEmpty />} />;

  return (
    <Spin spinning={loading}>
      {content}
      <OrderDetailsModal />
    </Spin>
  );
};
