// DON't forget update index.html after update types!

export const envConfig = (process?.env.NODE_ENV === 'development' ? process.env : (window as any).env) as {
  SENTRY_DSN: string;
  REACT_APP_SENTRY_DSN: string;
  NODE_ENV: string;
  GOOGLE_TAG_ID: string;
  REACT_APP_GOOGLE_TAG_ID: string;
  FB_PIXEL_ID: string;
  REACT_APP_FB_PIXEL_ID: string;
  GOOGLE_ANALYTICS_ID: string;
  REACT_APP_GOOGLE_ANALYTICS_ID: string;
  RELEASE_VERSION: string;
  REACT_APP_RELEASE_VERSION: string;
};
