import { IGDLEvent } from 'common/models/gdlModels';
import { envConfig } from 'common/config/env.config';
import { mapCartItemsToGDLProducts } from 'common/mappers/GDL.mapper';
import { ICartItemModel } from 'entities/Cart/Cart.models';

// @ts-ignore
const pushEvent = (event: IGDLEvent) => window?.dataLayer && Array.isArray(window.dataLayer) && window.dataLayer.push(event);

export const GDLHelper = {
  pushSignupEvent(customerName: string, customerEmail: string) {
    if (envConfig.NODE_ENV !== 'production') {
      return;
    }

    pushEvent({
      customerName,
      customerEmail,
      event: 'signUp',
    });
  },

  pushAddToBagEvent(customerName: string, customerEmail: string, orderSum: number, item: ICartItemModel) {
    if (envConfig.NODE_ENV !== 'production') {
      return;
    }

    pushEvent({
      orderSum,
      customerName,
      customerEmail,
      event: 'addToCart',
      ecommerce: {
        add: {
          products: mapCartItemsToGDLProducts([item]),
        },
      },
    });
  },

  pushPurchaseEvent(customerName: string, customerEmail: string, orderId: string, orderSum: number, items: ICartItemModel[]) {
    if (envConfig.NODE_ENV !== 'production') {
      return;
    }

    pushEvent({
      customerName,
      customerEmail,
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: orderId,
            revenue: orderSum,
          },
          products: mapCartItemsToGDLProducts(items),
        },
      },
    });
  },
};
