import { createBrowserHistory } from 'history';
import { combineReducers, configureStore, Middleware, PayloadAction } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { IError } from '@axmit/error-helper';
import { errorWatcher } from 'common/utils/errorWatcher';
import authReducer from 'app/store/reducers/auth.reducer';
import userReducer from 'app/store/reducers/user.reducer';
import cartReducer from 'app/store/reducers/cart.reducer';
import customerReducer from 'app/store/reducers/customer.reducer';
import menuReducer from 'app/store/reducers/menu.reducer';
import orderReducer from 'app/store/reducers/order.reducer';
import orderHistoryReducer from 'app/store/reducers/orderHistory.reducer';
import peakHoursReducer from 'app/store/reducers/peakHours.reducer';
import promoReducer from 'app/store/reducers/promo.reducer';
import qrReducer from 'app/store/reducers/qr.reducer';
import ratingReducer from 'app/store/reducers/rating.reducer';
import storeReducer from 'app/store/reducers/store.reducer';
import uiReducer from 'app/store/reducers/ui.reducer';

const { createReduxHistory, routerReducer, routerMiddleware } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const appReducers = combineReducers({
  router: routerReducer,
  auth: authReducer,
  user: userReducer,
  customer: customerReducer,
  cart: cartReducer,
  menu: menuReducer,
  order: orderReducer,
  orderHistory: orderHistoryReducer,
  peakHours: peakHoursReducer,
  promo: promoReducer,
  qr: qrReducer,
  rating: ratingReducer,
  store: storeReducer,
  ui: uiReducer,
});

export const errorHanler: Middleware = () => (next) => (action) => {
  if (typeof action === 'object' && (action as PayloadAction).type.includes('Error')) {
    const error = (action as PayloadAction).payload as unknown as IError;

    errorWatcher(error);
  }

  return next(action);
};

export const store = configureStore({
  reducer: appReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(routerMiddleware).prepend(errorHanler),
});

export const history = createReduxHistory(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
