import axios from 'axios';
import { IGetMenuDefaultCollectionParams, IGetMenuCollectionParams, IMenuCollection } from 'entities/Menu/Menu.models';

const basePath = 'menu';
const getDefaultMenuPath = 'default';

// class MenuTransport extends BaseHttpTransport<AxiosInstance, IMenuItem> {
//   public getList = ({ id, ...rest }: IGetMenuCollectionParams) => {
//     return this.axios.get(`${this.path}/${id}`, { params: rest });
//   };
//   public getDefault = (params: IGetMenuDefaultCollectionParams) => axios.get(`${this.path}/${getDefaultMenuPath}`, { params });
// }

// export const menuTransport = new MenuTransport(basePath, axios);

export const menuTransport = {
  getMenuCollection: ({ id, ...rest }: IGetMenuCollectionParams): Promise<IMenuCollection> =>
    axios.get(`${basePath}/${id}`, { params: rest }),
  getDefaultMenu: (params: IGetMenuDefaultCollectionParams): Promise<IMenuCollection> =>
    axios.get(`${basePath}/${getDefaultMenuPath}`, { params }),
};
