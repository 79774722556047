import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Input, List, message, Radio } from 'antd';
import debounce from 'lodash.debounce';
import { throttle } from 'lodash';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ModalContent } from 'common/components';
import {
  ERROR_MESSAGE_DURATION,
  ERROR_MESSAGE_PERMISSION_DENIED,
  ERROR_MESSAGE_POSITION_UNAVAILABLE,
  ERROR_MESSAGE_TIMEOUT,
  ERROR_NAVIGATOR,
  GEOLOCATION_REQUEST_TIMEOUT,
  STORE_SEARCH_LIMIT,
  STORE_SEARCH_WAIT_TIME
} from 'common/consts/Store.const';
import { IGeolocationPosition, IGEolocationPositionError } from 'common/models/coordinates';
import iconMarker from 'app/assets/images/marker.svg';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { getStoreCollection, setStoreCollection } from 'app/store/reducers/store.reducer';
import { setUiCommonModal } from 'app/store/reducers/ui.reducer';
import { EDisplayMode, IStoreCollectionParams, IStoreModel } from 'entities/Store/Store.models';
import { StoresListItem } from 'entities/Store/components/StoresListItem';

interface IComponentProps {
  onSelect(store: IStoreModel): void;
  options?: IStoreModel[];
}

export const StorePickerModal: React.FC<IComponentProps> = props => {
  const dispatch = useAppDispatch();
  const { storeCollection, storeCollectionLoading } = useAppSelector(state => state.store);
  const { uiCommonModal } = useAppSelector(state => state.ui);
  const { onSelect, options } = props;
  const { isVisible } = uiCommonModal || {};
  const [locationInput, setLocationInput] = useState('');
  const [displayMode, setDisplayMode] = useState<EDisplayMode>(EDisplayMode.All);
  const [locationPosition, setLocationPosition] = useState<IGeolocationPosition>();
  const isManualOptions = options !== undefined;

  const search = useCallback(
    debounce(params => dispatch(getStoreCollection(params)), STORE_SEARCH_WAIT_TIME),
    []
  );
  const locationCoordinates: Pick<IStoreCollectionParams, 'lat' | 'lon'> = useMemo(
    () => ({
      lat: locationPosition?.coords.latitude,
      lon: locationPosition?.coords.longitude
    }),
    [locationPosition]
  );

  const searchForStores = () => {
    search({
      customerSearch: locationInput,
      orderField: 'distance',
      orderDirection: 'ASC',
      ...(displayMode === EDisplayMode.All && { limit: STORE_SEARCH_LIMIT }),
      ...(displayMode === EDisplayMode.Nearest && locationCoordinates)
    });
  };

  const closeModal = () => {
    dispatch(setUiCommonModal({ isVisible: false }));
    setLocationInput('');
    dispatch(setStoreCollection(null));
  };

  const handleLocationInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocationInput(e.target.value);
  };

  const getFilteredOptions = useMemo(() => {
    // We need to escape some symbols in string to avoid a matching exception.
    const queryRegex = new RegExp(locationInput.replace(/[[()?+*\\]/g, ''), 'gi');

    return options?.filter(({ storeName }) => storeName.match(queryRegex));
  }, [locationInput, options]);

  const getEmptyText = useCallback(() => {
    let emptyText: string | boolean = '';

    if (isManualOptions) {
      emptyText = getFilteredOptions?.length ? true : 'No stores available for ordering the item.';
    } else {
      const notFoundText =
        displayMode === EDisplayMode['All']
          ? 'No stores found. Please try another location'
          : 'Sorry! There are currently no nearby stores on MB Go within 10km of your location.';

      emptyText = storeCollection ? notFoundText : true;
    }

    return emptyText;
  }, [isManualOptions, storeCollection, getFilteredOptions]);

  const handleStoreSelect = (newStore: IStoreModel) => {
    onSelect(newStore);
    closeModal();
  };

  const showErrorMessageThrottled = throttle(
    (errText: string) => message.error(errText, ERROR_MESSAGE_DURATION / 1000),
    ERROR_MESSAGE_DURATION
  );

  const getCoordinates = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (pos: IGeolocationPosition) => {
        setLocationPosition(pos);
      },
      (err: IGEolocationPositionError) => {
        if (err.code === 1) {
          showErrorMessageThrottled(ERROR_MESSAGE_PERMISSION_DENIED);
        } else if (err.code === 2) {
          showErrorMessageThrottled(ERROR_MESSAGE_POSITION_UNAVAILABLE);
        } else if (err.code === 3) {
          showErrorMessageThrottled(ERROR_MESSAGE_TIMEOUT);
        }

        setDisplayMode(EDisplayMode.All);
      },
      { timeout: GEOLOCATION_REQUEST_TIMEOUT, enableHighAccuracy: true, maximumAge: Infinity }
    );
  }, []);

  const handleRadioChange = useCallback(
    (evt: RadioChangeEvent) => {
      setDisplayMode(evt.target.value);

      if (evt.target.value === EDisplayMode.Nearest && !locationPosition) {
        if (navigator.geolocation) {
          getCoordinates();
        } else {
          showErrorMessageThrottled(ERROR_NAVIGATOR);
          setDisplayMode(EDisplayMode.All);
        }
      }
    },
    [locationPosition]
  );

  useEffect(() => {
    if (
      (displayMode === EDisplayMode.All && locationInput.trim() !== '' && !isManualOptions) ||
      (displayMode === EDisplayMode.Nearest && locationPosition)
    ) {
      searchForStores();
    }
  }, [displayMode, locationInput, locationPosition]);

  return (
    <ModalContent
      visible={!!isVisible}
      close={closeModal}
      header="Masayang Araw! ☀️"
      subHeader={
        <span>
          Search for your favourite Minute Burger store by
          <br /> City or find the store that&apos;s nearest your location.
        </span>
      }
    >
      <>
        <Radio.Group value={displayMode} onChange={handleRadioChange} className="radio-horizontal mb-8">
          <Radio value={EDisplayMode.All}>All stores</Radio>
          <Radio value={EDisplayMode.Nearest}>Nearest Me</Radio>
        </Radio.Group>
        <Input
          value={locationInput}
          prefix={<img src={iconMarker} alt="marker" className="mr-3" />}
          onChange={handleLocationInputChange}
        />

        <List
          className="mt-3 store-picker-list"
          itemLayout="horizontal"
          dataSource={isManualOptions ? getFilteredOptions : storeCollection?.data}
          loading={storeCollectionLoading}
          locale={{ emptyText: getEmptyText() }}
          renderItem={item => <StoresListItem store={item} onSelect={handleStoreSelect} />}
        />
      </>
    </ModalContent>
  );
};
