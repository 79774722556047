export interface IOrderModel extends Components.Schemas.OrderDto {}
export interface IAddOrderParams extends Components.Schemas.PlaceAnOrderDto {}

export interface IOrderItemModel extends Components.Schemas.OrderItemDto {}

export interface IOrderItemAddOnModel extends Components.Schemas.BaseOrderItemDto {}

export interface IRejectOrderParams extends Components.Schemas.OrderCustomerRejectDto {
  id: string;
}

export enum EOrderStatus {
  New = 'new',
  InProgress = 'inProgress',
  Ready = 'ready',
  Finished = 'finished',
  RejectedByCustomer = 'rejectedByCustomer',
  RejectedByStore = 'rejectedByStore'
}
export enum ECommonRejectReasons {
  ChangeProduct = 'changeProduct',
  ChangeQuantity = 'changeQuantity',
  ChangeTime = 'changeTime',
  WrongLocation = 'wrongLocation',
  WaitedTooLong = 'waitedTooLong',
  StoreTooBusy = 'storeTooBusy',
  ProductsUnavailable = 'productsUnavailable',
  Abandoned = 'abandoned',
  Other = 'other'
}
export type TRejectReasons = Exclude<Components.Schemas.OrderCustomerRejectDto['rejectReason'], undefined>;

export const rejectReasons: Record<TRejectReasons, TRejectReasons> = {
  changeProduct: 'changeProduct',
  changeQuantity: 'changeQuantity',
  changeTime: 'changeTime',
  wrongLocation: 'wrongLocation',
  waitedTooLong: 'waitedTooLong'
};

export const rejectReasonLabels: Record<TRejectReasons, string> = {
  changeProduct: 'Will change product',
  changeQuantity: 'Will change quantity',
  changeTime: 'Will change pickup time',
  wrongLocation: 'Set wrong location',
  waitedTooLong: 'Waited too long'
};

export const rejectReasonsGroup = [
  { value: rejectReasons.changeProduct, label: rejectReasonLabels.changeProduct },
  { value: rejectReasons.changeQuantity, label: rejectReasonLabels.changeQuantity },
  { value: rejectReasons.changeTime, label: rejectReasonLabels.changeTime },
  { value: rejectReasons.wrongLocation, label: rejectReasonLabels.wrongLocation },
  { value: rejectReasons.waitedTooLong, label: rejectReasonLabels.waitedTooLong }
];

export const currentOrderStatus = {
  [EOrderStatus.New]: 'Waiting for Store to Confirm',
  [EOrderStatus.InProgress]: 'Preparing your Order',
  [EOrderStatus.Ready]: 'Ready for pickup',
  [EOrderStatus.Finished]: 'Completed',
  [EOrderStatus.RejectedByStore]: 'Cancelled',
  [EOrderStatus.RejectedByCustomer]: 'Cancelled'
};

export enum EOrdersErrorCode {
  AlreadyFinished = 'error.orderAlreadyFinishedException',
  ActiveOrderExists = 'error.activeOrderExistsException',
  UnavailableItemInOrder = 'error.unprocessableAssetItemException',
  EmptyItems = 'error.emptyItemsException',
  UnavailablePromo = 'error.promoIsUnavailableException'
}

export enum EOrdersErrorMessage {
  AlreadyFinished = 'The order was already accepted.',
  ActiveOrderExists = 'Please wait until your current order has been completed before submitting a new order',
  TotalSumExceeds = 'Your order value exceeds the allowable cash-on-pickup amount of P1500.',
  ItemUnavailable = 'Asset item is unavailable',
  ItemDoesNotExistInStore = "Asset item doesn't exist in asset of current store",
  EmptyItems = 'You should add at least 1 item to order'
}
