import React from 'react';
import { axiosConfig } from 'index';
import { getCreds, refreshToken } from '@axmit/axios-patch-jwt';
import { FacebookButton } from 'common/components/FacebookButton';
import { ERoutesPrivate } from 'common/models/routesModel';
import { GoogleButton } from 'common/components/GoogleButton';
import { objectToQuery } from 'common/helpers/filters.helper';
import exclamationMark from 'app/assets/images/exclamationMark.svg';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { setUiSocialLinkModal } from 'app/store/reducers/ui.reducer';
import { ESocialLinkModals } from 'entities/UI/UI.models';
import { ESocialType } from 'entities/Auth/Auth.models';

interface IComponentProps {
  className?: string;
}

type AllProps = IComponentProps;

export const SocialLinkBar: React.FC<AllProps> = (props) => {
  const dispatch = useAppDispatch();
  const { customerModel } = useAppSelector((state) => state.customer);
  const { className } = props;
  const { isPasswordSet, isGoogleSet, isFacebookSet } = customerModel || {};
  const facebookRemoveDisabled = isFacebookSet && !isPasswordSet && !isGoogleSet;
  const googleRemoveDisabled = isGoogleSet && !isPasswordSet && !isFacebookSet;

  const connectSocial = async (socialType: ESocialType) => {
    await refreshToken(axiosConfig);
    const creds = await getCreds();

    const baseUrl = socialType === ESocialType.Facebook ? ERoutesPrivate.ConnectFacebook : ERoutesPrivate.ConnectGoogle;

    if (creds.access) {
      window.location.replace(`${baseUrl}${objectToQuery(creds.access)}`);
    }
  };

  const removeSocial = (socialType: ESocialType) => {
    dispatch(setUiSocialLinkModal({ isVisible: true, type: ESocialLinkModals.UnlinkConfirm, socialType }));
  };

  return (
    <div className={className}>
      {isFacebookSet ? (
        <FacebookButton
          disabled={facebookRemoveDisabled}
          onClick={() => removeSocial(ESocialType.Facebook)}
          text="Remove Facebook"
        />
      ) : (
        <FacebookButton onClick={() => connectSocial(ESocialType.Facebook)} text="Connect with Facebook" />
      )}
      {facebookRemoveDisabled && (
        <div className="d-flex mt-4">
          <div className="mr-5 ml-2 pt-1">
            <img width={20} src={exclamationMark} alt="warning" />
          </div>
          <div className="fs-xxs color-default">
            Before removing your Facebook account from MB Go, please set a password in your Account Settings or connect your
            account with Google instead.
          </div>
        </div>
      )}
      {!isGoogleSet && (
        <GoogleButton className="mt-7" text="Connect with Google" onClick={() => connectSocial(ESocialType.Google)} />
      )}
      {isGoogleSet && (
        <GoogleButton
          className="mt-7"
          disabled={googleRemoveDisabled}
          onClick={() => removeSocial(ESocialType.Google)}
          text="Remove Google"
        />
      )}
      {googleRemoveDisabled && (
        <div className="d-flex mt-4">
          <div className="mr-5 ml-2 pt-1">
            <img width={20} src={exclamationMark} alt="warning" />
          </div>
          <div className="fs-xxs color-default">
            Before removing your Google account from MB Go, please set a password in your Account Settings or connect your account
            with Facebook instead.
          </div>
        </div>
      )}
    </div>
  );
};
