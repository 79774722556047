import React from 'react';
import { Button, Form, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { ERoutesPublic } from 'common/models/routesModel';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { emailValidationRules } from 'common/consts/validation.consts';
import { useFormMapper } from 'common/helpers/form.helper';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { restorePassword } from 'app/store/reducers/auth.reducer';

export const ForgotPasswordPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { authPasswordRestore, authPasswordRestoreParams, authPasswordRestoreLoading, authPasswordRestoreError } = useAppSelector(
    (state) => state.auth
  );
  const { fields } = useFormMapper(['email'], authPasswordRestore, authPasswordRestoreParams, authPasswordRestoreError);

  const onSubmit = (values: { email: string }) => {
    dispatch(restorePassword(values));
  };

  return (
    <TwoColumnsLayout
      left={
        <MobileLayout header="Restore password" back={() => history.push(ERoutesPublic.Login)}>
          <Form onFinish={onSubmit} requiredMark={false} layout="vertical" fields={fields}>
            <Form.Item validateTrigger="onBlur" rules={emailValidationRules} name="email" label="Email">
              <Input name="email" type="email" placeholder="Email" disabled={authPasswordRestoreLoading} />
            </Form.Item>

            <Button size="large" block className="btn" htmlType="submit" type="primary" disabled={authPasswordRestoreLoading}>
              Send instructions
            </Button>
          </Form>
        </MobileLayout>
      }
    />
  );
};
