import React, { useState } from 'react';
import { Button, Form, Input, Row, Checkbox, Col } from 'antd';
import { Link } from 'react-router-dom';
import { useFormMapper } from 'common/helpers/form.helper';
import { ERoutesCommon } from 'common/models/routesModel';
import {
  emailValidationRules,
  firstNameValidationRules,
  lastNameValidationRules,
  passValidationRules,
} from 'common/consts/validation.consts';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { addCustomerModel } from 'app/store/reducers/customer.reducer';
import { SocialBar } from 'entities/Auth/components/SocialBar';

interface IComponentProps {
  onSuccess?: Function;
}

export const SignUp: React.FunctionComponent<IComponentProps> = (props) => {
  const { onSuccess } = props;
  const dispatch = useAppDispatch();
  const { customerModel, customerModelParams, customerModelError, customerModelLoading } = useAppSelector(
    (state) => state.customer
  );
  const { fields } = useFormMapper(
    ['firstName', 'lastName', 'email', 'password'],
    customerModel,
    customerModelParams,
    customerModelError
  );
  const [agreeWithPolicy, togglePolicy] = useState<boolean>();
  const disabledForm = customerModelLoading || !agreeWithPolicy;

  const login = (values: any) => {
    const firstName = values?.firstName;
    const lastName = values?.lastName;
    const email = values?.email;
    const password = values?.password;

    dispatch(addCustomerModel({ firstName, lastName, email, password, onSuccess }));
  };

  return (
    <Form onFinish={login} requiredMark={false} layout="vertical" fields={fields}>
      <Form.Item>
        <Row gutter={10} className="flex-noWrap" align="middle">
          <Col>
            <Checkbox onChange={() => togglePolicy(!agreeWithPolicy)} checked={agreeWithPolicy} />
          </Col>

          <Col className="fs">
            I agree with{' '}
            <Link target="_blank" rel="noopener noreferrer" to={ERoutesCommon.Privacy} className="link">
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link target="_blank" rel="noopener noreferrer" to={ERoutesCommon.ToS} className="link">
              Terms of Service
            </Link>
          </Col>
        </Row>
      </Form.Item>
      <SocialBar disabled={disabledForm} />
      <div className="social-bar__separator font-weight-bold fs">Or</div>

      <Form.Item
        rules={[firstNameValidationRules.required, firstNameValidationRules.signUpMin, firstNameValidationRules.max]}
        validateTrigger="onBlur"
        name="firstName"
        label="First name"
      >
        <Input name="firstName" placeholder="First name" disabled={disabledForm} />
      </Form.Item>

      <Form.Item
        rules={[lastNameValidationRules.required, lastNameValidationRules.signUpMin, lastNameValidationRules.max]}
        validateTrigger="onBlur"
        name="lastName"
        label="Last name"
      >
        <Input name="lastName" placeholder="Last name" disabled={disabledForm} />
      </Form.Item>

      <Form.Item validateTrigger="onBlur" rules={emailValidationRules} name="email" label="Email">
        <Input name="email" type="email" disabled={disabledForm} />
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: 'Password is required',
          },
          ...passValidationRules,
        ]}
        validateFirst
        name="password"
        label="Password"
      >
        <Input.Password type="password" disabled={disabledForm} />
      </Form.Item>

      <Button size="large" block className="btn" type="primary" htmlType="submit" disabled={disabledForm}>
        Continue
      </Button>
    </Form>
  );
};
