import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaPredicate } from 'react-media-hook';
import { Button, List, message, Row } from 'antd';
import moment from 'moment';
import { MobileLayout } from 'common/components/Layouts/MobileLayout';
import { ModalContent, IModalContent } from 'common/components';
import { TwoColumnsLayout } from 'common/components/Layouts/TwoColumnsLayout';
import { BlockingMessage } from 'common/components/Header/BlockingMessage';
import { MEDIA_MOBILE } from 'common/config/base.config';
import { getMapLinkToStore, getStoreAddressString } from 'common/helpers/Store.helper';
import { ORDER_MAX_PRICE_THRESHOLD } from 'common/consts/validation.consts';
import { ORDERS_PICKUP_DATE_FORMAT } from 'common/consts/Orders.const';
import { getCustomerFullName } from 'common/helpers/Customer.helper';
import { StoreRating } from 'common/components/StoreRating';
import { formatAmount } from 'common/helpers/format.helper';
import { ERoutesPrivate } from 'common/models/routesModel';
import { ReactComponent as SvgPlacePoint } from 'app/assets/images/placePoint.svg';
import { ReactComponent as SvgMapPoint } from 'app/assets/images/mapPoint.svg';
import { ReactComponent as SvgClock } from 'app/assets/images/clock.svg';
import { ReactComponent as SvgCash } from 'app/assets/images/cash.svg';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { getCartModel } from 'app/store/reducers/cart.reducer';
import { getMenuAddOns } from 'app/store/reducers/menu.reducer';
import { addOrder, getCurrentOrder } from 'app/store/reducers/order.reducer';
import { getPeakHours } from 'app/store/reducers/peakHours.reducer';
import { setUiCartItemModal } from 'app/store/reducers/ui.reducer';
import { CodeConfirm, PhoneConfirm } from 'entities/Customer/components';
import { EOrdersErrorCode, EOrdersErrorMessage } from 'entities/Orders/Orders.models';
import { TimeSelector } from 'entities/PeakHours/components/TimeSelector';
import { formatTime, getTimeList } from 'entities/PeakHours/helpers/date.helper';
import { ECustomerStatus } from 'entities/Customer/Customer.models';
import { ECartPromoBonusType, ECartPromoType, ICartItemModel, ICartModel } from 'entities/Cart/Cart.models';
import { hasUnavailableItemsInError } from 'entities/Orders/Orders.helpers';
import { PromoCodeForm } from 'entities/Cart/components/PromoCodeForm';
import { PromoInfo } from 'entities/Cart/components/PromoInfo';
import { CartItemsListItem } from 'entities/Cart/components/CartItemsListItem';
import { CartItemTotal } from 'entities/Cart/components/CartItemTotal';

enum EModalType {
  ChangeTime = 'change-time',
  Phone = 'phone',
  Code = 'code',
  UnavailableItems = 'unavailableItems',
  UnavailablePromo = 'unavailablePromo'
}

interface IComponentProps {
  cart: ICartModel;
  isBag?: boolean;
}

export const OrderPageBeforeSend: React.FC<IComponentProps> = props => {
  const dispatch = useAppDispatch();
  const { cart, isBag } = props;
  const { customerModel } = useAppSelector(state => state.customer);
  const { cartModelError } = useAppSelector(state => state.cart);
  const { peakHoursModel, peakHoursModelLoading } = useAppSelector(state => state.peakHours);
  const { orderModelLoading, orderModelError } = useAppSelector(state => state.order);
  const [phone, setPhone] = useState<string>('');
  const { isContainUnavailableItems, unavailableItems } = hasUnavailableItemsInError(orderModelError);
  const isPromoValid =
    orderModelError?.data.code === EOrdersErrorCode.UnavailablePromo ||
    cartModelError?.data.code === EOrdersErrorCode.UnavailablePromo;
  const isCustomerBlocked = customerModel?.status && customerModel.status === ECustomerStatus.Blocked;
  const banExpiredAt = customerModel?.banExpiredAt || undefined;
  const isMobile: boolean = useMediaPredicate(MEDIA_MOBILE);
  const { total, items, promo, discountTotal, bonusTotal } = cart;
  const finalTotal = discountTotal || total || 0;
  const isPromoApplied = !!promo;
  const promoBonusType = promo?.bonus?.bonusType as ECartPromoBonusType | undefined;
  const promoType = promo?.type as ECartPromoType | undefined;
  const header = isBag ? 'Your Bag' : 'Your Order';

  useEffect(() => {
    dispatch(getPeakHours());
  }, []);

  useEffect(() => {
    const storeId = customerModel?.store?.id;

    if (storeId) {
      dispatch(getMenuAddOns(storeId));
    }
  }, [customerModel]);

  const [visibleModal, toggleModalVisibility] = useState<boolean>();
  const [contentType, toggleModalType] = useState<EModalType>();

  const customerName = getCustomerFullName(customerModel);

  const getTimeListMemo = React.useCallback(() => {
    const openTime = moment(customerModel?.store?.openTime || '00:00', 'HH:mm');
    const isWithoutCloseTime = customerModel?.store?.schedule === '24/7';
    const endOfNextDay = moment(customerModel?.store?.closeTime || '23:59', 'HH:mm')
      .clone()
      .add(1, 'day');
    const endOfToday = moment(customerModel?.store?.closeTime || '23:59', 'HH:mm');
    const closeTime = isWithoutCloseTime ? endOfNextDay : endOfToday;
    const { asap, asapIncrement, times } = getTimeList(openTime, closeTime, peakHoursModel);
    return { asap, asapIncrement, times };
  }, [customerModel, peakHoursModel]);

  const { asap, asapIncrement, times } = getTimeListMemo();

  const [time, setTime] = useState<moment.Moment | undefined>(asap);
  const [isAsap, setIsAsap] = useState<boolean>(false);
  useEffect(() => {
    const { asap: initialValue } = getTimeListMemo();
    setTime(initialValue);
    setIsAsap(true);
  }, [customerModel, peakHoursModel, getTimeListMemo, setIsAsap]);

  const disableForm = !time || !total || orderModelLoading;

  const submitPhone = (newPhone: string) => {
    setPhone(newPhone);
    toggleModalType(EModalType.Code);
  };

  const submit = (isPhoneJustConfirmed?: boolean) => {
    if (finalTotal > ORDER_MAX_PRICE_THRESHOLD) {
      message.error(EOrdersErrorMessage.TotalSumExceeds);
      return;
    }

    if (customerModel?.isPhoneConfirmed || isPhoneJustConfirmed) {
      const storeId = customerModel?.store?.id;

      if (!time || !storeId) {
        return;
      }

      dispatch(addOrder({ pickUpTime: time.toISOString() }));

      return;
    }

    toggleModalType(EModalType.Phone);
    toggleModalVisibility(true);
  };

  const handleUpdateBagClick = useCallback(() => {
    dispatch(getCartModel());
    toggleModalVisibility(false);
    dispatch(getCurrentOrder());
  }, [getCartModel]);

  const components: IModalContent = {
    [EModalType.ChangeTime]: {
      content: (
        <TimeSelector
          time={time}
          loading={peakHoursModelLoading}
          asap={asap}
          asapIncrement={asapIncrement}
          times={times}
          setTime={(newTime, isAsapProp) => {
            setTime(newTime);
            setIsAsap(isAsapProp);
            toggleModalVisibility(false);
          }}
        />
      ),
      header: 'Set custom time',
      subheader: 'Please select pick-up time'
    },
    [EModalType.Phone]: {
      content: <PhoneConfirm onSuccess={submitPhone} />,
      header: 'Please enter your phone number',
      subheader: 'Confirmation code will be sent to you via SMS'
    },
    [EModalType.Code]: {
      content: (
        <CodeConfirm
          onSuccess={() => {
            toggleModalVisibility(false);
            submit(true);
          }}
          phone={phone}
        />
      ),
      header: 'Confirm my order',
      subheader: `SMS code has been sent to ${phone}`
    },
    [EModalType.UnavailableItems]: {
      content: (
        <div className="fs t-align-c">
          <List
            className="mb-5 pb-5"
            dataSource={unavailableItems}
            renderItem={item => <CartItemsListItem item={item} regular />}
          />
          <Button size="large" block className="btn" type="primary" onClick={handleUpdateBagClick}>
            Update
          </Button>
          <div className="mt-1">You will remain on Your Bag page, and the unavailable items will be removed from your bag.</div>
        </div>
      ),
      header: "We're sorry! One or more of the items in your bag are now unavailable.",
      subheader: 'Please update your bag to remove the unavailable items before trying to order again.'
    },
    [EModalType.UnavailablePromo]: {
      content: (
        <div className="fs t-align-c">
          <Button size="large" block className="btn" type="primary" onClick={handleUpdateBagClick}>
            Refresh Now
          </Button>
        </div>
      ),
      header: 'The promo applied to some of your items has expired.',
      subheader: 'Please refresh your bag to update your items and their prices.'
    }
  };

  const modalContent = contentType && components[contentType];

  const store = customerModel?.store;
  const { storeName = 'Unknown store' } = store || {};

  const handleCartItemClick = useCallback((item: ICartItemModel) => {
    dispatch(setUiCartItemModal({ isVisible: true, cartItem: item }));
  }, []);

  useEffect(() => {
    if (isContainUnavailableItems) {
      toggleModalVisibility(isContainUnavailableItems);
      toggleModalType(EModalType.UnavailableItems);
    }
  }, [isContainUnavailableItems]);

  useEffect(() => {
    if (isPromoValid) {
      toggleModalVisibility(true);
      toggleModalType(EModalType.UnavailablePromo);
    }
  }, [isPromoValid]);

  return (
    <TwoColumnsLayout
      left={
        <>
          {isCustomerBlocked && isMobile && <BlockingMessage banExpiredAt={banExpiredAt} />}
          <MobileLayout header={header}>
            <PromoInfo bonusType={promoBonusType} promoType={promoType} />
            <List
              dataSource={items}
              renderItem={item => <CartItemsListItem item={item} promoBonusType={promoBonusType} onClick={handleCartItemClick} />}
            />
          </MobileLayout>
        </>
      }
      right={
        <MobileLayout>
          <div className="order-info-item">
            <SvgPlacePoint />

            <div>
              <div className="basic__text_title mb-3 mt-1">Pick up from</div>
              {storeName && (
                <div className="fs-xs color-default font-weight-5 lh-sm mb-2">
                  <span>{storeName}</span>
                  <StoreRating store={store} className="ml-3" />
                </div>
              )}
              <div className="color-gray fs-xxs lh-sm">{getStoreAddressString(customerModel?.store)}</div>
            </div>
          </div>

          {customerModel?.store && (
            <div className="order-info-item">
              <SvgMapPoint />

              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getMapLinkToStore(customerModel?.store)}
                className="link fs-xs color-orange-main"
              >
                View Directions to Store
              </a>
            </div>
          )}

          <div className="d-flex d-flex_align-center mb-9">
            <SvgClock className="mr-4" />

            <div className="d-flex__item_full d-flex d-flex_justify-between d-flex_align-center">
              <div className="basic__text_title">{time ? (isAsap ? `ASAP (~${formatTime(time)})` : formatTime(time)) : '-'}</div>

              <Button
                className="btn_link btn_link_sm ml-2"
                type="link"
                onClick={() => {
                  toggleModalType(EModalType.ChangeTime);
                  toggleModalVisibility(true);
                }}
                disabled={false}
              >
                <span className="fs-xs">Change time</span>
              </Button>
            </div>
          </div>

          <div className="d-flex d-flex_align-center mb-9">
            <SvgCash className="mr-4" />

            <div>
              <div className="basic__text_title">Cash</div>
            </div>
          </div>

          {!!bonusTotal && (
            <Row className="menu-card__header pb-4" justify="space-between" align="middle" wrap={false}>
              <div>Discount</div>
              <div className="color-orange-main">P{formatAmount(bonusTotal)}</div>
            </Row>
          )}

          <div className="d-flex d-flex_justify-between d-flex_align-center mb-9 pb-4 bordered">
            <div className="basic__text_title">Total</div>
            <CartItemTotal
              total={total}
              discountTotal={discountTotal}
              bonusType={promoBonusType}
              isPromoApplied={isPromoApplied}
              bonusTotal={bonusTotal}
            />
          </div>

          <PromoCodeForm className="mt-9 mb-4" />

          <Row align="middle" justify="center" className="mb-7 color-orange-main font-weight-5">
            <Link to={ERoutesPrivate.PersonalPromo} className="link fs-xs">
              See all MB Go! Exclusive deals here
            </Link>
          </Row>

          <Button
            size="large"
            block
            className="btn"
            type="primary"
            onClick={() => submit()}
            disabled={disableForm || isCustomerBlocked}
          >
            Order
          </Button>
          {isCustomerBlocked && !isMobile && (
            <div className="blocking-message_desktop">
              <BlockingMessage banExpiredAt={banExpiredAt} />
            </div>
          )}

          <div className="color-grayDark mt-9">
            <p>Cancellations, after an order has been accepted by the store, are not allowed.</p>
            <p>
              Please be at the store on or shortly before {time ? formatTime(time, ORDERS_PICKUP_DATE_FORMAT) : 'pick up time'}.
              Orders that are not picked up after the 15 minute grace period may result in your account being temporarily
              suspended.
            </p>
            <p>
              We would like to encourage a positive experience for all users, from our customers to our stores. See you at our
              stores soon{customerName && `, ${customerName}`}
            </p>
          </div>

          <ModalContent
            visible={visibleModal}
            close={() => toggleModalVisibility(false)}
            header={modalContent?.header}
            subHeader={modalContent?.subheader}
          >
            {modalContent?.content}
          </ModalContent>
        </MobileLayout>
      }
    />
  );
};
