import { EErrorStatus, IError } from '@axmit/error-helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { ERoutesCommon, ERoutesPrivate } from 'common/models/routesModel';
import { AppDispatch, history, RootState } from 'app/store';
import { setCustomerModel } from 'app/store/reducers/customer.reducer';
import { setCartModel } from 'app/store/reducers/cart.reducer';
import {
  EOrderHistoryErrorCode,
  EOrderHistoryErrorMessage,
  IOrderHistoryAddReorderParams,
  IOrderHistoryCollection,
  IOrderHistoryCollectionFilter,
  IOrderHistoryModel,
  IOrderHistoryReorderModel,
} from 'entities/OrderHistory/OrderHistory.models';
import { orderHistoryTransport } from 'entities/OrderHistory/OrderHistory.transport';

export interface IState {
  // orderHistory model
  orderHistoryModel: IOrderHistoryModel | null;
  orderHistoryModelLoading: boolean;
  orderHistoryModelError: IError | null;
  // orderHistory collection
  orderHistoryCollection: IOrderHistoryCollection | null;
  orderHistoryCollectionLoading: boolean;
  orderHistoryCollectionError: IError | null;
  // orderHistoryReorder model
  orderHistoryReorderModel: IOrderHistoryReorderModel | null;
  orderHistoryReorderModelLoading: boolean;
  orderHistoryReorderModelError: IError | null;
}

const initialState: IState = {
  // orderHistory model
  orderHistoryModel: null,
  orderHistoryModelLoading: false,
  orderHistoryModelError: null,
  // orderHistory collection
  orderHistoryCollection: null,
  orderHistoryCollectionLoading: false,
  orderHistoryCollectionError: null,
  // orderHistoryReorder model
  orderHistoryReorderModel: null,
  orderHistoryReorderModelLoading: false,
  orderHistoryReorderModelError: null,
};

export const orderHistorySlice = createSlice({
  name: 'orderHistory',
  initialState,
  reducers: {
    // orderHistory model
    setOrderHistoryModelLoading(state, action: PayloadAction<boolean>) {
      state.orderHistoryModelLoading = action.payload;
    },
    setOrderHistoryModel(state, action: PayloadAction<IOrderHistoryModel | null>) {
      state.orderHistoryModel = action.payload;
    },
    setOrderHistoryModelError(state, action: PayloadAction<IError | null>) {
      state.orderHistoryModelError = action.payload;
    },
    // orderHistory collection
    setOrderHistoryCollectionLoading(state, action: PayloadAction<boolean>) {
      state.orderHistoryCollectionLoading = action.payload;
    },
    setOrderHistoryCollection(state, action: PayloadAction<IOrderHistoryCollection | null>) {
      state.orderHistoryCollection = action.payload;
    },
    setOrderHistoryCollectionError(state, action: PayloadAction<IError | null>) {
      state.orderHistoryCollectionError = action.payload;
    },
    // orderHistoryReorder model
    setOrderHistoryReorderModelLoading(state, action: PayloadAction<boolean>) {
      state.orderHistoryReorderModelLoading = action.payload;
    },
    setOrderHistoryReorderModel(state, action: PayloadAction<IOrderHistoryReorderModel | null>) {
      state.orderHistoryReorderModel = action.payload;
    },
    setOrderHistoryReorderModelError(state, action: PayloadAction<IError | null>) {
      state.orderHistoryReorderModelError = action.payload;
    },
  },
});

export const {
  setOrderHistoryModelLoading,
  setOrderHistoryModel,
  setOrderHistoryModelError,
  setOrderHistoryCollectionLoading,
  setOrderHistoryCollection,
  setOrderHistoryCollectionError,
  setOrderHistoryReorderModelLoading,
  setOrderHistoryReorderModel,
  setOrderHistoryReorderModelError,
} = orderHistorySlice.actions;
export default orderHistorySlice.reducer;

export const getOrderHistoryModel = (id: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setOrderHistoryModelLoading(true));

    try {
      const orderHistoryModel = await orderHistoryTransport.getOrderHistoryModel(id);

      dispatch(setOrderHistoryModel(orderHistoryModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setOrderHistoryModelError(_error));

      history.replace(ERoutesCommon.Main);

      if (_error?.data?.statusCode === EErrorStatus.Forbidden && _error?.data?.message) {
        message.error(_error.data.message);
      }
    } finally {
      dispatch(setOrderHistoryModelLoading(false));
    }
  }

  return thunk;
};

export const getOrderHistoryCollection = (filter: IOrderHistoryCollectionFilter, successCb: () => void) => {
  async function thunk(dispatch: AppDispatch, getState: () => RootState) {
    dispatch(setOrderHistoryCollectionLoading(true));

    try {
      const orderHistoryCollection = await orderHistoryTransport.getOrderHistoryCollection(filter);
      const currentOrderHistoryCollection = getState().orderHistory.orderHistoryCollection?.data || [];

      dispatch(
        setOrderHistoryCollection({
          data: [...currentOrderHistoryCollection, ...orderHistoryCollection.data],
          meta: orderHistoryCollection.meta,
        })
      );

      successCb();
    } catch (error) {
      const _error = error as IError;

      dispatch(setOrderHistoryCollectionError(_error));
    } finally {
      dispatch(setOrderHistoryCollectionLoading(false));
    }
  }

  return thunk;
};

export const reorder = (params: IOrderHistoryAddReorderParams) => {
  async function thunk(dispatch: AppDispatch, getState: () => RootState) {
    dispatch(setOrderHistoryReorderModelLoading(true));

    try {
      const reorderModel = await orderHistoryTransport.reorder(params);
      const { store, cart } = reorderModel || {};
      const { customerModel } = getState().customer;

      dispatch(setOrderHistoryReorderModel(reorderModel));

      if (store && customerModel) {
        dispatch(setCustomerModel({ ...customerModel, store }));
      }

      if (cart) {
        dispatch(setCartModel(cart));
      }

      history.push(ERoutesPrivate.Bag);
    } catch (error) {
      const _error = error as IError;

      dispatch(setOrderHistoryReorderModelError(_error));

      if (_error?.data?.code === EOrderHistoryErrorCode.StoreIsClosed) {
        const storeName = params?.storeName || 'Unknown Store';
        message.error(`${storeName} is currently unavailable.`);
      }
      if (_error?.data?.code === EOrderHistoryErrorCode.EmptyOrder) {
        message.error(EOrderHistoryErrorMessage.EmptyOrder);
      }
    } finally {
      dispatch(setOrderHistoryReorderModelLoading(false));
    }
  }

  return thunk;
};
