import React, { useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import debounce from 'lodash.debounce';
import { EmptyData } from 'common/components/EmptyData';
import { storeTransport } from 'entities/Store/Store.transport';
import { IStoreCollectionParams, IStoreModel } from 'entities/Store/Store.models';

interface IComponentProps {
  onChange: (item?: IStoreModel) => void;
  defaultOpt: Partial<IStoreModel> | undefined;
}

export const StoreSelector: React.FC<IComponentProps> = ({ onChange, defaultOpt }) => {
  const [stores, setStores] = useState<IStoreModel[]>([]);
  const [selectedStore, setSelectedStore] = useState<IStoreModel | null>(null);
  const defaultOptId = defaultOpt?.id;
  const showDefaultOpt = selectedStore?.id !== defaultOptId;
  const exist = stores.find((el) => el.id === selectedStore?.id);

  if (!exist && selectedStore) {
    stores.unshift(selectedStore);
  }

  const getItemLabel = (item: IStoreModel): string => {
    return item?.storeName || 'Unknown store';
  };

  const options = useMemo(
    () =>
      stores.map((option) => (
        <Select.Option key={option.id} value={option.id}>
          {getItemLabel(option)}
        </Select.Option>
      )),
    [stores]
  );

  const loadData = async (value?: string) => {
    const filter: IStoreCollectionParams = {};

    if (value) {
      filter.customerSearch = value;
    }

    const stores = await storeTransport.getStoreCollection(filter);

    setStores(stores.data);
  };

  const handleSearch = (value?: string) => {
    loadData(value);
  };

  const searchDebounced = debounce(handleSearch, 200);

  const handleChange = async (id: string | number) => {
    const selectedStore = stores.find((el) => el.id === id);
    if (selectedStore) {
      setSelectedStore(selectedStore);
    }

    onChange(selectedStore);
  };

  useEffect(() => {
    if (defaultOpt) {
      const defaultStore = stores.find((el) => el.id === selectedStore?.id);

      if (defaultStore) {
        setSelectedStore(defaultStore);
      }
    }
  }, [stores, defaultOpt]);

  useEffect(() => {
    loadData();
  }, []);

  return stores ? (
    <Select
      allowClear={true}
      onClear={() => setSelectedStore(null)}
      className="width-full"
      showSearch
      value={selectedStore?.id || defaultOpt?.id}
      defaultActiveFirstOption={false}
      filterOption={false}
      onFocus={() => searchDebounced('')}
      onSearch={searchDebounced}
      onChange={handleChange}
      notFoundContent={<EmptyData />}
      getPopupContainer={(triggerNode) => triggerNode as HTMLElement}
    >
      {!!defaultOptId && showDefaultOpt && (
        <Select.Option key={defaultOptId} value={defaultOptId}>
          {defaultOpt.storeName}
        </Select.Option>
      )}
      {options}
    </Select>
  ) : (
    <div />
  );
};
