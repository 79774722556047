import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { useFormMapper } from 'common/helpers/form.helper';
import { requiredValidationRule, passValidationRules, oldPassValidationRules } from 'common/consts/validation.consts';
import { useAppDispatch, useAppSelector } from 'app/store/store.hooks';
import { setCustomerPassword, setPassword, updatePassword } from 'app/store/reducers/customer.reducer';
import { ICustomerSetPasswordParams, ICustomerUpdatePasswordParams } from 'entities/Customer/Customer.models';

interface IComponentProps {
  isPasswordSet: boolean;
  userId: string;
}

export const UpdatePasswordForm: React.FC<IComponentProps> = (props) => {
  const dispatch = useAppDispatch();
  const { isPasswordSet, userId } = props;
  const { customerPasswordError, customerPasswordLoading } = useAppSelector((state) => state.customer);
  const { fields } = useFormMapper(['password', 'oldPassword'], null, null, customerPasswordError);
  const buttonText = isPasswordSet ? 'Change password' : 'Set password';

  const clearCustomerPassword = () => {
    dispatch(setCustomerPassword(null));
  };

  useEffect(() => {
    return () => clearCustomerPassword();
  }, [setCustomerPassword]);

  const onSubmit = (values: any) => {
    const { password, oldPassword } = values;

    if (isPasswordSet) {
      const params: ICustomerUpdatePasswordParams = {
        id: userId,
        password,
        oldPassword,
      };

      dispatch(updatePassword(params));
    } else {
      const params: ICustomerSetPasswordParams = {
        id: userId,
        password,
      };

      dispatch(setPassword(params));
    }
  };

  return (
    <Form onFinish={onSubmit} requiredMark={false} layout="vertical" fields={fields}>
      {isPasswordSet && (
        <Form.Item
          rules={[requiredValidationRule, ...oldPassValidationRules]}
          name="oldPassword"
          label="Old password"
          validateFirst
        >
          <Input.Password type="password" disabled={customerPasswordLoading} />
        </Form.Item>
      )}

      <Form.Item rules={[requiredValidationRule, ...passValidationRules]} name="password" label="New password" validateFirst>
        <Input.Password type="password" disabled={customerPasswordLoading} />
      </Form.Item>

      <Button
        size="large"
        className="btn"
        htmlType="submit"
        type="primary"
        disabled={customerPasswordLoading}
        block
        title={buttonText}
      >
        {buttonText}
      </Button>
    </Form>
  );
};
