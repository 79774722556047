import { EErrorStatus, IError } from '@axmit/error-helper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { saveCreds } from '@axmit/axios-patch-jwt';
import { GDLHelper } from 'common/helpers/GDL.helper';
import { getCustomerFullName } from 'common/helpers/Customer.helper';
import { getStoreShortId } from 'common/helpers/Store.helper';
import { ERoutesCommon } from 'common/models/routesModel';
import { LS_KEY_STORE } from 'common/consts/LocalStorage.const';
import { AppDispatch, RootState, history } from 'app/store';
import { initAuthModel, updateIsSocialNetworkRegistered } from 'app/store/reducers/auth.reducer';
import { setCartModel } from 'app/store/reducers/cart.reducer';
import { getDefaultMenu, getMenuAddOns, getMenuCollection } from 'app/store/reducers/menu.reducer';
import { clearStoreModel } from 'app/store/reducers/store.reducer';
import { setUiCommonModal } from 'app/store/reducers/ui.reducer';
import {
  ECustomerErrorCode,
  ECustomerErrorMessage,
  IConfirmationModel,
  ICustomerAddParams,
  ICustomerCompleteRegistrationParams,
  ICustomerModel,
  ICustomerSetPasswordParams,
  ICustomerSetStoreParams,
  ICustomerUpdateParams,
  ICustomerUpdatePasswordParams,
} from 'entities/Customer/Customer.models';
import { customerTransport } from 'entities/Customer/Customer.transport';
import { EAuthErrorMessage, EAuthSuccessMessage } from 'entities/Auth/Auth.models';
import { EUserSuccessMessage } from 'entities/User/User.models';
import {
  ECustomerPhoneErrorMessage,
  ECustomerPhoneSuccessMessage,
  IConfirmPhoneCodeParams,
  IConfirmPhoneParams,
} from 'entities/Customer/Customer.models';

export interface IState {
  // model
  customerModelLoading: boolean;
  customerModel: ICustomerModel | null;
  customerModelParams: ICustomerCompleteRegistrationParams | null;
  customerModelError: IError | null;
  // confirmation
  customerConfirmationLoading: boolean;
  customerConfirmation: IConfirmationModel | null;
  customerConfirmationError: IError | null;
  // password
  customerPasswordLoading: boolean;
  customerPassword: ICustomerModel | null;
  customerPasswordError: IError | null;
  // customerPhone
  customerPhoneLoading: boolean;
  customerPhone: Object | null;
  customerPhoneParams: IConfirmPhoneParams | null;
  customerPhoneError: IError | null;
  // customerPhoneCode
  customerPhoneCodeLoading: boolean;
  customerPhoneCode: Object | null;
  customerPhoneCodeError: IError | null;
}

const initialState: IState = {
  // model
  customerModelLoading: false,
  customerModel: null,
  customerModelParams: null,
  customerModelError: null,
  // confirmation
  customerConfirmationLoading: false,
  customerConfirmation: null,
  customerConfirmationError: null,
  // password
  customerPasswordLoading: false,
  customerPassword: null,
  customerPasswordError: null,
  // customerPhone
  customerPhoneLoading: false,
  customerPhone: null,
  customerPhoneParams: null,
  customerPhoneError: null,
  // customerPhoneCode
  customerPhoneCodeLoading: false,
  customerPhoneCode: null,
  customerPhoneCodeError: null,
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // model
    setCustomerModelLoading(state, action: PayloadAction<boolean>) {
      state.customerModelLoading = action.payload;
    },
    setCustomerModel(state, action: PayloadAction<ICustomerModel | null>) {
      state.customerModel = action.payload;
    },
    setCustomerModelParams(state, action: PayloadAction<ICustomerCompleteRegistrationParams | null>) {
      state.customerModelParams = action.payload;
    },
    setCustomerModelError(state, action: PayloadAction<IError | null>) {
      state.customerModelError = action.payload;
    },
    // confirmation
    setCustomerConfirmationLoading(state, action: PayloadAction<boolean>) {
      state.customerConfirmationLoading = action.payload;
    },
    setCustomerConfirmation(state, action: PayloadAction<IConfirmationModel | null>) {
      state.customerConfirmation = action.payload;
    },
    setCustomerConfirmationError(state, action: PayloadAction<IError | null>) {
      state.customerConfirmationError = action.payload;
    },
    // password
    setCustomerPasswordLoading(state, action: PayloadAction<boolean>) {
      state.customerPasswordLoading = action.payload;
    },
    setCustomerPassword(state, action: PayloadAction<ICustomerModel | null>) {
      state.customerPassword = action.payload;
    },
    setCustomerPasswordError(state, action: PayloadAction<IError | null>) {
      state.customerPasswordError = action.payload;
    },
    // phone
    setCustomerPhoneLoading(state, action: PayloadAction<boolean>) {
      state.customerPhoneLoading = action.payload;
    },
    setCustomerPhone(state, action: PayloadAction<Object | null>) {
      state.customerPhone = action.payload;
    },
    setCustomerPhoneParams(state, action: PayloadAction<IConfirmPhoneParams | null>) {
      state.customerPhoneParams = action.payload;
    },
    setCustomerPhoneError(state, action: PayloadAction<IError | null>) {
      state.customerPhoneError = action.payload;
    },
    // phoneCode
    setCustomerPhoneCodeLoading(state, action: PayloadAction<boolean>) {
      state.customerPhoneLoading = action.payload;
    },
    setCustomerPhoneCode(state, action: PayloadAction<Object | null>) {
      state.customerPhoneCode = action.payload;
    },
    setCustomerPhoneCodeError(state, action: PayloadAction<IError | null>) {
      state.customerPhoneCodeError = action.payload;
    },
  },
});

export const {
  setCustomerModelLoading,
  setCustomerModel,
  setCustomerModelParams,
  setCustomerModelError,
  setCustomerConfirmationLoading,
  setCustomerConfirmation,
  setCustomerConfirmationError,
  setCustomerPasswordLoading,
  setCustomerPassword,
  setCustomerPasswordError,
  setCustomerPhoneLoading,
  setCustomerPhone,
  setCustomerPhoneParams,
  setCustomerPhoneError,
  setCustomerPhoneCodeLoading,
  setCustomerPhoneCode,
  setCustomerPhoneCodeError,
} = customerSlice.actions;
export default customerSlice.reducer;

export const getCustomerModel = (id: string) => {
  async function thunk(dispatch: AppDispatch, getState: () => RootState) {
    dispatch(setCustomerModelLoading(true));

    try {
      const customerModel = await customerTransport.getCustomerModel(id);
      const { email, phone } = customerModel || {};

      dispatch(setCustomerModel(customerModel));

      const state = getState();

      const isJustRegisteredViaSocialNetwork = state?.auth?.isSocialNetworkRegistered;

      if (isJustRegisteredViaSocialNetwork) {
        const customerFullName = getCustomerFullName(customerModel);

        GDLHelper.pushSignupEvent(customerFullName, email || '');
        dispatch(updateIsSocialNetworkRegistered(false));
      }

      if (phone) {
        dispatch(addCustomerPhone({ phone }));
      }
    } catch (error) {
      const _error = error as IError;

      dispatch(setCustomerModelError(_error));
    } finally {
      dispatch(setCustomerModelLoading(false));
    }
  }

  return thunk;
};

export const addCustomerModel = (params: ICustomerAddParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCustomerModelLoading(true));

    try {
      const customerModel = await customerTransport.addCustomerModel(params);
      const { email } = customerModel || {};

      dispatch(setCustomerModel(customerModel));

      params.onSuccess && params.onSuccess();

      const customerFullName = getCustomerFullName(customerModel);
      GDLHelper.pushSignupEvent(customerFullName, email || '');
    } catch (error) {
      const _error = error as IError;

      dispatch(setCustomerModelError(_error));

      if (_error?.data?.code === ECustomerErrorCode.AlreadyRegistered) {
        message.error(ECustomerErrorMessage.AlreadyRegistered);
      }
    } finally {
      dispatch(setCustomerModelLoading(false));
    }
  }

  return thunk;
};

export const updateCustomerModel = (params: ICustomerUpdateParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCustomerModelLoading(true));

    try {
      const customerModel = await customerTransport.updateCustomerModel(params);

      dispatch(setCustomerModel(customerModel));

      message.success(EAuthSuccessMessage.SuccessfullyEdited);
    } catch (error) {
      const _error = error as IError;

      dispatch(setCustomerModelError(_error));

      if (_error?.data?.code === ECustomerErrorCode.AlreadyRegistered) {
        message.error(ECustomerErrorMessage.AlreadyRegistered);
      }
    } finally {
      dispatch(setCustomerModelLoading(false));
    }
  }

  return thunk;
};

export const setCustomerStore = (params: ICustomerSetStoreParams, successCb?: () => void) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCustomerModelLoading(true));

    try {
      const customerModel = await customerTransport.setCustomerStore(params);

      dispatch(setCustomerModel(customerModel));

      if (successCb) {
        successCb();
      } else {
        const { cart } = customerModel || {};

        if (cart) {
          dispatch(setCartModel(cart));
        }

        dispatch(clearStoreModel());

        const storeShortId = getStoreShortId();

        if (!storeShortId) {
          return;
        }

        const storeId = customerModel.store?.id;

        if (storeId) {
          dispatch(getMenuCollection(storeId));
          dispatch(getMenuAddOns(storeId));
        } else {
          dispatch(getDefaultMenu());
        }
      }
    } catch (error) {
      const _error = error as IError;

      dispatch(setCustomerModelError(_error));
    } finally {
      dispatch(setCustomerModelLoading(false));
    }
  }

  return thunk;
};

export const setCustomerStoreFromLS = (userId: string) => {
  async function thunk(dispatch: AppDispatch) {
    const storeFromLS = localStorage.getItem(LS_KEY_STORE);

    if (storeFromLS) {
      const store = JSON.parse(storeFromLS);

      dispatch(setCustomerStore({ id: userId, store: store.id }));
    }
  }

  return thunk;
};

export const completeCustomerRegistration = (params: ICustomerCompleteRegistrationParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCustomerModelLoading(true));
    dispatch(setCustomerModelParams(params));

    try {
      const customerModel = await customerTransport.completeCustomerRegistration(params);

      dispatch(setCustomerModel(customerModel));
      dispatch(setCustomerModelParams(null));
    } catch (error) {
      const _error = error as IError;

      dispatch(setCustomerModelError(_error));
    } finally {
      dispatch(setCustomerModelLoading(false));
    }
  }

  return thunk;
};

export const disconnectFacebook = () => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCustomerModelLoading(true));

    try {
      const customerModel = await customerTransport.disconnectFacebook();

      dispatch(setCustomerModel(customerModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setCustomerModelError(_error));
    } finally {
      dispatch(setCustomerModelLoading(false));
    }
  }

  return thunk;
};

export const disconnectGoogle = () => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCustomerModelLoading(true));

    try {
      const customerModel = await customerTransport.disconnectGoogle();

      dispatch(setCustomerModel(customerModel));
    } catch (error) {
      const _error = error as IError;

      dispatch(setCustomerModelError(_error));
    } finally {
      dispatch(setCustomerModelLoading(false));
    }
  }

  return thunk;
};

export const confirmCustomerEmail = (token: string) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCustomerConfirmationLoading(true));

    try {
      const confirmationModel = await customerTransport.confirmCustomerEmail({ token });
      dispatch(setCustomerConfirmation(confirmationModel));

      message.success(EAuthSuccessMessage.AccountConfirmed);
      await saveCreds(confirmationModel);
      dispatch(initAuthModel());

      history.push(ERoutesCommon.Root);
    } catch (error) {
      const _error = error as IError;

      dispatch(setCustomerConfirmationError(_error));

      if (_error.status === EErrorStatus.Validation || _error.status === EErrorStatus.NotFound) {
        message.error(EAuthErrorMessage.InvalidLink);
      }

      history.push(ERoutesCommon.Root);
    } finally {
      dispatch(setCustomerConfirmationLoading(false));
    }
  }

  return thunk;
};

export const setPassword = (params: ICustomerSetPasswordParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCustomerPasswordLoading(true));

    try {
      const customerModel = await customerTransport.setCustomerPassword(params);

      dispatch(setCustomerModel(customerModel));
      dispatch(setUiCommonModal({ isVisible: false }));
    } catch (error) {
      const _error = error as IError;

      dispatch(setCustomerPasswordError(_error));
    } finally {
      dispatch(setCustomerPasswordLoading(false));
    }
  }

  return thunk;
};

export const updatePassword = (params: ICustomerUpdatePasswordParams) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCustomerPasswordLoading(true));

    try {
      const customerModel = await customerTransport.updateCustomerPassword(params);

      dispatch(setCustomerModel(customerModel));
      dispatch(setUiCommonModal({ isVisible: false }));
      message.success(EUserSuccessMessage.PasswordChanged);
    } catch (error) {
      const _error = error as IError;

      dispatch(setCustomerPasswordError(_error));
    } finally {
      dispatch(setCustomerPasswordLoading(false));
    }
  }

  return thunk;
};

export const addCustomerPhone = (params: IConfirmPhoneParams, successCb?: () => void, failCb?: () => void) => {
  async function thunk(dispatch: AppDispatch) {
    dispatch(setCustomerPhoneLoading(true));
    dispatch(setCustomerPhoneParams(params));

    try {
      await customerTransport.addCustomerPhone(params);

      dispatch(setCustomerPhoneParams(null));

      if (successCb) {
        successCb();
      }
    } catch (error) {
      const _error = error as IError;

      dispatch(setCustomerPhoneError(_error));

      if (_error.status === EErrorStatus.Validation && _error.data.code === ECustomerErrorCode.CodeSendingError && failCb) {
        failCb();
      }
    } finally {
      dispatch(setCustomerPhoneLoading(false));
    }
  }

  return thunk;
};

export const activateCustomerPhone = (params: IConfirmPhoneCodeParams, successCb?: () => void) => {
  async function thunk(dispatch: AppDispatch, getState: () => RootState) {
    dispatch(setCustomerPhoneCodeLoading(true));

    try {
      await customerTransport.activateCustomerPhone(params);

      message.success(ECustomerPhoneSuccessMessage.Activated);

      const state = getState();
      const userId = state.auth.authModel?.access?.userId || '';

      if (userId) {
        dispatch(getCustomerModel(userId));
      }

      if (successCb) {
        successCb();
      }
    } catch (error) {
      const _error = error as IError;

      dispatch(setCustomerPhoneCodeError(_error));

      if (_error.status === EErrorStatus.NotFound) {
        message.error(ECustomerPhoneErrorMessage.InvalidCode);
      }
    } finally {
      dispatch(setCustomerPhoneCodeLoading(false));
    }
  }

  return thunk;
};
